var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c("div", { staticClass: "container" }, [
        _vm._m(0),
        _c("div", { staticClass: "info-faren-main" }, [
          _c("div", { staticClass: "tips" }, [
            _vm._v(
              "请上传以下照片或文件，照片要求清晰无反光，文件小于" +
                _vm._s(_vm.size) +
                "M。"
            )
          ]),
          _c(
            "div",
            { staticClass: "info-faren-content" },
            [
              _vm._l(_vm.imgUrls, function(item, index) {
                return _c("div", { key: index, staticClass: "has-value" }, [
                  _c("div", { staticClass: "upload-box-btn-main" }, [
                    _vm.returnItem.uploadType == 1
                      ? _c("img", { attrs: { src: item } })
                      : _c("img", {
                          attrs: {
                            src: require("@/assets/images/img/successBg.png")
                          }
                        }),
                    _c("i", {
                      on: {
                        click: function($event) {
                          return _vm.removeImg(index)
                        }
                      }
                    })
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "uploading-tips",
                      class: {
                        on:
                          index === 0 && _vm.imgUrls.length > 0
                            ? _vm.returnItem.need == 1
                            : false
                      }
                    },
                    [_vm._v(_vm._s(_vm.returnItem.title))]
                  )
                ])
              }),
              _vm.imgUrls.length < 8
                ? _c(
                    "div",
                    { staticClass: "no-value" },
                    [
                      _vm._m(1),
                      _c(
                        "span",
                        {
                          staticClass: "uploading-tips",
                          class: {
                            on:
                              _vm.imgUrls.length == 0
                                ? _vm.returnItem.need == 1
                                : false
                          }
                        },
                        [_vm._v(_vm._s(_vm.returnItem.title))]
                      ),
                      _c("OSSUpload", {
                        ref: "upload1",
                        staticClass: "upload-item",
                        staticStyle: { "margin-bottom": "14px" },
                        attrs: {
                          type: _vm.returnItem.uploadType,
                          backgroundImgName: _vm.returnItem.bg,
                          accept: _vm.returnItem.accept,
                          need: _vm.returnItem.need,
                          maxSize: _vm.returnItem.size,
                          successBg: _vm.returnItem.successBg,
                          title: _vm.returnItem.title,
                          files: _vm.returnItem.files,
                          multiple: _vm.returnItem.multiple
                        },
                        on: {
                          success: function(e) {
                            return _vm.handleSuccess(e)
                          },
                          "update:files": function($event) {
                            return _vm.$set(_vm.returnItem, "files", $event)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "add-btn on", on: { click: _vm.commit } },
            [
              _vm.commiting ? _c("cube-loading") : _vm._e(),
              _c("span", [_vm._v("保存")])
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-box" }, [
      _c("p", { staticClass: "title" }, [_vm._v("补充资料")]),
      _c("p", { staticClass: "desc" }, [_vm._v("Hi，请填写以下资料吧~")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "upload-box-btn-main" }, [
      _c("img", { attrs: { src: require("@/assets/images/img/carme.png") } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }