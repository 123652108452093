<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-12-13 11:48:03
 * @LastEditors: CZK
 * @LastEditTime: 2024-12-13 11:56:59
-->
<template>
  <div class="container">
    <div class="title-box">
      <p class="title">企业开户</p>
      <p class="desc">请填写以下资料吧~</p>
    </div>

    <div class="info-faren-main">
      <div class="body-box">
        <div class="up-box">
          <img src="@/assets/images/img/step_2.png"/>
          <span class="t">经办人确认</span>
          <span class="d">当前操作需要经办人确认，请点击“获取<br/>验证码”，并回填接收到的短信验证码</span>
        </div>

        <div class="faren-form">
          <div class="form-item border-bottom-1px">
            <label>经&nbsp;办&nbsp;人</label>
            <span>{{info.contactName}}</span>
          </div>
          <div class="form-item border-bottom-1px">
            <label>手&nbsp;机&nbsp;号</label>
            <span>{{info.contactPhone}}</span>
          </div>
          <div class="form-item border-bottom-1px">
            <label>验&nbsp;证&nbsp;码</label>
            <input type="text" v-model="authCode" placeholder="短信验证码" />
            <div
              class="get-code"
              :class="{on:!state.smsSendBtn}"
              @click.stop.prevent="getCaptcha"
            >{{ state.smsSendBtn ? state.time + 's后重发' : '获取验证码'}}</div>
          </div>
        </div>
      </div>


      <div class="add-btn on"  @click="commit">
          <cube-loading v-if="commiting"></cube-loading>
          <span>下一步</span>
        </div>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import OSSUpload from "@comp/Upload/OSSUpload";
import { resetImageBlob } from "@/utils/getOssImageBlobUrl";
// import fontCard from '@comp/fontCard/index'
import {findNextFlowThenJump1} from "./findNextFlow"
import moment from "moment";
import {getEBankEnterprisePersonInfo,sendVerifySMS,verifyOperatorConfirmation} from "@api/user";
const LIMITTIME = 60;
export default {
  name: "SupplementaryInformation",
  components: {
    // fontCard,
    OSSUpload
  },
  // 受益方式 1-直接或间接25%(含)以上 2-通过人事、财务等其他方对公司进行控制 3-高级管理员 4-法人或公司负责人 5-其他
  data(){
    return {
      state: {
        time: LIMITTIME,
        smsSendBtn: false
      },
      authCode:'',
      info:{},
      identify:false,
      commiting:false
    }
  },
  methods: {

    //恢复按钮
    regainCheckBtn(state) {
      state.time = LIMITTIME;
      state.smsSendBtn = false;
    },
    //获取验证码
    getCaptcha() {
      const {state } = this;
      // if(!this.showCode) return
      if (!this.info.contactPhone) {
        this.$createToast({
          type: "warn",
          txt: "请确认手机号"
        }).show();
        return;
      }
      setTimeout(() => {
        if (this.info.contactPhone.length > 0) {
          state.smsSendBtn = true;
          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              this.regainCheckBtn(state);
              window.clearInterval(interval);
            }
          }, 1000);
          const toastSuccess = this.$createToast({
            txt: "验证码发送中",
            type: "correct",
            time: 0
          });
          toastSuccess.show();
          sendVerifySMS({type:'1',token:''})
            .then(res => {
              toastSuccess.hide();
              if (res && res.success) {
                this.$createToast({
                  txt: "验证码已发送，请留意",
                  type: "correct"
                }).show();
                // 储存tattedId
              } else {
                clearInterval(interval);
                this.regainCheckBtn(state);
              }
            })
            .catch(err => {
              console.log('err', err)
              toastSuccess.hide();
              clearInterval(interval);
              this.regainCheckBtn(state);
            });
        } else {
          this.$createToast({
            txt: "请确认手机号",
            type: "error"
          }).show();
        }
      }, 0);
    },

    async getInfo(){
     let res =  await getEBankEnterprisePersonInfo({token:""})
     if(res.success){
        this.info = res.data
        
     }
    },

    async commit(){
      if(this.commiting) return;
      if(!this.authCode) return this.$createToast({txt: "请输入验证码",type: "error"}).show()

      this.commiting = true
     let res =  await verifyOperatorConfirmation({
      authCode:this.authCode,token:''
      })

      if(res.success){
        findNextFlowThenJump1()
      }
      this.commiting = false
    }
  },
  created(){
    this.getInfo()
  }
}
</script>
<style lang="less" scoped>
input{
all: unset;
}
.container{
width: 100%;
background:#F5F7FB url("../../assets//images/img/bg1.png") no-repeat;
background-size: contain;
.agreement-box{
    display: flex;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    line-height: 18px;
    margin: 16px 0;
    text-align: justify;
    a{
      color:#3399FF;
    }
    i{
      display: block;
      width: 18px;
      height: 18px;
      margin-right: 8px;
      flex-shrink: 0;
      background: url("../../assets/images/img/check_on.png") no-repeat;
      background-size: contain;
    }
  }
.title-box{
  margin-top: 27px;
  margin-left: 32px;
  .title{
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 18px;
  }
  .desc{
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
   
    line-height: 22px;
    margin-top: 12px;
  }
}
.info-faren-main{
  width: 343px;
  // height: 500px;
  background: #FFFFFF;
  margin: 17px auto 0;
  border-radius: 8px 8px 8px 8px;
  // padding: 14px 12px 12px;
  display:flex;
  flex-direction: column;
  .upload-company-box{
    display:flex;
    flex-direction: column;
    align-items: center;
    // padding-bottom: 14px;
    .upload-company-btn{
      position: relative;
      width: 321px;
      height: 42px;
      display:flex;
      flex-direction: column;
      align-items: center;
      margin: 14px auto 14px;
      .ossupload{
        width:100%;
        height:100%;
        position:absolute;
        left:0;
        opacity: 0;
        top:0;
      }
      button{
        all:unset;
        width: 321px;
        height: 42px;
        text-align: center;
        background: #CCCCCC;
        border-radius: 30px 30px 30px 30px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 42px;
        color: #FFFFFF;
        &.on{
          background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
          margin: 0 auto 0;
        }
      }
    }
    .upload-company-show-image{
      width: 321px;
      height: 124px;
      border-radius: 8px 8px 8px 8px;
      border: 2px dashed #C0DBFF;
      display:flex;
      justify-content:center;
      align-items: center;
      .default-image{
        width:159px;
        height:107px;
        overflow:hidden;
        display:flex;
        justify-content:center;
        align-items: center;
        position:relative;
        .d{
          width:100%;
          height:100%;
          object-fit:cover;
        }
        .see-btn{
          display:flex;
          flex-direction: column;
          justify-content:center;
          align-items: center;
          position:absolute;
          img{
            width:40px;
            height:40px;
            object-fit:contain;
          }
          span{
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #3399FF;
            line-height: 18px;
            margin-top:6px;
          }
        }
      }
    }
  }
  
  .tips{
    width: 100%;
    height: 60px;
    background: #FFFBE1;
    border-radius: 8px 8px 0px 0px;
    padding: 17px 12px;
    text-align: left;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #FFA54C;
    text-align: left;
    line-height:1.2;
  }
  .body-box{
    padding: 14px 12px 12px;
    .up-box{
      display: flex;
      flex-direction: column;
      align-items: center;
      img{
        width:183px;
        height:141px;
        object-fit:contain;
        margin-top: 8px
      }
      .t{
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 18px;
        color: #333333;
        line-height: 19px;
        margin-top: 4px;
      }
      .d{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 18px;
        margin-top: 18px;
        text-align: center;
      }
    }
  }
  .info-faren-title-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 13px;
    .select-box{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      display: flex;
      line-height: 18px;
      span{display: flex;align-items: center;}
      i{
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url("../../assets/images/img/no.png") no-repeat;
        background-size: contain;
        margin-left: 10px;
        margin-right: 10px;
        &.yes{
          background: url("../../assets/images/img/yes.png") no-repeat;
          background-size: contain;
        }
      }
    }
  }
  .info-faren-title{
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    margin-left: 5px;
    color: #1C2023;
    line-height: 20px;
    &.on{
      &::after{
        content: '*';
        color:#FA5151;
      }
    }
    
  }
  .info-faren-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    button{
      all:unset;
      width: 321px;
      height: 42px;
      text-align: center;
      background: #CCCCCC;
      border-radius: 30px 30px 30px 30px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 42px;
      margin: 12px auto 0;
      color: #FFFFFF;
      &.on{
        background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
        margin: 10px auto 16px;
      }
    }
    
  }
  // .tips{
  //   width: 321px;
  //   height: 40px;
  //   background: #FFFBE1;
  //   border-radius: 8px 8px 8px 8px;
  //   padding: 10px 0 10px 6px;
  //   text-align: left;
  //   font-family: PingFang SC, PingFang SC;
  //   font-weight: 500;
  //   font-size: 14px;
  //   margin: 14px 0;
  //   color: #FFA54C;
  // }
  .faren-form{
    margin-top: 20px;
    .form-item1{
      width: 100%;
      display: flex;
      justify-content: space-between;
      min-height: 50px;
      label{
        width: 70px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        flex-shrink: 0;
        color: #666666;
        margin-left: 6px;
        line-height: 50px;
        margin-right: 32px;
      }
      .empty-img{
        width: 50px;
        margin-left: 6px;
        height: 50px;
        img{
          width:100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .down{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #3399FF;
        line-height: 18px;
        display:flex;
        align-items: center;
        i{
          display: inline-block;
          width: 24px;
          height: 24px;
          background: url("../../assets/images/img/blue_right.png") no-repeat;
          background-size: contain;
        }
      }
      .types-box{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        line-height: 18px;
        margin-top: 16px;
        p{
          display: flex;
          margin-bottom: 11px;
        }
        i{
          display: inline-block;
          width: 16px;
          height: 16px;
          flex-shrink: 0;
          background: url("../../assets/images/img/no.png") no-repeat;
          background-size: contain;
          margin-right: 10px;
          &.yes{
            background: url("../../assets/images/img/yes.png") no-repeat;
            background-size: contain;
          }
        }
      }
    }
    .form-item{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      div{
        flex-shrink: 0;
        white-space: nowrap;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 18px;
      }
      label{
        width: 56px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        flex-shrink: 0;
        color: #666666;
        margin-left: 6px;
        line-height: 20px;
        margin-right: 32px;
        
        &.on{
          position:relative;
          &::after{
            content: '*';
            position:absolute;
            right:-6px;
            top:0;
            color:#FA5151;
          }
        }
      }
      input{
        flex: 1;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        &::placeholder{
          color: #999999;
        }
      }
      span{
        flex: 1;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
      }
    }
  }
  
}

.add-btn{
    width: 321px;
    height: 42px;
    text-align: center;
    background: #CCCCCC;
    border-radius: 30px 30px 30px 30px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 42px;
    margin: 12px auto 18px;
    color: #FFFFFF;
    &.on{
      background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
      margin: 10px auto 16px;
    }
  }


  .confrim-content{
    width: 316px;
    height: 178px;
    background: #FFFFFF;
    display:flex;
    flex-direction: column;
    border-radius: 8px 8px 8px 8px;
    .title{
      text-align:center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #3D3D3D;
      margin-top:22px;
    }
    .desc{
      text-align:center;
      font-family: PingFangSC-Medium, PingFangSC-Medium;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      padding: 0 45px;
      color: #353535;
      margin-top:14px;
    }
    .footer-box{
      margin-top:25px;
      display:flex;
      justify-content: space-around;
      .btn{
        width: 108px;
        height: 36px;
        text-align:center;
        line-height:36px;
        font-family: PingFangSC-Semibold, PingFangSC-Semibold;
        font-weight: 400;
        font-size: 16px;
        border-radius: 30px 30px 30px 30px;
        &.main{
          background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
          color: #FFFFFF;
        }
        &.two{
          border: 1px solid #009DFF;
          color:#009DFF;
        }
      }
    }
  }
}

.get-code{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333;
        &.on{
          color: #3399FF;
        }
      }
</style>