<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-10-29 13:58:38
 * @LastEditors: CZK
 * @LastEditTime: 2024-12-11 10:12:43
-->
<template>
  <div class="container" v-if="bankInfo">
    <div class="list-container">
      <div class="item" v-for="(item,index) in bankInfo.receivingAccountList" :key="index" @click="toMenu('receivingAccountDetail',item.id)">
        <div class="title">
          <img :src="bankImg[item.bankName]?bankImg[item.bankName]:bankImg['默认']"/>
          {{item.accountName}}</div>
        <p class="num">{{formatNumber(item.bankNo)}}</p>
        <p class="desc">{{item.openBranchName}}</p>
      </div>
      <!-- <div class="item" @click="toMenu('receivingAccountDetail')">
        <div class="title"><img src=""/>中职通(广州)在线科技有限公司</div>
        <p class="num">6212  ****  ****  8520</p>
        <p class="desc">中国建设银行股份有限公司广州天河支行</p>
      </div> -->
      <empty v-if="!bankInfo.receivingAccountList||bankInfo.receivingAccountList.length==0"/>
    </div>

    <div class="footer">
      <div class="button" @click="toMenu('receivingAccount')" v-if='bankInfo.authStatus=="4"&&bankInfo.receivingAccountList.length==0'>+添加收款账户</div>
      <p>遇到问题? <span>点击联系客服</span></p>
    </div>
  </div>
</template>
<script>
import {bankImg} from "@/utils/bankImg"
import{getMyReceivingAccount} from '@/api/user'
  import empty from './empty.vue'
  export default {
    name: "receivingAccountList",
    components:{empty},
    data() {
      return {
        bankImg,
        bankInfo:null
      }
    },
    methods:{
      formatNumber(numStr) {
          return numStr.replace(/^(\d{4})(\d+)(\d{4})$/,"$1" + "*".repeat(numStr.length - 4) + "$3");
      },
      toMenu(name,accountId){
        this.$router.push({name,query:{accountId}})
      },
      async getBankList(){
        let res = await getMyReceivingAccount({token:''})
        this.bankInfo = res.data||{}
      },
    },
    mounted(){
      this.getBankList()
    }
  }
</script>
<style lang="less" scoped>
  .container {
    width: 100%;
    background: #F5F7FB url(../../assets/images/img/apply_bg.png) no-repeat;
    background-size: 100% auto;
    .footer{
      width: 100%;
      display: flex;
      position: fixed;
      bottom: 50px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .button{
        width: 321px;
        height: 42px;
        background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
        border-radius: 30px 30px 30px 30px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        text-align: center;
        line-height:42px;
      }
      p{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        margin-top: 20px;
        text-align: center;
        line-height: 18px;
        span{
          color:#009DFF;
        }
      }
    }
    .list-container{
      margin:22px auto 0;
      width: 343px;
      .item{
        width: 343px;
        height: 120px;
        background: #FFFFFF;
        box-shadow: 0px 3 8px 0px rgba(51,51,51,0.1);
        border-radius: 8px 8px 8px 8px;
        padding: 14px 24px;
        margin-bottom: 16px;
        .title{
          display: flex;
          align-items:center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #333333;
          line-height: 20px;

          img{
            width:30px;
            height:30px;
            object-fit: contain;
            margin-right:8px;
          }
        }
        .num{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 18px;
          color: #333333;
          line-height: 23px;
          margin-top:7px;
          letter-spacing: 3px;
        }
        .desc{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          margin-top: 11px;
          line-height: 18px;
        }
      }
    }
  }
</style>