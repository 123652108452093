var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm._m(0),
      _c("div", { staticClass: "info-faren-main" }, [
        _c("div", { staticClass: "body-box" }, [
          _vm._m(1),
          _c("div", { staticClass: "faren-form" }, [
            _c("div", { staticClass: "form-item border-bottom-1px" }, [
              _c("label", [_vm._v("法    人")]),
              _c("span", [_vm._v(_vm._s(_vm.info.legalName))])
            ]),
            _c("div", { staticClass: "form-item border-bottom-1px" }, [
              _c("label", [_vm._v("手 机 号")]),
              _c("span", [_vm._v(_vm._s(_vm.info.legalPhone))])
            ]),
            _c("div", { staticClass: "form-item border-bottom-1px" }, [
              _c("label", [_vm._v("验 证 码")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.authCode,
                    expression: "authCode"
                  }
                ],
                attrs: { type: "text", placeholder: "短信验证码" },
                domProps: { value: _vm.authCode },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.authCode = $event.target.value
                  }
                }
              }),
              _c(
                "div",
                {
                  staticClass: "get-code",
                  class: { on: !_vm.state.smsSendBtn },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.getCaptcha($event)
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.state.smsSendBtn
                        ? _vm.state.time + "s后重发"
                        : "获取验证码"
                    )
                  )
                ]
              )
            ])
          ])
        ]),
        _c(
          "div",
          { staticClass: "add-btn on", on: { click: _vm.commit } },
          [
            _vm.commiting ? _c("cube-loading") : _vm._e(),
            _c("span", [_vm._v("下一步")])
          ],
          1
        )
      ]),
      _c(
        "transition",
        { attrs: { name: "cube-action-sheet-fade" } },
        [
          _c(
            "cube-popup",
            {
              attrs: {
                type: "repayment-confrim",
                position: "center",
                visible: _vm.isConfromVisibleRpMask
              }
            },
            [
              _c("transition", { attrs: { name: "cube-action-sheet-move" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isConfromVisibleRpMask,
                        expression: "isConfromVisibleRpMask"
                      }
                    ],
                    staticClass: "confrim-content",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [
                    _c("p", { staticClass: "title" }, [_vm._v("提示")]),
                    _c("p", { staticClass: "desc" }, [
                      _vm._v(
                        "当前账簿未完成结算授权，无法实现账户结算 请点击下方获取短信验证码，完成授权确认"
                      )
                    ]),
                    _c("div", { staticClass: "line h50 border-bottom-1px" }, [
                      _c("span", { staticClass: "t" }, [_vm._v("验 证 码")]),
                      _c("input", {
                        attrs: { type: "text", placeholder: "短信验证码" }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "get-code",
                          class: { on: !_vm.state.smsSendBtn },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.getCaptcha1($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.state.smsSendBtn
                                ? _vm.state.time + "s后重发"
                                : "获取验证码"
                            )
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "line" }, [
                      _c("span", { staticClass: "tip" }, [
                        _vm._v("短信验证码发送到138****9001（林*超）")
                      ])
                    ]),
                    _c("div", { staticClass: "footer-box" }, [
                      _c(
                        "span",
                        { staticClass: "btn two", on: { click: _vm.toIndex } },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "btn main",
                          on: { click: _vm.commitVisible }
                        },
                        [_vm._v("确定")]
                      )
                    ])
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-box" }, [
      _c("p", { staticClass: "title" }, [_vm._v("企业开户")]),
      _c("p", { staticClass: "desc" }, [_vm._v("请填写以下资料吧~")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "up-box" }, [
      _c("img", { attrs: { src: require("@/assets/images/img/step_2.png") } }),
      _c("span", { staticClass: "t" }, [_vm._v("法人确认")]),
      _c("span", { staticClass: "d" }, [
        _vm._v("当前操作需要法人确认，请点击“获取"),
        _c("br"),
        _vm._v("验证码”，并回填接收到的短信验证码")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }