var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.componentType
        ? _c(_vm.componentType, {
            ref: "instance",
            tag: "components",
            attrs: {
              type: _vm.verifyType,
              figure: _vm.figure,
              arith: _vm.arith,
              width: _vm.width,
              height: _vm.height,
              fontSize: _vm.fontSize,
              codeLength: _vm.codeLength,
              mode: _vm.mode,
              vOffset: _vm.vOffset,
              vSpace: _vm.vSpace,
              explain: _vm.explain,
              imgUrl: _vm.imgUrl,
              imgName: _vm.imgName,
              showButton: _vm.showButton,
              imgSize: _vm.imgSize,
              blockSize: _vm.blockSize,
              barSize: _vm.barSize,
              defaultNum: _vm.defaultNum,
              checkNum: _vm.checkNum
            }
          })
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showButton,
              expression: "showButton"
            }
          ],
          staticStyle: { width: "0", height: "0" },
          on: { click: _vm.checkCode }
        },
        [
          _vm._t("check", [
            _c("button", { staticClass: "verify-btn" }, [
              _vm._v(_vm._s(_vm.i18n("ok")))
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }