var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      !_vm.loading
        ? _c("div", { staticClass: "row-content" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "row-item border-bottom-1px" }, [
                _c("div", { staticClass: "row-item-title" }, [
                  _c("span", { staticClass: "money" }, [_vm._v("店铺数据")]),
                  _c(
                    "span",
                    {
                      staticClass: "status status02",
                      on: { click: _vm.toShopData }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            ["未上传", "未上传", "未上传", "去更新", "已上传"][
                              _vm.info.shopDataStatus
                            ]
                          ) +
                          "\n            "
                      ),
                      _c("i")
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "row-item border-bottom-1px" }, [
                _c("div", { staticClass: "row-item-title" }, [
                  _c("span", { staticClass: "money" }, [
                    _vm._v("法人认证授权")
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "status status02",
                      on: { click: _vm.faAuth }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            ["未认证", "去认证", "去授权", "已完成"][
                              _vm.info.personAuthStatus
                            ]
                          ) +
                          "\n            "
                      ),
                      _c("i")
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "row-item border-bottom-1px" }, [
                _c("div", { staticClass: "row-item-title" }, [
                  _c("span", { staticClass: "money" }, [
                    _vm._v("企业认证授权")
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "status status02",
                      on: { click: _vm.comAuth }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            ["未认证", "去认证", "去授权", "已完成"][
                              _vm.info.authStatus
                            ]
                          ) +
                          "\n            "
                      ),
                      _c("i")
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "row-item border-bottom-1px" }, [
                _c("div", { staticClass: "row-item-title" }, [
                  _c("span", { staticClass: "money" }, [_vm._v("补充信息")]),
                  _c(
                    "span",
                    {
                      staticClass: "status status02",
                      on: { click: _vm.toAddData }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            ["未填写", "去更新", "已填写"][
                              _vm.info.supplyStatus
                            ]
                          ) +
                          "\n            "
                      ),
                      _c("i")
                    ]
                  )
                ])
              ])
            ])
          ])
        : _c("c-spin", {
            staticStyle: { "margin-top": "40vh", "text-align": "center" },
            attrs: { tip: "Loading...", size: "large" }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }