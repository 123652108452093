<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-10-29 13:58:38
 * @LastEditors: CZK
 * @LastEditTime: 2024-12-11 17:39:15
-->
<template>
  <div class="container">
    <div class="list-container" v-if="!loading">
      <div class="item">
        <div class="title"><img :src="bankImg[info.enterpriseName]?bankImg[info.enterpriseName]:bankImg['默认']"/>{{info.enterpriseName	}}</div>
        <p class="num">{{info.accountNo}}0</p>
        <p class="desc">{{info.enterpriseName}}</p>
      </div>

      <div class="card-line">
        <span class="title">账户余额</span> <span class="val">￥{{info.balance}}</span>
      </div>
      <div class="card" v-for="(item,key,index) in list" :key="key">
        <div class="title border-bottom-1px" v-if="index===0">
          收款记录
        </div>
        <p class="date">{{key}}</p>
        <!-- @click="toMenu('transactionDetails')" -->
         <!-- @click="toMenu('transactionDetails',{spbId:})" -->
        <div class="card-item border-bottom-1px" v-for="(jtem,jndex) in item" :key="jndex"  >
          <div class="line">
            <span class="title1">{{jtem.summary}}</span><span class="val">{{jtem.crDrMaintInd=='C'?'+':"-"}}{{jtem.tranAmt||0}}元<img src="../../assets/images/img/right_icon.png" alt=""></span>
          </div>
          <p class="line-date">{{jtem.tranDate}}</p>
        </div>

        <!-- <div class="card-item border-bottom-1px" @click="toMenu('transactionDetails')">
          <div class="line">
            <span class="title1">盈峰保理</span><span class="val">+3,000.00元<img src="../../assets/images/img/right_icon.png" alt=""></span>
          </div>
          <p class="line-date">{{jtem.tranDate}}</p>
        </div> -->
      </div>
    </div>

    <c-spin v-else style="margin-top:40vh;text-align: center;" tip="Loading..." size="large"></c-spin>
    <!-- <empty/> -->
  </div>
</template>
<script>
  import empty from './empty.vue'
  import{getEbankAccount,queryEbankAccountTran} from '@/api/user'
  import moment from 'moment'
  import {bankImg} from "@/utils/bankImg"
  export default {
    name: "receivingAccountDetail",
    components:{empty},
    data() {
      return {
        bankImg,
        info:null,
        loading:true,
        list:[]
      }
    },
    methods:{
      formatNumber(numStr) {
          return numStr.replace(/^(\d{4})(\d+)(\d{4})$/,"$1" + "*".repeat(numStr.length - 8) + "$3");
      },
      returnList(rows){
        if(rows.length==0) return {}
        let obj = {}
        rows.forEach(item=>{
          let ym = moment(item.tranDate).format('YYYY年MM月')
          if(obj[ym]){
            item.tranDate = moment(item.tranDate).format('MM.DD hh:mm')
            obj[ym].push(item)
          }else{
            item.tranDate = moment(item.tranDate).format('MM.DD hh:mm')
            obj[ym] = [item]
          }
        })
        return obj
      },
      async getInfo(){
        let res = await getEbankAccount({token:'',type:0})
        if(res.success) this.info = res.data

        let res1 = await queryEbankAccountTran({token:'',type:0})
        if(res1.success) this.list = this.returnList(res1.data.tranRecordList||[])

        console.log(this.list,'............................')
        this.loading = false
      },
      toMenu(name){
        this.$router.push({name})
      }
    },
    mounted(){
      this.getInfo()
    }
  }
</script>
<style lang="less" scoped>
  .container {
    width: 100%;
    background: #F5F7FB url(../../assets/images/img/apply_bg.png) no-repeat;
    background-size: 100% auto;
    .list-container{
      margin:22px auto 0;
      width: 343px;
      .card-line{
        width: 343px;
        height: 46px;
        background: #FFFFFF;
        box-shadow: 0px 3 8px 0px rgba(35,96,170,0.1);
        border-radius: 8px 8px 8px 8px;
        padding:0 17px;
        display:flex;
        justify-content: space-between;
        align-items: center;

        .title{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
        }
        .val{
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #333333;
        }
      }
      .item{
        width: 343px;
        height: 120px;
        background: #FFFFFF;
        box-shadow: 0px 3 8px 0px rgba(51,51,51,0.1);
        border-radius: 8px 8px 8px 8px;
        padding: 14px 24px;
        margin-bottom: 16px;
        .title{
          display: flex;
          align-items:center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #333333;
          line-height: 20px;

          img{
            width:30px;
            height:30px;
            object-fit: contain;
            margin-right:8px;
          }
        }
        .num{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 18px;
          color: #333333;
          line-height: 23px;
          margin-top:7px;
          letter-spacing: 3px;
        }
        .desc{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          margin-top: 11px;
          line-height: 18px;
        }
      }

      .card{
        width: 343px;
        margin-top: 16px;
        padding: 0 11px;
        background: #FFFFFF;
        box-shadow: 0px 3 8px 0px rgba(35,96,170,0.1);
        border-radius: 8px 8px 8px 8px;
        .title{
          padding:0 6px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #333333;
          height:46px;
          line-height: 46px;
        }
        .date{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #1C2023;
          line-height: 18px;
          padding-top: 15px;
          margin-left: 6px;
        }
        .card-item{
          width: 100%;
          height: 70px;
          display: flex;
          padding:  0 6px;
          flex-direction:column;
          justify-content: center;
          .line-date{
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            line-height: 18px;
            margin-top:8px;
          }
          .line{
            display:flex;
            justify-content: space-between;
            align-items: center;
            .title1{
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 16px;
              color: #333333;
            }
            .val{
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #333333;
              display:flex;
              align-items:center;
              img{
                width:24px;
                height:24px;
                object-fit:contain;
              }
            }
          }
        }
      }
    }
  }
</style>