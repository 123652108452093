<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-09-29 15:33:11
 * @LastEditors: CZK
 * @LastEditTime: 2025-01-08 14:28:45
-->
<template>
  <div class="container">
      <div class="title-box">
        <p class="title">申请借款</p>
        <p class="desc">Hi，请填写以下资料吧~</p>
      </div>

      <div class="info-faren-main">
        <div class="info-faren-title-box border-bottom-1px">
          <p class="info-faren-title">申请信息</p>
        </div>
        <div class="info-faren-content">

          <div class="faren-form">
            <div class="form-item border-bottom-1px yuan">
              <label>申请金额</label>
              <input type="number" v-model="info.loanAmount" placeholder="请输入">
            </div>

            <div class="form-item border-bottom-1px">
              <label>收款账户</label>
              <div class="bank-box b" @click="info.receiveAccountId?'':addBank()">
                {{info.receiveAccountId?`${banks[currentBankIndex].bankName}（${banks[currentBankIndex].bankNo.slice(-4)}）`:'请选择'}}
              </div>
            </div>
            
            
            <div class="form-item border-bottom-1px r" @click="selectTap">
              <label>用&emsp;&emsp;途</label>
              <input type="text" v-model="info.loanPurposeName" placeholder="请选择" readonly>
            </div>

            <div class="form-item border-bottom-1px day">
              <label>期&emsp;&emsp;限</label>
              <!-- <input type="number"  v-model="info.loanDatelimit" placeholder="请输入" @blur="getLilv"> -->
              <span>{{info.loanDatelimit}}</span>
            </div>

            <!-- <div class="form-item border-bottom-1px ">
              <label>还款方式</label>
              <span>{{info.repayModeName}}</span>
            </div> -->
            
          </div>
        </div>
      </div>

      <div class="info-faren-main">
          <div class="info-faren-content">
            <div class="faren-form">
              <div class="form-item border-bottom-1px">
                <label>综合服务费</label>
                <span>{{interestRate}}%</span>
              </div>
              <!-- <div class="form-item border-bottom-1px">
                <label>融资费率</label>
                <span>{{interestRate}}%</span>
              </div>
              <div class="form-item border-bottom-1px">
                <label>平台服务费</label>
                <span>{{serviceChargeRate}}%</span>
              </div>
              <div class="form-item border-bottom-1px">
                <label>渠道服务费</label>
                <span>{{channelServiceChargeRate}}%</span>
              </div> -->
            </div>
          </div>

          <div class="agreement-box">
              <i></i>
              <p>本人已阅读并同意
                <a v-for="(item,index) in contractList" :key="index" @click="toUrl(`《${item.templateName}》`,item.url)">《{{item.templateName}}》</a>
              </p>
            </div>

          <div class="add-btn on" @click="commit">
            <cube-loading v-if="commiting"></cube-loading>
            <span>提交订单</span>
          </div>
      </div>

      <!-- 二次确认弹窗 -->
    <transition name="cube-action-sheet-fade">
      <cube-popup
        type="repayment-confrim"
        position="bottom"
        :visible="isConfromVisibleRpMask"
        @mask-click="isConfromVisibleRpMask=false"
      >
        <transition name="cube-action-sheet-move">
          <div class="confrim-content" v-show="isConfromVisibleRpMask" @click.stop>
            <div class="header border-bottom-1px">
              <i @click="isConfromVisibleRpMask=false"></i>收款账户
            </div>
            <div class="content" ref="scrollContainer">
              <div class="bank-box border-bottom-1px" :class="{on:currentBankIndex==index}" @click="selectBank(index)" v-for="(item,index) in banks" :key="index">
                <img :src="bankImg[item.bankName]?bankImg[item.bankName]:bankImg['默认']"/>
                <span>{{item.bankName}}（{{item.bankNo.slice(-4)}}）</span>
              </div>

              <div class="bank-box border-bottom-1px" @click="addBank">
                <img src="../../assets/images/img/add_icon.png"/>
                <span>添加新账户</span>
              </div>
            </div>
          </div>
        </transition>
      </cube-popup>
    </transition>
  </div>

</template>
<script type="text/ecmascript-6">
import {bankImg} from "@/utils/bankImg"
import{confirmApply,getLeagueIncomeAccountList,getSelectOption1,getLoanFeeRuleTo,getContractList} from '@/api/user'
import {findNextFlowThenJump1} from "./findNextFlow"
export default {
  name: "withdrawalApplication",
  components: {
 
  },
  data(){
    return {
      use:[{text:'生产经营',value:1},{text:'支付货款',value:2}],
      bankImg,
      info:{
        "feeRuleId": 0,
        "loanAmount": 0,
        "loanDatelimit": 45,
        "loanPurpose": "",
        loanPurposeName:'',
        "receiveAccountId": 0,
        "repayMode": "",
        repayModeName:''
      },
      total:0,
      minTotal:0,
      isConfromVisibleRpMask:false,
      banks:[],
      currentBankIndex:0,
      commiting:false,
      feeRuleInfo:null,
      totalFee:0,
      contractList:[],
      interestRate:0,
      serviceChargeRate:0,
      channelServiceChargeRate:0
    }
  },
  methods: {
    toUrl(name,url){
      this.$router.push({
        name: "AgreementView",
        query: {
          protocolName:name,
          url
        }
      });
    },
   async commit(){
      if(this.commiting) return
      let _that = this
      function showToast(message) {  
        _that.$createToast({txt: message,type: "error"}).show();
      }

      const{loanAmount,loanDatelimit,loanPurpose,receiveAccountId,repayMode}=this.info
      if(loanAmount<this.minTotal||loanAmount>this.total) return showToast('申请金额必须在5000-'+this.total+'之间')
      console.log(loanDatelimit,'------------------',this.maxDay)
      if(parseInt(loanDatelimit||0)<1||parseInt(loanDatelimit)>parseInt(this.maxDay)) return showToast(`期限必须在1-${this.maxDay}之间`)
      if(!loanPurpose) return showToast('请选择用途')
      if(!receiveAccountId) return showToast('请选择收款账户')
      // if(!repayMode) return showToast('请选择还款方式')
      this.commiting = true
      let res = await confirmApply({...this.info,token:''})
      if(res.success){
        this.$createToast({txt: '提交成功',type: "success"}).show();
        findNextFlowThenJump1()
      }
      this.commiting = false
    },
    selectBank(index){
      this.currentBankIndex = index
      this.info.receiveAccountId = this.banks[index].id
      this.isConfromVisibleRpMask=false
    },
    async getSelectOption1(){
     let res = await getSelectOption1({token:'',name:'RepayMode,LoanPurpose'})
     this.use = (res.data.LoanPurpose||[]).map(item=>({text:item.key_,value:item.value_}))
     this.info.repayMode = (res.data.RepayMode||[])[0].value_
     this.info.repayModeName = (res.data.RepayMode||[])[0].key_
    },
    async getBankList(){
      let res = await getLeagueIncomeAccountList({token:''})
      this.banks = res.data||[]
      this.currentBankIndex = 0
      this.info.receiveAccountId = this.banks.length>0?this.banks[0].id:''
    },
    selectTap(){

      if (!this.picker) {
        this.picker = this.$createPicker({
          title: '用途',
          data: [this.use],
          onSelect: this.selectHandle
        })
      }
      this.picker.show()
    },
    selectHandle(selectedVal, selectedIndex, selectedText){
      // console.log(selectedVal, selectedIndex, selectedText)
      this.info.loanPurpose = selectedVal[0]
      this.info.loanPurposeName = selectedText[0]
    },
    addBank(){
      this.$router.push({name:'receivingAccount'})
    },
    async getLoanFeeRuleTo(){
     let res =  await getLoanFeeRuleTo({token:''})
     this.info.loanAmount = res.data.maxLoanAmount
     this.minTotal = parseFloat(res.data.minLoanAmount||0)
     this.total = parseFloat(res.data.maxLoanAmount||0)
     this.maxDay = res.data.maxLoanLimitDate
     this.info.loanDatelimit = res.data.maxLoanLimitDate||45
    //  this.feeRuleInfo = res.data
      this.interestRate = res.data.interestRate
    //  this.getRule(this.maxDay)
    },
    getLilv(){
      this.getRule(this.info.loanDatelimit||0)
    },
    getRule(day){
      const {mode,feeRuleList} = this.feeRuleInfo
      let totalFee = 0
      let interestRate = 0
      let serviceChargeRate = 0
      let channelServiceChargeRate = 0
      if(mode>0){
        feeRuleList.forEach(item=>{
          interestRate =  parseFloat(item.interestRate)
          serviceChargeRate = parseFloat(item.serviceChargeRate)
          channelServiceChargeRate = parseFloat(item.channelServiceChargeRate)
        })
        
      }else{
        feeRuleList.forEach(item=>{
          if(day>= parseInt(item.minDay||0)&&day<=parseInt(item.maxDay||0)){
            interestRate =  parseFloat(item.interestRate)
            serviceChargeRate = parseFloat(item.serviceChargeRate)
            channelServiceChargeRate = parseFloat(item.channelServiceChargeRate)
          }
        })
      }
      totalFee = interestRate + serviceChargeRate + channelServiceChargeRate


      this.totalFee = totalFee
      this.interestRate = interestRate
      this.serviceChargeRate = serviceChargeRate
      this.channelServiceChargeRate = channelServiceChargeRate

    },
    async ContractList(){
     let res = await getContractList({token:''})
     this.contractList = res.data
    }

  },
  mounted(){
    const scrollContainer = this.$refs.scrollContainer;  
    scrollContainer.addEventListener('touchmove', function(e) {  
      e.stopPropagation();  
    }, false); 

    const {total} = this.$route.query
    // this.total = parseFloat(total||0)
    // this.info.loanAmount = parseFloat(total||0)
    this.getSelectOption1()
    this.getLoanFeeRuleTo()
    this.getBankList()
    this.ContractList()
  }
}
</script>
<style lang="less" scoped>
input{
  all: unset;
}
.container{
width: 100%;
background:#F5F7FB url("../../assets//images/img/bg1.png") no-repeat;
background-size: contain;
.title-box{
  margin-top: 27px;
  margin-left: 32px;
  .title{
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 18px;
  }
  .desc{
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 22px;
    margin-top: 12px;
  }
}
.info-faren-main{
  width: 343px;
  // height: 500px;
  background: #FFFFFF;
  margin: 17px auto 0;
  border-radius: 8px 8px 8px 8px;
  padding: 0 12px 12px;
  .info-faren-title-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    line-height: 50px;
    .select-box{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      display: flex;
      line-height: 18px;
      span{display: flex;align-items: center;}
      i{
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url("../../assets/images/img/no.png") no-repeat;
        background-size: contain;
        margin-left: 10px;
        margin-right: 10px;
        &.yes{
          background: url("../../assets/images/img/yes.png") no-repeat;
        }
      }
    }
  }
  .agreement-box{
    display: flex;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    line-height: 18px;
    margin: 16px 0;
    text-align: justify;
    a{
      color:#3399FF;
    }
    i{
      display: block;
      width: 18px;
      height: 18px;
      margin-right: 8px;
      flex-shrink: 0;
      background: url("../../assets/images/img/check_on.png") no-repeat;
      background-size: contain;
    }
  }
  .info-faren-title{
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    margin-left: 5px;
    color: #1C2023;
    line-height: 20px;
    &::after{
      content: '*';
      color:#FA5151;
    }
  }
  .info-faren-content{
    display: flex;
    justify-content: space-between;
  }
  .tips{
    width: 321px;
    height: 40px;
    background: #FFFBE1;
    border-radius: 8px 8px 8px 8px;
    padding: 10px 0 10px 6px;
    text-align: left;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    margin: 14px 0;
    color: #FFA54C;
  }
  .faren-form{
    width: 100%;
    .form-item1{
      width: 100%;
      display: flex;
      justify-content: space-between;
      min-height: 50px;
      label{
        width: 56px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        flex-shrink: 0;
        color: #666666;
        margin-left: 6px;
        line-height: 50px;
        margin-right: 32px;
      }
      .types-box{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        line-height: 18px;
        margin-top: 16px;
        p{
          display: flex;
          margin-bottom: 11px;
        }
        i{
          display: inline-block;
          width: 16px;
          height: 16px;
          flex-shrink: 0;
          background: url("../../assets/images/img/no.png") no-repeat;
          background-size: contain;
          margin-right: 10px;
          &.yes{
            background: url("../../assets/images/img/yes.png") no-repeat;
          }
        }
      }
    }
    .form-item{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      position: relative;
      .bank-box{
        background: #F5F5F5;
        width: 232px;
        height: 38px;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #C9C9C9;
        padding: 0 6px;
        display: flex;
        align-items: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        &.b{
          &::before{
            content: '';
            width: 24px;
            height: 24px;
            background: url("../../assets/images/img/b_icon.png") no-repeat;
            background-size: contain;
            display: block;
            position: absolute;
            right: 0;
          }
        }
      }
      &.r{
        &::before{
          content: '';
          width: 24px;
          height: 24px;
          background: url("../../assets/images/img/right_icon.png") no-repeat;
          background-size: contain;
          display: block;
          position: absolute;
          right: 0;
        }
      }
      &.yuan{
        &::before{
          content: '元';
          position: absolute;
          right: 6px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
        }
      }
      &.day{
        &::before{
          content: '天';
          position: absolute;
          right: 6px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
        }
      }
      label{
        width: 70px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        flex-shrink: 0;
        color: #666666;
        margin-left: 6px;
        line-height: 20px;
        margin-right: 32px;
      }
      input{
        flex: 1;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        &::placeholder{
          color: #999999;
        }
      }
      span{
        flex: 1;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
      }
    }
  }
  .add-btn{
    width: 321px;
    height: 42px;
    text-align: center;
    background: #CCCCCC;
    border-radius: 30px 30px 30px 30px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 42px;
    margin: 22px auto 0;
    color: #FFFFFF;
    &.on{
      background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
      margin: 10px auto 16px;
    }
  }
}
}
.confrim-content{
  width: 100%;
  height: 374px;
  background: #FFFFFF;
  border-radius: 8px 8px 0px 0px;
  padding: 0 12px;
  .header{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    position: relative;
    i{
      display: block;
      width: 24px;
      height: 24px;
      background: url("../../assets/images/img/×.png") no-repeat;
      background-size: contain;
      position: absolute;
      left: 10px;
    }
  }
  .content{
    width: 100%;
    height: calc(374px - 50px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    .bank-box{
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #F5F5F5;
      padding: 0 12px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      &.on{
        font-weight: 600;
        position: relative;
        &::before{
          content: '';
          display: block;
          width: 24px;
          height: 24px;
          background: url("../../assets/images/img/gou.png") no-repeat;
          background-size: contain;
          position: absolute;
          right: 12px;
        }
      }
      img{
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }
      span{
        text-align: left;
    }
  }
}
}
</style>