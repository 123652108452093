var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.info
    ? _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "card" }, [
          _c("p", { staticClass: "danwei" }, [_vm._v("结算金额(元)")]),
          _c("p", { staticClass: "money" }, [
            _vm._v(_vm._s(_vm.info.repaymentDiff || 0))
          ]),
          _c("div", { staticClass: "date-line border-bottom-1px" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.info.signDate))
            ]),
            _c("span", { staticClass: "val" }, [
              _vm._v("请款" + _vm._s(_vm.info.loanAmount || 0) + "元\n        ")
            ])
          ]),
          _c("div", { staticClass: "line" }, [
            _c("span", { staticClass: "title" }, [_vm._v("结算金额")]),
            _c("span", { staticClass: "val" }, [
              _vm._v("￥" + _vm._s(_vm.info.repaymentDiff || 0))
            ])
          ]),
          _c("div", { staticClass: "small-line" }, [
            _c("span", { staticClass: "title" }, [_vm._v("本金")]),
            _c("span", { staticClass: "val" }, [
              _vm._v("￥" + _vm._s(_vm.info.principalDiff || 0))
            ])
          ]),
          _c("div", { staticClass: "small-line" }, [
            _c("span", { staticClass: "title" }, [_vm._v("融资费")]),
            _c("span", { staticClass: "val" }, [
              _vm._v("￥" + _vm._s(_vm.info.basicInterestDiff || 0))
            ])
          ]),
          _c("div", { staticClass: "small-line" }, [
            _c("span", { staticClass: "title" }, [_vm._v("管理费")]),
            _c("span", { staticClass: "val" }, [
              _vm._v("￥" + _vm._s(_vm.info.platformServiceDiff || 0))
            ])
          ]),
          _c("div", { staticClass: "small-line" }, [
            _c("span", { staticClass: "title" }, [_vm._v("服务费")]),
            _c("span", { staticClass: "val" }, [
              _vm._v("￥" + _vm._s(_vm.info.channelServiceDiff || 0))
            ])
          ]),
          _c("div", {
            staticClass: "border-bottom-1px",
            staticStyle: { "margin-top": "9px" }
          }),
          _c("div", { staticClass: "line border-bottom-1px" }, [
            _c("span", { staticClass: "title" }, [_vm._v("结算账户")]),
            _c("span", { staticClass: "val" }, [
              _vm._v(
                _vm._s(_vm.info.bankName) +
                  "(尾号" +
                  _vm._s(_vm.info.bankAccount) +
                  ")"
              )
            ])
          ]),
          _c("div", { staticClass: "line border-bottom-1px" }, [
            _c("span", { staticClass: "title" }, [_vm._v("结算方式")]),
            _c("span", { staticClass: "val" }, [
              _vm._v(_vm._s(_vm.info.repayType))
            ])
          ]),
          _c("div", { staticClass: "line border-bottom-1px" }, [
            _c("span", { staticClass: "title" }, [_vm._v("结算时间")]),
            _c("span", { staticClass: "val" }, [
              _vm._v(_vm._s(_vm.info.repayTime))
            ])
          ]),
          _c("div", { staticClass: "line border-bottom-1px" }, [
            _c("span", { staticClass: "title" }, [_vm._v("结算状态")]),
            _c("span", { staticClass: "val" }, [
              _vm._v(_vm._s(_vm.info.status))
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }