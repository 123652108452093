<template>
  <div class="container" v-if="info" :class="info.orderStatus">
    <p class="apply-status">{{info.orderStatusName}}</p>

    <div class="apply-container">
      <div class='line-item border-bottom-1px'>
        <span class="line-item-title">请款单号</span>
        <span class="line-item-content">{{info.orderNo}}<label v-clipboard:copy="info.orderNo"  v-clipboard:success="onCopy" v-clipboard:error="onError">复制</label></span>
      </div>
      <div class='line-item border-bottom-1px'>
        <span class="line-item-title">店铺名称</span>
        <span class="line-item-content">{{info.shopName}}</span>
      </div>
      <div class='line-item border-bottom-1px'>
        <span class="line-item-title">所属平台</span>
        <span class="line-item-content">{{info.platformType}}</span>
      </div>
      <div class='line-item border-bottom-1px'>
        <span class="line-item-title">申请时间</span>
        <span class="line-item-content">{{info.applyTime}}</span>
      </div>
      <div class='line-item border-bottom-1px'>
        <span class="line-item-title">请款金额</span>
        <span class="line-item-content">¥{{info.loanAmount||0}}</span>
      </div>

      <div class='line-item border-bottom-1px'>
        <span class="line-item-title">请款期限</span>
        <span class="line-item-content">{{info.loanDateLimit||0}}天</span>
      </div>

      <div class='line-item border-bottom-1px' v-if="info.loanName">
        <span class="line-item-title">出借方</span>
        <span class="line-item-content">{{info.loanName}}</span>
      </div>

      <div class='line-item border-bottom-1px' v-if="info.paySuccessTime">
        <span class="line-item-title">放款时间</span>
        <span class="line-item-content">{{info.paySuccessTime}}</span>
      </div>


      <div class='line-item border-bottom-1px' v-if="info.signatureContractList&&info.signatureContractList.length>0">
        <span class="line-item-title">服务合同</span>
        <!-- <span class="line-item-content">暂无</span> -->

        <span class="line-item-content look" @click="isAgreenVisibleRpMask=true">查看<i></i></span>
      </div>
      <div class='line-item border-bottom-1px' >
        <span class="line-item-title">还款记录</span>
        <!-- <span class="line-item-content">暂无</span> -->
        <span class="line-item-content look" @click="toMenu('billingDetails',{applyId:info.applyId})">查看<i></i></span>
      </div>


      <div class="btn main" v-if="info.continueApplyStatus == '1'" @click='nextApply'>继续申请</div>
      <div class="btn two" v-if="info.afterAuditStatus=='1'" @click="isConfromVisibleRpMask=true">取消申请</div>
    </div>

    

    <!-- 二次确认弹窗 -->
    <transition name="cube-action-sheet-fade">
        <cube-popup
          type="repayment-confrim"
          position="bottom"
          :visible="isAgreenVisibleRpMask"
          @mask-click="isAgreenVisibleRpMask=false"
        >
          <transition name="cube-action-sheet-move">
            <div class="apply-container">
              <div class='line-item1 border-bottom-1px'>
                <span class="line-item-title1 hot">点击查看服务合同</span>
              </div>

              <div class='line-item1 border-bottom-1px' v-for="(item,index) in info.signatureContractList" :key='index'>
                <span class="line-item-title1 blue" @click="open(item.templateName,item.viewUrl)">《{{item.templateName}}》</span>
              </div>

              <!-- <div class='line-item1 border-bottom-1px'>
                <span class="line-item-title1 blue">《保理合同》</span>
              </div> -->

              <div class="btn main" @click="isAgreenVisibleRpMask=false">关闭</div>
            </div>
          </transition>
        </cube-popup>
      </transition>



      <!-- 二次确认弹窗 -->
      <transition name="cube-action-sheet-fade">
        <cube-popup
          type="repayment-confrim"
          position="center"
          :visible="isConfromVisibleRpMask"
          @mask-click="isConfromVisibleRpMask=false"
        >
          <transition name="cube-action-sheet-move">
            <div class="confrim-content" v-show="isConfromVisibleRpMask" @click.stop>
              <p class="title">提示</p>
              <p class="desc">确定要取消本次申请吗？</p>
              <div class="footer-box">
                <span class="btn two" @click="isConfromVisibleRpMask=false">取消</span>
                <span class="btn main" @click="cancel">确定</span>
              </div>
            </div>
          </transition>
        </cube-popup>
      </transition>
  </div>
</template>
<script>
  import Vue from "vue";
  import VueClipboard from "vue-clipboard2";
  Vue.use(VueClipboard);
  import { Toast } from "cube-ui";
  import { getLoanApplyDetail,cancelLoanApply } from "@/api/user";
  import {findNextFlowThenJump1} from "./findNextFlow"
  export default {
    name: "applyDetail",
    data() {
      return {
        status:'pingtaishenhe',
        isConfromVisibleRpMask:false,
        isAgreenVisibleRpMask:false,
        info:null
        // 这里放置数据
      }
    },
    mounted(){
      this.getDetail()
    },
    methods:{
      nextApply(){
        findNextFlowThenJump1()
      },
      open(name,url){
        this.$router.push({
          name:'AgreementView',
          query: {
            protocolName: name,
            url: url
          }
        })
      },
      toMenu(name,query){
        this.$router.push({name,query})
      },
      async cancel(){
       let res = await cancelLoanApply({token:'',applyId:this.info.applyId})
       if(res.success){
        Toast.$create({
          txt: "已取消",
          type: "success",
          time: 1000,
        }).show();
        this.$router.back()
       }
      },
      async getDetail(){
        const {applyId} = this.$route.query;
        let res = await getLoanApplyDetail({token:'',applyId})
        this.info = res.data
      },
      onCopy(e){
        Toast.$create({
          txt: "已复制成功",
          type: "warn",
          time: 1000,
        }).show();
        console.log(e,'0000')
      },
      // 复制失败时的回调函数
      onError (e) {
        console.log("抱歉，复制失败！", e);
        Toast.$create({
          txt: "抱歉，复制失败！",
          type: "warn",
          time: 1000,
        }).show();
      },
    }
  }
</script>
<style lang="less" scoped>
.container{
  background: #F5F7FB url("../../assets/images/img/applying_icon.png") top 17px center no-repeat;
  &.Applying{
    background-image:url("../../assets/images/img/applying_icon.png");
  }

  &.Repaying{
    background-image:url("../../assets/images/img/give_moeny_icon.png");
  }

  &.IsOverdue{
    background-image:url("../../assets/images/img/overduing_icon.png");
  }

  &.Settled{
    background-image:url("../../assets/images/img/no_give_icon.png");
  }

  &.WaitAudit{
    background-image:url("../../assets/images/img/watting_icon.png");
  }

  &.Rejected{
    background-image:url("../../assets/images/img/rejected_icon.png");
  }

  .confrim-content{
    width: 316px;
    height: 158px;
    background: #FFFFFF;
    display:flex;
    flex-direction: column;
    border-radius: 8px 8px 8px 8px;
    .title{
      text-align:center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #3D3D3D;
      margin-top:22px;
    }
    .desc{
      text-align:center;
      font-family: PingFangSC-Medium, PingFangSC-Medium;
      font-weight: 400;
      font-size: 16px;
      color: #353535;
      margin-top:14px;
    }
    .footer-box{
      margin-top:25px;
      display:flex;
      justify-content: space-around;
      .btn{
        width: 108px;
        height: 36px;
        text-align:center;
        line-height:36px;
        font-family: PingFangSC-Semibold, PingFangSC-Semibold;
        font-weight: 400;
        font-size: 16px;
        border-radius: 30px 30px 30px 30px;
        &.main{
          background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
          color: #FFFFFF;
        }
        &.two{
          border: 1px solid #009DFF;
          color:#009DFF;
        }
      }
    }
  }
  .apply-status{
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 18px;
    color: #333333;
    line-height: 20px;
    margin-top: 114px;
    text-align:center;
  }

  .apply-container{
    width: 343px;
    background: #FFFFFF;
    margin: 18px auto 20px;
    border-radius: 8px 8px 8px 8px;
    padding: 0 11px 22px;
    .line-item1{
      width: 321px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      .line-item-title1{
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #1C2023;
        &.blue{
          color: #3399FF;
        }
        &.hot{
          &:after{
            content:'*';
            color:#FA5151;
          }
        }
      }
    }
    .line-item{
      width: 321px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .line-item-title{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
      }
      .line-item-content{
        display: flex;
        align-items: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        &.look{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #3399FF;
          line-height: 18px;
          i{
            width:24px;
            height:24px;
            display:block;
            background:url("../../assets/images/img/right03.png") no-repeat;
          }
        }
        label{
          display:block;
          width: 40px;
          height: 20px;
          text-align:center;
          line-height:20px;
          background: #FFFFFF;
          border-radius: 8px 8px 8px 8px;
          border: 1px solid #333333;
          font-family: PingFangSC-Regular, PingFangSC-Regular;
          font-weight: 400;
          font-size: 12px;
          color: #333333;
          margin-left: 10px;
        }
      }
      
    }

    .btn{
        width: 321px;
        height: 42px;
        border-radius: 30px 30px 30px 30px;
        display:flex;
        align-items:center;
        justify-content:center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        &.main{
          background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
          margin-top:22px;
          color: #FFFFFF;
        }
        &.two{
          border: 1px solid #009DFF;
          margin-top:16px;
          color: #009DFF;
        }
      }
  }
  
}
</style>