<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-12-23 10:18:28
 * @LastEditors: CZK
 * @LastEditTime: 2024-12-23 10:20:43
-->
<template>
  <div class="container">
    <div class="row-content" v-if="!loading">
      <div class="row" >
        
        <div class="row-item border-bottom-1px" >
          <div class="row-item-title">
            <span class="money">店铺数据</span>
            <span class="status status02" @click="toShopData">
              {{['未上传','未上传','未上传','去更新','已上传'][info.shopDataStatus]}}
              <i></i>
            </span>
          </div>
          <!-- <p class="row-item-date">{{jtem.applyTime}}</p> -->
        </div>

        <div class="row-item border-bottom-1px" >
          <div class="row-item-title">
            <span class="money">法人认证授权</span>
            <span class="status status02" @click="faAuth">
              {{['未认证','去认证','去授权','已完成'][info.personAuthStatus]}}
              <i></i>
            </span>
          </div>
          <!-- <p class="row-item-date">{{jtem.applyTime}}</p> -->
        </div>

        <div class="row-item border-bottom-1px" >
          <div class="row-item-title">
            <span class="money">企业认证授权</span>
            <span class="status status02" @click="comAuth">
              {{['未认证','去认证','去授权','已完成'][info.authStatus]}}
              <i></i>
            </span>
          </div>
          <!-- <p class="row-item-date">{{jtem.applyTime}}</p> -->
        </div>

        <div class="row-item border-bottom-1px" >
          <div class="row-item-title">
            <span class="money">补充信息</span>
            <span class="status status02" @click="toAddData">
              {{['未填写','去更新','已填写'][info.supplyStatus]}}
              <i></i>
            </span>
          </div>
          <!-- <p class="row-item-date">{{jtem.applyTime}}</p> -->
        </div>
      </div>

    </div>
    <c-spin v-else style="margin-top:40vh;text-align: center;" tip="Loading..." size="large"></c-spin>
    
  </div>
</template>
<script>
  import { getManagementDataInfoTo,getAuthMerchantStatus,doOrganAuth,getPersonVerifyUrl,getPersonAutoSignAuthResult } from '@/api/user'
  import moment from "moment";
  export default {
    name: "",
    components:{},
    data() {
      return {
        info:{},
        list:null,
        loading:true
      }
    },
    computed: {
      
    },
    methods:{
      toShopData(){
        switch(this.info.shopDataStatus){
          case '0':
            this.$router.push('companyInformation')
            break;
          case '1':
            this.$router.push('storeInfo')
            break;
          case '2':
          case '3':
            this.$router.push('additionalInformation')
            break;
          default:
            break;
        }
      },
      toAddData(){
        if(this.info.supplyStatus==1){
          this.$router.push('enterpriseSupplementInfo')
        }
      },
      async faAuth(){
        if(this.info.personAuthStatus==1){
          let res = await getPersonVerifyUrl({
            token:''
          })
          if (res.success && res.data) {
            const { verifyUrl } = res.data;
            window.location.href = verifyUrl;
          }
        }
        if(this.info.personAuthStatus==2){
         let res = await getPersonAutoSignAuthResult({
            token:''
          })
            if (res.success && res.data) {
              const { status,authUrl } = res.data;
              if (status == "0") {
                window.location.href = authUrl;
              }
            }
        }

      },
      async comAuth(){
        if(this.info.authStatus==1){
         let res = await doOrganAuth({token: ""})
          if (res.success && res.data) {
            const { authStatus,url } = res.data;
            if (authStatus != "4") {
              window.location.href = url;
            }
          }
        }

        if(this.info.authStatus==2){
         let res = await getAuthMerchantStatus({token: ""})
          if (res.success && res.data) {
            const { status, authUrl } = res.data;
            if (status == "0") {
              window.location.href = authUrl;
            }
          }
        }
      },
      returnClass(name){
       let i = this.status.findIndex(item=>item==name)
       return  i>-1?`status0${i+1}`:''
      },
      toPage(name,applyId){
        this.$router.push({name,query:{applyId}})
      },
      async getinfo(){
       let res = await getManagementDataInfoTo({token:''})
       if(res.success){
        this.info = res.data
        setTimeout(()=>{
          this.loading = false
        },1000)
        
       }
      }
    },
    mounted(){
      this.getinfo()
    }
  }
</script>
<style lang="less" scoped>
  .container {
    width:100%;
    background:#F5F7FB url("../../assets/images/img/apply_bg.png") no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    .row-content{
      width:343px;
      margin:22px auto 0;
      .row{
        width: 343px;
        background: #FFFFFF;
        border-radius: 8px 8px 8px 8px;
        padding: 0 11px;
        margin-bottom:16px;
        .row-time{
          width:100%;
          height: 40px;
          line-height: 40px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #1C2023;
        }
        .row-item{
          padding:12px 6px;
          display:flex;
          flex-direction: column;
          justify-content: space-between;
          .row-item-title{
            display:flex;
            align-items:center;
            justify-content: space-between;
            .money{
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #333333;
            }
            .status{
              width: 74px;
              height: 26px;
              border-radius: 5px 5px 5px 5px;
              display: flex;
              font-size:12px;
              justify-content: flex-end;
              align-items: center;
              i{
                display:block;
                width:24px;
                height:24px;
              }
              &.status01{
                background: #FFEDE1;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #FF6A00;
                i{
                  background: url("../../assets/images/img/right01.png") no-repeat;
                  background-size: contain;
                }
              }

              &.status02{
                background: #EAEDFE;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 12px;
                color: #304FFE;
                i{
                  background: url("../../assets/images/img/right02.png") no-repeat;
                  background-size: contain;
                }
              }

              &.status03{
                background: #E8F6FC;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 12px;
                color: #007BFF;
                i{
                  background: url("../../assets/images/img/right03.png") no-repeat;
                  background-size: contain;
                }
              }

              &.status04{
                background: #E5F9ED;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #1BA555;
                i{
                  background: url("../../assets/images/img/right04.png") no-repeat;
                  background-size: contain;
                }
              }

              &.status05{
                background: #FFEFEF;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #FF0000;
                i{
                  background: url("../../assets/images/img/right05.png") no-repeat;
                  background-size: contain;
                }
              }

              &.status06,&.status07,&.status08{
                background: #F6EFFF;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #8A38F5;
                i{
                  background: url("../../assets/images/img/right06.png") no-repeat;
                  background-size: contain;
                }
              }
              
            }
          }
          .row-item-date{
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            margin-top: 7px;
            line-height: 18px;
          }
        }
      }
    }
  }
</style>