<template>
  <div class="container">
    <div class="row">
      <label class="t">手&nbsp;机&nbsp;号</label>
      <input type="text" maxlength="11" placeholder="请输入手机号" v-model="form.mobile" />
    </div>
    <div class="row">
      <label class="t">验&nbsp;证&nbsp;码</label>
      <input type="text" maxlength="11" placeholder="请输入验证码" v-model="form.authCode" />

      <div
        class="get-code"
        @click.stop.prevent="getCaptcha"
      >{{ state.smsSendBtn ? state.time + 's后重发' : '获取验证码'}}</div>
    </div>
    <button class="btn1 btn-primary" @click="commit">确定</button>
  </div>
</template>
<script>
import { sendSms,updateUserMobile} from "@/api/user";
const LIMITTIME = 60;
  export default {
    data(){
      return{
        state: {
          time: LIMITTIME,
          smsSendBtn: false
        },
        form:{
          "authCode": '',
          "authId": '',
          "mobile": '',
        },
        mobile:'',
        code:'',
        tattedId:'',
        isConfromVisibleRpMask:false
      }
    },
    methods: {
      //恢复按钮
    regainCheckBtn(state) {
      state.time = LIMITTIME;
      state.smsSendBtn = false;
    },
    //获取验证码
    getCaptcha() {
      const {  state} = this;
      // if(!this.showCode) return
      if (!this.form.mobile) {
        this.$createToast({
          type: "warn",
          txt: "请输入手机号"
        }).show();
        return;
      }
      setTimeout(() => {
        if (this.form.mobile.length > 0) {
          state.smsSendBtn = true;
          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              this.regainCheckBtn(state);
              window.clearInterval(interval);
            }
          }, 1000);
          const toastSuccess = this.$createToast({
            txt: "验证码发送中",
            type: "correct",
            time: 0
          });
          toastSuccess.show();
          sendSms({mobile:this.form.mobile, authCodeType:'ChangeMobile'})
            .then(res => {
              toastSuccess.hide();
              if (res && res.success && res.data && res.data.authId) {
                this.$createToast({
                  txt: "验证码已发送，请留意",
                  type: "correct"
                }).show();
                // 储存tattedId
                const tattedId = res.data.authId;
                this.form.authId = tattedId;
              } else {
                clearInterval(interval);
                this.regainCheckBtn(state);
              }
            })
            .catch(err => {
              console.log('err', err)
              toastSuccess.hide();
              clearInterval(interval);
              this.regainCheckBtn(state);
            });
        } else {
          this.$createToast({
            txt: "请确认手机号",
            type: "error"
          }).show();
        }
      }, 0);
    },
    async commit(){
      const {form} = this
      if(!form.mobile) return this.$createToast({ txt: "请确认手机号",type: "error"}).show();
      if(!form.authCode) return this.$createToast({ txt: "请确认验证码",type: "error"}).show();
      let res = await updateUserMobile({...form,token:''})
      if(res.success){
        this.$createToast({ txt: "已修改,请重新登陆",type: "success"}).show();
        window.localStorage.clear();
        setTimeout(()=>{
          this.$router.replace({name:'login'})
        },1500)
       
      }
    },

    toAgreementView(protocolName, url) {
      this.$router.push({
        name: "AgreementView",
        query: {
          protocolName,
          url
        }
      });
    },
  },
  created() {

  }
}
</script>
<style lang="less" scoped>
.container{
  background: #F5F7FB;
  .row{
    width: 343px;
    height: 46px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    margin:10px auto 0;
    display:flex;
    align-items:center;
    justify-content: space-between;
    padding: 0 16px 0 17px;
    .t{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      flex-shrink: 0;
      color: #333333;
      line-height: 18px;
    }
    input{
      all:unset;
      flex:1;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      margin-left:32px;
      font-size: 14px;
      color: #333333;
      &::placeholder{
        color: #999999;
      }
    }
    .get-code{
      white-space: nowrap;
      font-family: PingFang SC;
      line-height: 24px;
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      background: #FFFFFF;
    }
    .v{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 18px;
    }
    &.r{
      position: relative;
      &::after{
        content:'';
        position: absolute;
        right: 10px;
        width: 24px;
        height:24px;
        background: url(~@/assets/images/img/right_icon.png) no-repeat;
        background-size:contain;
      }
    }
  }
  .btn1{
    all:unset;
    width: 321px;
    height: 42px;
    background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
    border-radius: 30px 30px 30px 30px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:30px auto 0;
  }


  .confrim-content{
    width: 316px;
    height: 158px;
    background: #FFFFFF;
    display:flex;
    flex-direction: column;
    border-radius: 8px 8px 8px 8px;
    .title{
      text-align:center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #3D3D3D;
      margin-top:22px;
    }
    .desc{
      text-align:center;
      font-family: PingFangSC-Medium, PingFangSC-Medium;
      font-weight: 400;
      font-size: 16px;
      color: #353535;
      margin-top:14px;
    }
    .footer-box{
      margin-top:25px;
      display:flex;
      justify-content: space-around;
      .btn{
        width: 108px;
        height: 36px;
        text-align:center;
        line-height:36px;
        font-family: PingFangSC-Semibold, PingFangSC-Semibold;
        font-weight: 400;
        font-size: 16px;
        border-radius: 30px 30px 30px 30px;
        &.main{
          background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
          color: #FFFFFF;
        }
        &.two{
          border: 1px solid #009DFF;
          color:#009DFF;
        }
      }
    }
  }
}
</style>