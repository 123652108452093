import { findNextFlow, doOrganAuth ,findProductNextFlow,getPersonVerifyUrl,
  getPersonAutoSignAuthResult,getAuthMerchantStatus,
  doMerchantAdmissionRiskExecute,doTransactionApprovalRiskExecute} from "@/api/user";
import { getServerTime } from "@/utils/requestXafqApp";
import { getFddPersonVerifyUrl,getAuthUserStatus } from "@/api/carFinance";
import { checkXsRule } from "@/api/risk";
import { parseTime } from "@/utils/util";
import { Toast } from "cube-ui";
import router from "@/router";
import { getCustomH5Platform } from "@/utils/isDev";
import {staticConfig} from '@/utils/staticConfig'

// import { encryptText } from "@/utils/auth";

export function findNextFlowThenJump() {
  const customH5Platform = getCustomH5Platform();
  const host = customH5Platform.host;
  return findNextFlow({ token: "",where:host}).then((res) => {
    if (res.success && res.data) {
      const { code, opId, childJson } = res.data;
      switch (code) {
        case 'CommitAdmitOrder': //企业营业执照
          toCompanyInformation()
          break;
        case 'EnterpriseAuth':toDoOrganAuth();break;
        case 'EnterpriseInfo':
          toEnterpriseInfo()
          break;
        case "RelationInfo": // "关联信息"
          toRelationInfo()
          break;
        case 'ShopInfo':
          toShopInfo()
          break;
        case 'SupplyInfo':
          router.push({
            name: "additionalInformation",
            query: {
              childJson,
              type:1
            }
          })
          break;
        case 'AllowAudit':
          toDoMerchantAdmissionRiskExecute()
          break;
        case 'EBankAccount':
          
          break;
        case 'ArtificialPreAudit':
        case 'ArtificialAudit':
        case 'AdmitLoanAudit':
          router.push({
            name: "result",
            query: {
              type:3
            }
          })
          break;
        case 'AllowAuditFail':
        case 'ArtificialPreAuditFail':
        case 'ArtificialAuditFail':
        case 'AdmitLoanAuditFail':
          router.push({
            name: "result",
            query: {
              type:2
            }
          })
          break;

        case 'Nullify':
          break;

        case 'AdmitOver':
          router.push({
            name: "result",
            query: {
              type:3
            }
          })
          break;

        default:
            break;
      }
    } else {
      console.log("findNextFlowThenJump failed");
    }
  }).catch(err=>{
    console.log('接口报错或超时了，需要重新进入流程',err)
    setTimeout(()=>{
      findNextFlowThenJump()
    },5000)
  });
}


export function findNextFlowThenJump1(total) {
  const customH5Platform = getCustomH5Platform();
  const host = customH5Platform.host;
  return findProductNextFlow({ token: "",where:host}).then((res) => {
    if (res.success && res.data) {
      const { code, opId, childJson,subprocessCode } = res.data;
      switch (code) {
        case 'CommitApply': // 提交申请
          // router.push({
          //   name: "withdrawalApplication",
          //   query: {
          //     total
          //   }
          // })
          break;
        case "ConfirmApply":
            router.push({name: "withdrawalApplication"})
          break;
        case 'EBankAccount'://开户
          console.log("-------------------------------")
          switch(subprocessCode){
            case 'CorporateBankInfo': // 对公银行信息
            router.push('openAccount')
            break;
            case 'ConfirmationOfRemittanceAmount': // 打款金额确认
            router.push('openAccount_step_1')
            break;
            case 'OperatorConfirmation': // 经办人确认
            router.push('openAccount_step_2')
            break;
            case 'LegalPersonConfirmation': // 法人确认
            router.push('openAccount_step_3')
            break;
            case 'OpenAcctProcessed': // 开户审核中
              Toast.$create({
                txt: '开户审核中',
                type: "warning",
                time: 3200,
              }).show();
              setTimeout(() => {
                router.replace({name:'xaIndex'})
              }, 3000);
            break;
            case 'OpenAcctFail': // 开户失败
              Toast.$create({
                txt: '开户失败',
                type: "error",
                time: 3200,
              }).show();
              setTimeout(() => {
                router.replace({name:'xaIndex'})
              }, 3000);
            break;
            case "Loading":
              router.push({name:'openLoading'})
              //进入等待页面
              break;
            case 'OpenAcctAuthority':
            router.push({name:'billingAuthorization'})
            break;

            case 'Completed': // 已完成
              Toast.$create({
                txt: '开户已完成',
                type: "success",
                time: 3200,
              }).show();
              setTimeout(() => {
                router.replace({name:'xaIndex'})
              }, 3000);
            break;
          }
          break;
        case 'EnterpriseSupplementInfo': //企业补充信息
          toEnterpriseSupplementInfo()  
        break;
        case 'EnterpriseAuth':toDoOrganAuth();break;
        case 'UpdateShopInfo': //"更新店铺信息"
          router.push({
            name: "additionalInformation",
            query: {
              childJson,
              type:2
            }
          })
          break;
        case 'UpdateSettlementAccount': //"更改结算账户"
            router.push({
              name: "changeReceivingAccount",
              query: {
                
              }
            })
            
          break;
        case 'BindShopAccount': // "绑定店铺账号"
          router.push({
            name: "bindStoreAccount",
            query: {
              
            }
          })
          break;
        case 'EnterpriseSignAuth'://"企业签约授权"
          toGetAuthMerchantStatus()
          break;
        case 'PersonAuth': //"个人实名认证"
          toGetPersonVerifyUrl()
          break;
        case 'PersonSignAuth':  //"个人签约授权"
          toGetAuthUserStatus()
          break;

        case 'TransactionAudit': //"交易审批"
          toDoTransactionApprovalRiskExecute()
        break;
        case 'loading':
        case 'PreAudit':
        case 'PlatformAudit': //"平台审批"
        case 'LoanAudit': //"资方审批"
          router.push({
            name: "result",
            query: {
              type:1
            }
          })
          break;
        case 'TransactionAuditFail':
        case 'PlatformAuditFail':
        case 'LoanAuditFail':
          router.push({
            name: "result",
            query: {
              type:2
            }
          })
          break;

        case 'SignContract': //"签署合同"
          alert('签署合同流程接口未出')
          break;

        case 'DoReceipt': // "执行放款"

          break;

        default:
            break;
      }
    } else {
      console.log("findNextFlowThenJump failed");
    }
  }).catch(err=>{
    console.log('接口报错或超时了，需要重新进入流程',err)
    setTimeout(()=>{
      findNextFlowThenJump1()
    },5000)
  });
}

function toGetAuthMerchantStatus() {
  getAuthMerchantStatus({
    token: "",
  }).then((res) => {
    if (res.success && res.data) {
      const { status, authUrl } = res.data;
      if (status == "0") {
        window.location.href = authUrl;
      } else {
        findNextFlowThenJump()
      }
    }
  });
}

function toGetAuthUserStatus() {
  getPersonAutoSignAuthResult({
    type: staticConfig.person_params, // xafq_h5
    token:''
  }).then((res) => {
    if (res.success && res.data) {
      const { status,authUrl } = res.data;
      if (status == "0") {
        window.location.href = authUrl;
      } else {
       findNextFlowThenJump1()
      }
    }
  });
}
function toGetPersonVerifyUrl() {
  getPersonVerifyUrl({
    type: staticConfig.person_params, // xafq_h5
    token:''
  }).then((res) => {
    if (res.success && res.data) {
      const { verifyUrl } = res.data;
      window.location.href = verifyUrl;
      // router.push({
      //   name: "testFace",
      //   query: {
      //     url:encryptText(verifyUrl)
      //   },
      // });
    }
  });
}

function toShopInfo(){
  
  router.push({
    name: "storeInfo",
    query: {
     
    },
  });
}

function toCompanyInformation(){
  
  router.push({
    name: "companyInformation",
    query: {
     
    },
  });
}

function toEnterpriseInfo(){
  router.push({
        name: "supplementaryInformation",
        query: {
         
        },
      });
}


function toEnterpriseSupplementInfo(){
  router.push({
        name: "enterpriseSupplementInfo",
        query: {
         
        },
      });
}




function toRelationInfo(){
  router.push({
        name: "relationInfo",
        query: {
         
        },
      });
}

function toGetFddUrl() {
  const userId = window.localStorage.getItem("userId");
  const timestamp = parseTime(getServerTime(), "{y}-{m}-{d} {h}:{i}:{s}");
  const customH5Platform = getCustomH5Platform();
  const where = customH5Platform.where;
  getFddPersonVerifyUrl({
    apiMethodName: "getFddPersonVerifyUrl",
    appId: "44212397006651392",
    signType: "RSA",
    type: staticConfig.fdd_params, // xafq_h5
    userId,
    timestamp,
    terminalType: "2",
  }).then((res) => {
    if (res.success && res.data) {
      const { verifyUrl } = res.data;
      window.location.href = verifyUrl;
      // router.push({
      //   name: "testFace",
      //   query: {
      //     url:encryptText(verifyUrl)
      //   },
      // });
    }
  });
}

//前往企业实名认证
function toDoOrganAuth(){
  doOrganAuth({
    token: "",
  }).then((res) => {
    if (res.success && res.data) {
      const { authStatus,url } = res.data;
      if (authStatus != "4") {
        window.location.href = url;
      }else{
        findNextFlowThenJump()
      }
    }
  });
}

function toDoMerchantAdmissionRiskExecute() {
  doMerchantAdmissionRiskExecute({token:''}).then(res=>{
    findNextFlowThenJump();
  })
}

function toDoTransactionApprovalRiskExecute() {
  doTransactionApprovalRiskExecute({token:''}).then(res=>{
    findNextFlowThenJump1();
  })
}
// 风控审核
function toCheckRule(productId, applyId) {
  console.log('进入风控-------------------')
  const userId = window.localStorage.getItem("userId");
  const timestamp = parseTime(getServerTime(), "{y}-{m}-{d} {h}:{i}:{s}");
  checkXsRule({
    apiMethodName: "checkXsRule",
    appId: "44212397006651392",
    signType: "RSA",
    apiVersion: "1.0",
    timestamp,
    terminalType: "2",
    userId,
    productId,
    applyId,
  }).then((res) => {
    if (res.success) {
      console.log('风控回来-------------------')
      router.push({ name: "loading" ,query:{random: Math.floor(Math.random() * (1000))}});

      // router.push({ name: "loading" }); // 进入风控审批等待页 等审批结束再进入流程

      // findNextFlowThenJump();
    } else {
      findNextFlowThenJump();
    }
  });
}

function toExecuteLoanReceipt(applyId) {
  executeLoanReceipt({ applyId, token: "" }).then((res) => {
    if (res.success) {
      findNextFlowThenJump();
    } else {
      //
    }
  });
}

function toGettingResult(applyId) {
  router.push({
    name: "loanGettingResult",
    query: {
      applyId,
    },
  });
}

export function toLoanResult(status,applyId) {
  router.push({
    name: "loanResult",
    query: {
      status,
      applyId
    },
  });
}
