var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.bankInfo
    ? _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "list-container" },
          [
            _vm._l(_vm.bankInfo.receivingAccountList, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "item",
                  on: {
                    click: function($event) {
                      return _vm.toMenu("receivingAccountDetail", item.id)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "title" }, [
                    _c("img", {
                      attrs: {
                        src: _vm.bankImg[item.bankName]
                          ? _vm.bankImg[item.bankName]
                          : _vm.bankImg["默认"]
                      }
                    }),
                    _vm._v("\n        " + _vm._s(item.accountName))
                  ]),
                  _c("p", { staticClass: "num" }, [
                    _vm._v(_vm._s(_vm.formatNumber(item.bankNo)))
                  ]),
                  _c("p", { staticClass: "desc" }, [
                    _vm._v(_vm._s(item.openBranchName))
                  ])
                ]
              )
            }),
            !_vm.bankInfo.receivingAccountList ||
            _vm.bankInfo.receivingAccountList.length == 0
              ? _c("empty")
              : _vm._e()
          ],
          2
        ),
        _c("div", { staticClass: "footer" }, [
          _vm.bankInfo.authStatus == "4" &&
          _vm.bankInfo.receivingAccountList.length == 0
            ? _c(
                "div",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.toMenu("receivingAccount")
                    }
                  }
                },
                [_vm._v("+添加收款账户")]
              )
            : _vm._e(),
          _vm._m(0)
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_vm._v("遇到问题? "), _c("span", [_vm._v("点击联系客服")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }