var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { position: "relative" },
      on: {
        mouseover: function($event) {
          _vm.mode === "pop" ? (_vm.showImage = true) : undefined
        },
        mouseout: function($event) {
          _vm.mode === "pop" ? (_vm.showImage = false) : undefined
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showImage,
              expression: "showImage"
            }
          ],
          staticClass: "verify-img-out",
          style:
            _vm.mode === "pop"
              ? {
                  display: "none",
                  position: "absolute",
                  bottom: "42px",
                  height: parseInt(_vm.setSize.imgHeight) + _vm.vSpace + "px"
                }
              : {
                  position: "relative",
                  height: parseInt(_vm.setSize.imgHeight) + _vm.vSpace + "px"
                },
          on: {
            mouseover: function($event) {
              _vm.mode === "pop" ? (_vm.showImage = true) : undefined
            },
            mouseout: function($event) {
              _vm.mode === "pop" ? (_vm.showImage = false) : undefined
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "verify-img-panel",
              style: {
                width: _vm.setSize.imgWidth,
                height: _vm.setSize.imgHeight,
                "background-size":
                  _vm.setSize.imgWidth + " " + _vm.setSize.imgHeight,
                "margin-bottom": _vm.vSpace + "px"
              }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showRefresh,
                      expression: "showRefresh"
                    }
                  ],
                  staticClass: "verify-refresh",
                  staticStyle: { "z-index": "3" },
                  on: { click: _vm.refresh }
                },
                [_c("i", { staticClass: "iconfont icon-refresh" })]
              ),
              _c("canvas", {
                ref: "canvas",
                attrs: {
                  width: _vm.setSize.imgWidth,
                  height: _vm.setSize.imgHeight
                },
                on: {
                  click: function($event) {
                    _vm.bindingClick ? _vm.canvasClick($event) : undefined
                  }
                }
              }),
              _vm._l(_vm.tempPoints, function(tempPoint, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "point-area",
                    style: {
                      "background-color": "#1abd6c",
                      color: "#fff",
                      "z-index": 9999,
                      width: "30px",
                      height: "30px",
                      "text-align": "center",
                      "line-height": "30px",
                      "border-radius": "50%",
                      position: "absolute",
                      top: parseInt(tempPoint.y - 10) + "px",
                      left: parseInt(tempPoint.x - 10) + "px"
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(index + 1) +
                        "\n            "
                    )
                  ]
                )
              })
            ],
            2
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "verify-bar-area",
          style: {
            width: this.barSize.width,
            height: this.barSize.height,
            color: this.barAreaColor,
            "border-color": this.barAreaBorderColor,
            "line-height": this.barSize.height
          }
        },
        [_c("span", { staticClass: "verify-msg" }, [_vm._v(_vm._s(_vm.text))])]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }