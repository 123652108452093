<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-12-23 10:18:28
 * @LastEditors: CZK
 * @LastEditTime: 2024-12-23 10:20:43
-->
<template>
  <div class="container">
    <div class="row-content" v-if="!loading">
      <div class="row" >
        <!-- toMenu('settlementAccount') -->
        <div class="row-item border-bottom-1px" @click="toAccount(info.settleAcctStatus,'settlementAccount')">
          <div class="row-item-title">
            <span class="money">结算账户</span>
            <span class="status status02">
              {{['未开通','去开通','审核中','已开通'][info.settleAcctStatus]}}
              <i></i>
            </span>
          </div>
          <!-- <p class="row-item-date">{{jtem.applyTime}}</p> -->
        </div>
        <div class="row-item border-bottom-1px" @click="toAccount(info.balanceAcctStatus,'balanceAccounts')">
          <div class="row-item-title">
            <span class="money">余额账户</span>
            <span class="status status02">
              {{['未开通','去开通','审核中','已开通'][info.balanceAcctStatus]}}
              <i></i>
            </span>
          </div>
          <!-- <p class="row-item-date">{{jtem.applyTime}}</p> -->
        </div>
        <div class="row-item border-bottom-1px" @click="toIncome">
          <div class="row-item-title">
            <span class="money">收款账户</span>
            <span class="status status02">
              {{['未添加','去添加',info.incomeAcctNum][info.incomeStatus]}}
              <i></i>
            </span>
          </div>
          <!-- <p class="row-item-date">{{jtem.applyTime}}</p> -->
        </div>
      </div>

    </div>
    <c-spin v-else style="margin-top:40vh;text-align: center;" tip="Loading..." size="large"></c-spin>
    
  </div>
</template>
<script>
  import {findNextFlowThenJump,findNextFlowThenJump1} from "./findNextFlow"
  import { getManagementAccountInfo } from '@/api/user'
  import moment from "moment";
  export default {
    name: "",
    components:{},
    data() {
      return {
        info:{},
        list:null,
        loading:true
      }
    },
    computed: {
      
    },
    methods:{
      toAccount(status,name){
        if(status==3)  this.toMenu(name)
        else if(status==1) {
          findNextFlowThenJump1()
        }
      },
      toIncome(){
        if(this.info.incomeStatus==1){
          this. toMenu('receivingAccount')
        }

        if(this.info.incomeStatus==2){
          this. toMenu('receivingAccountList')
        }
       
      },
      toMenu(name){
        this.$router.push({name})
      },
      async getInfo(){
        let res = await getManagementAccountInfo({token:''})
        if(res.success){
          this.info = res.data
          this.loading = false
        }
      }
    },
    mounted(){
      this.getInfo()
      
    }
  }
</script>
<style lang="less" scoped>
  .container {
    width:100%;
    background:#F5F7FB url("../../assets/images/img/apply_bg.png") no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    .row-content{
      width:343px;
      margin:22px auto 0;
      .row{
        width: 343px;
        background: #FFFFFF;
        border-radius: 8px 8px 8px 8px;
        padding: 0 11px;
        margin-bottom:16px;
        .row-time{
          width:100%;
          height: 40px;
          line-height: 40px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #1C2023;
        }
        .row-item{
          padding:12px 6px;
          display:flex;
          flex-direction: column;
          justify-content: space-between;
          .row-item-title{
            display:flex;
            align-items:center;
            justify-content: space-between;
            .money{
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #333333;
            }
            .status{
              width: 74px;
              height: 26px;
              border-radius: 5px 5px 5px 5px;
              display: flex;
              font-size:12px;
              justify-content: flex-end;
              align-items: center;
              i{
                display:block;
                width:24px;
                height:24px;
              }
              &.status01{
                background: #FFEDE1;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #FF6A00;
                i{
                  background: url("../../assets/images/img/right01.png") no-repeat;
                  background-size: contain;
                }
              }

              &.status02{
                background: #EAEDFE;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 12px;
                color: #304FFE;
                i{
                  background: url("../../assets/images/img/right02.png") no-repeat;
                  background-size: contain;
                }
              }

              &.status03{
                background: #E8F6FC;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 12px;
                color: #007BFF;
                i{
                  background: url("../../assets/images/img/right03.png") no-repeat;
                  background-size: contain;
                }
              }

              &.status04{
                background: #E5F9ED;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #1BA555;
                i{
                  background: url("../../assets/images/img/right04.png") no-repeat;
                  background-size: contain;
                }
              }

              &.status05{
                background: #FFEFEF;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #FF0000;
                i{
                  background: url("../../assets/images/img/right05.png") no-repeat;
                  background-size: contain;
                }
              }

              &.status06,&.status07,&.status08{
                background: #F6EFFF;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #8A38F5;
                i{
                  background: url("../../assets/images/img/right06.png") no-repeat;
                  background-size: contain;
                }
              }
              
            }
          }
          .row-item-date{
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            margin-top: 7px;
            line-height: 18px;
          }
        }
      }
    }
  }
</style>