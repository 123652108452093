import { axios } from "@/utils/requestRepayment";
import { axioss, getServerTime } from "@/utils/requestXafqApp";
import { axiosss } from "@/utils/requestXafqFreshToken";
import { getFddPersonVerifyUrl } from "@/api/carFinance";
import { checkXsRule } from "@/api/risk";
import { parseTime } from "@/utils/util";
import {fontAxios} from "@/utils/formData";

/**
 * 短信登录
 * @param data String mobile,tattedId,tattedCode
 * @returns {*}
 */
export function msgLogin(data = {}) {
  return axios({
    url: "/msgLogin.do",
    method: "post",
    data
  });
}

/**
 * 签约支付验证 登录 
 * @param data String mobile,tattedId,tattedCode
 * @returns {*}
 */
export function certLogin(data = {}) {
  return axios({
    url: "/Login.do",
    method: "post",
    data
  });
}

/**
 * 获取用户信息
 * @param {*} query 
 */
export function getUser(query) {
  return axios({
    url: "/repayment/getUser.do",
    method: "get",
    params: query
  });
}

/**
 * 退出登录
 * @param {*} data 
 */
export function logout(query) {
  return axios({
    url: "/delCookei.do",
    method: "get",
    params: query
  });
}

/**
 * 发送登录短信验证码
 * @param {*} data mobile 手机号
 */
export function verificationCode(data = {}) {
  return axios({
    url: "/verificationCode.do",
    method: "post",
    data
  });
}



/**
 * 发送承诺书短信验证码
 * @param {*} data mobile 手机号
 */
 export function sendPledgeConfirmSms(data = {}) {
  return axioss({
    url: "/userInfo/sendPledgeConfirmSms",
    method: "post",
    data
  });
}

/**
 * 确认承诺书
 * @param {*} data mobile 手机号
 */
 export function commitmentConfirmation(data = {}) {
  return axioss({
    url: "/userAuthorize/commitmentConfirmation",
    method: "post",
    data
  });
}


/**
 * 微信授权登录
 * @param {*} data mobile 手机号 openid
 */
 export function getVerificationCode(params = {}) {
  return axioss({
    url: "/app/login/verificationCode",
    method: "get",
    params
  });
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * 小爱分期H5 Api
 * data: 20201009
 * author: chenyh
 */

/**
 * 刷新token(同步接口)
 *
 * @param data    String freshToken, token, sendTime
 * @returns {*}
 */
export function freshToken(data = {}) {
  return axiosss({
    url: "/app/login/freshToken",
    method: "post",
    data
  });
}

/**
 * 发送短信
 *
 * @param data    String mobile, String type, String sendTime
 * @returns {*}
 */
export function sendSms(data = {}) {
  return axioss({
    url: "/app/outer/sendMes",
    method: "post",
    data
  });
}



/**
 * 注册并登录
 *
 * @param data    String
 * @returns {*}
 */
export function regAndLogin(data = {}) {
  return axioss({
    url: "/app/login/regAndLogin",
    method: "post",
    data
  });
}

/**
 * 获取用户openId
 * @param {*} query  manageId
 */

export function getH5OpenId(query){
  return axioss({
    url: "/app/login/getOpenid",
    method: "get",
    params: query,
  });
}


/**
 * 获取图片验证码base64接口
 * 
 */

 export function getCaptcha(query){
  return axioss({
    url: "/app/login/getCaptcha",
    method: "get",
    params: query,
  });
}

/**
 * 根据商户邀请码查询商户名称
 * @param {*} query  inviteCode
 */
export function queryLeagueInfo(query){
  return axioss({
    url: "/appLoanApply/queryLeagueInfo",
    method: "get",
    params: query,
  });
}

/**
 * 查询常见问题
 *
 * @param data    String page, rows, questionType, sendTime
 * @returns {*}
 */
export function querySysQuestions(query) {
  return axioss({
    url: "/announce/querySysQuestions",
    method: "get",
    params: query
  });
}

/**
 * 查询常见问题详情
 *
 * @param {*} query
 * @returns {*}
 */
export function querySysQuestionDetail(query) {
  return axioss({
    url: "/announce/querySysQuestionDetail",
    method: "get",
    params: query
  });
}

/**
 * 获取公告
 *
 * @param {*} query 
 * @returns {*}
 */
export function queryAnnounce(query) {
  return axioss({
    url: "/announce/queryAnnounce",
    method: "get",
    params: query
  });
}

/**
 * 获取公告详情
 *
 * @param {*} query 
 * @returns {*}
 */
export function queryAnnounceDetail(query) {
  return axioss({
    url: "/announce/queryAnnounceDetail",
    method: "get",
    params: query
  });
}

/**
 * 获取个人消息
 *
 * @param {*} query 
 * @returns {*}
 */
export function queryPersonalMessage(query) {
  return axioss({
    url: "/announce/queryPersonalMessage",
    method: "get",
    params: query
  });
}

/**
 * 获取个人消息详情
 *
 * @param {*} query 
 * @returns {*}
 */
export function queryPersonalMessageDetail(query) {
  return axioss({
    url: "/announce/queryPersonalMessageDetail",
    method: "get",
    params: query
  });
}

/**
 * 我的客服
 *
 * @param {*} query 
 * @returns {*}
 */
export function queryAppServiceLog(query) {
  return axioss({
    url: "/appService/queryAppServiceLog",
    method: "get",
    params: query
  });
}

/**
 * 获取我的银行卡列表
 *
 * @param {*} query 
 * @returns {*}
 */
export function queryBankcardList(query) {
  return axioss({
    url: "/bankcard/queryBankcardList",
    method: "get",
    params: query
  });
}

/**
 * 获取用户实名状态
 *
 * @param {*} query 
 * @returns {*}
 */
 export function queryUserAuthInfo(query) {
  return axioss({
    url: "/userAuthorize/queryUserAuthInfo",
    method: "get",
    params: query
  });
}

/**
 * 设置为默认卡
 *
 * @param {*} data
 * @returns {*}
 */
 export function setMainBankcard(data = {}) {
  return axioss({
    url: "/bankcard/setMainBankcard",
    method: "post",
    data
  });
}

/**
 * 判断是否可以解绑银行卡
 *
 * @param {*} query
 * @returns {*}
 */
 export function getOnTheWayLoan(query) {
  return axioss({
    url: "/bankcard/getOnTheWayLoan",
    method: "get",
    params: query
  });
}

/**
 * 解绑默认卡
 *
 * @param {*} data
 * @returns {*}
 */
 export function unbindBankCard(data = {}) {
  return axioss({
    url: "/bankcard/unbundlingBankcard",
    method: "post",
    data
  });
}

/**
 * 查询申请中订单
 *
 * @param {*} query 
 * @returns {*}
 */
export function queryLoaning(query) {
  return axioss({
    url: "/appLoanApply/queryLoaning",
    method: "get",
    params: query
  });
}

/**
 * 取消订单
 *
 * @param data 
 * @returns {*}
 */
export function cancelLoan(data = {}) {
  return axioss({
    url: "/appLoanApply/cancelLoan",
    method: "post",
    data
  });
}

/**
 * 根据商户邀请码查询商品信息
 *
 * @param {*} query 
 * @returns {*}
 */
export function queryInfoByOpera(query) {
  return axioss({
    url: "/appLoanApply/queryInfoByOpera",
    method: "get",
    params: query
  });
}

/**
 * 还款试算
 *
 * @param {*} query 
 * @returns {*}
 */
export function repayCount(query) {
  return axioss({
    url: "/repaymentPlan/repayCount",
    method: "get",
    params: query
  });
}

/**
 * 保存订单
 *
 * @param data 
 * @returns {*}
 */
export function saveLoan(data = {}) {
  return axioss({
    url: "/appLoanApply/saveLoan",
    method: "post",
    data
  });
}

/**
 * 获取下一步流程
 *
 * @param {*} query 
 * @returns {*}
 */
export function findNextFlow(query) {
  return axioss({
    url: "/app/assetLeagueStep/findLeagueNextFlow",
    method: "get",
    params: query
  });
}

/**
 * 获取请款流程下一步流程
 *
 * @param {*} query 
 * @returns {*}
 */
export function findProductNextFlow(data) {
  return axioss({
    url: "/app/preloan/findProductNextFlow",
    method: "post",
    data
  });
}


/**
 * 获取app协议列表
 *
 * @param {*} query 
 * @returns {*}
 */
export function queryProtocolsList(query) {
  return axioss({
    url: "/index/queryProtocolsList",
    method: "get",
    params: query
  });
}

/**
 * 获取个人中心数据
 *
 * @param {*} query 
 * @returns {*}
 */
export function queryMyCenterData(query) {
  return axioss({
    url: "/myCenter/queryMyCenterData",
    method: "get",
    params: query,
    headers: {
      "NeedAuthorization":true
    }
  });
}

/**
 * 查询分期记录列表
 *
 * @param {*} query 
 * @returns {*}
 */
export function queryApplyList(query) {
  return axioss({
    url: "/preloanApply/queryApplyList",
    method: "get",
    params: query
  });
}

/**
 * 查询分期记录详情
 *
 * @param {*} query 
 * @returns {*}
 */
export function queryApplyDetail(query) {
  return axioss({
    url: "/preloanApply/queryApplyDetail",
    method: "get",
    params: query
  });
}

/**
 * 查询分期记录详情
 *
 * @param {*} query 
 * @returns {*}
 */
export function getSelectOption(query) {
  return axioss({
    url: "/appSystem/getSelectOption",
    method: "get",
    params: query
  });
}

/**
 * 获取当前登录用户
 *
 * @param data    请求体
 * @returns {*}
 */
export function getCurrentUser(data = {}) {
  return axioss({
    url: "/app/login/getCurrentUserr",
    method: "post",
    data
  });
}

/**
 * 发送协议支付短信
 *
 * @param data    请求体
 * @returns {*}
 */
export function sendPayMsgCode(data = {}) {
  return axioss({
    url: "/pay/sendPayMsgCode",
    method: "post",
    data
  });
}

/**
 * 支付鉴权确认(确认开通支付协议)
 *
 * @param data    请求体
 * @returns {*}
 */
export function agreementSign(data = {}) {
  return axioss({
    url: "/pay/agreementSign",
    method: "post",
    data
  });
}

/**
 * 补充个人资料
 *
 * @param data    请求体
 * @returns {*}
 */
export function replenishInfo(data = {}) {
  return axioss({
    url: "/userAuthorize/replenishInfo",
    method: "post",
    data
  });
}

/**
 * 补充用户联系人
 *
 * @param data    请求体
 * @returns {*}
 */
export function contactPerson(data = {}) {
  return axioss({
    url: "/userAuthorize/contactPerson",
    method: "post",
    data
  });
}

/**
 * 保存现场资料
 *
 * @param data    请求体
 * @returns {*}
 */
export function saveSceneInfoP(data = {}) {
  return axioss({
    url: "/userAuthorize/saveSceneInfo",
    method: "post",
    data
  });
}

/**
 * 放款（触发合同生成、生成借据、生成还款计划）
 *
 * @param data    请求体
 * @returns {*}
 */
export function executeLoanReceipt(data = {}) {
  return axioss({
    url: "/pay/executeLoanReceipt",
    method: "post",
    data
  });
}

/**
 * 查询法大大活体认证状态
 *
 * @param {*} query 
 * @returns {*}
 */
export function obtainFaceRealStatus(query) {
  return axioss({
    url: "/idCheck/obtainFaceRealStatus",
    method: "get",
    params: query,
    headers: {
      "NeedAuthorization":false
    }
  });
}

/**
 * 查询法大大授权状态
 *
 * @param {*} query 
 * @returns {*}
 */
 export function obtainAuthSignStatus(query) {
  return axioss({
    url: "/idCheck/obtainAuthSignStatus",
    method: "get",
    params: query,
    headers: {
      "NeedAuthorization":false
    }
  });
}

/**
 * 我的账单
 *
 * @param {*} query 
 * @returns {*}
 */
export function queryUserBillList(query) {
  return axioss({
    url: "/myBill/userBill",
    method: "get",
    params: query
  });
}

/**
 * 我的账单详情
 *
 * @param {*} query 
 * @returns {*}
 */
export function queryUserBillDetail(query) {
  return axioss({
    url: "/myBill/userBillDetail",
    method: "get",
    params: query
  });
}

/**
 * 息费减免展示
 *
 * @param {*} query 
 * @returns {*}
 */
export function queryFeeDiffInfo(query) {
  return axioss({
    url: "/myBill/feeDiffInfo",
    method: "get",
    params: query,
    headers: {
      "NeedAuthorization":false
    }
  });
}

/**
 * 还款详情
 *
 * @param {*} query 
 * @returns {*}
 */
export function queryRepaymentInfo(query) {
  return axioss({
    url: "/myBill/repaymentInfo",
    method: "get",
    params: query
  });
}

/**
 * 确认付款
 *
 * @param {*} query 
 * @returns {*}
 */
export function confirmRepay(query) {
  return axioss({
    url: "/pay/confirmRepay",
    method: "get",
    params: query
  });
}

/**
 * 用户银行转账支付
 *
 * @param {*} data 
 * @returns {*}
 */
 export function stdTradeAction(data = {}) {
  return axioss({
    url: "/repay/stdTradeAction",
    method: "post",
    data
  });
}

/**
 * 聚合支付
 *
 * @param {*} data 
 * @returns {*}
 */
 export function aggpayLinkAction(data = {}) {
  return axioss({
    url: "/inner/call/aggpayLinkAction",
    method: "post",
    data
  });
}

/**
 * 微信支付
 *
 * @param {*} data 
 * @returns {*}
 */
 export function aggpayAction(data = {}) {
  return axioss({
    url: "/repay/aggpayAction",
    method: "post",
    data
  });
}







/**
 * 发送还款验证码
 *
 * @param {*} query 
 * @returns {*}
 */
export function repaySmsSend(query) {
  return axioss({
    url: "/pay/repaySmsSend",
    method: "get",
    params: query
  });
}

/**
 * 还款验证码确认
 *
 * @param data 
 * @returns {*}
 */
export function repaySmsCheck(data = {}) {
  return axioss({
    url: "/pay/repaySmsCheck",
    method: "post",
    data
  });
}

/**
 * 用户主动还款
 *
 * @param data 
 * @returns {*}
 */
export function repayAction(data = {}) {
  return axioss({
    url: "/repay/repayAction",
    method: "post",
    data
  });
}

/**
 * 主动查询还款结果
 *
 * @param {*} query 
 * @returns {*}
 */
export function getRepayResult(query) {
  return axioss({
    url: "/inner/call/getRepayResult",
    method: "get",
    params: query,
    headers: {
      "NeedAuthorization":false
    }
  });
}

/**
 * 发送修改手机验证码
 *
 * @param data 
 * @returns {*}
 */
export function sendChangeMobileSms(data = {}) {
  return axioss({
    url: "/userInfo/sendChangeMobileSms",
    method: "post",
    data
  });
}

/**
 * 确认修改手机验证码
 *
 * @param data 
 * @returns {*}
 */
export function checkChangeMobileSms(data = {}) {
  return axioss({
    url: "/userInfo/sendChangeMobileSmsCheck",
    method: "post",
    data
  });
}

/**
 * 修改手机发送协议支付短信
 *
 * @param data 
 * @returns {*}
 */
export function changeMobilePayCode(data = {}) {
  return axioss({
    url: "/userInfo/changeMobilePayCode",
    method: "post",
    data
  });
}

/**
 * 修改手机确认协议支付
 *
 * @param data 
 * @returns {*}
 */
export function changeMobileAgreementSign(data = {}) {
  return axioss({
    url: "/userInfo/changeMobileAgreementSign",
    method: "post",
    data
  });
}

/**
 * 获取订单现场资料
 *
 * @param {*} query 
 * @returns {*}
 */
export function getSceneInfo(query) {
  return axioss({
    url: "/userAuthorize/getSceneInfo",
    method: "get",
    params: query
  });
}

/**
 * 获取补充个人资料
 *
 * @param {*} query 
 * @returns {*}
 */
export function getReplenishInfo(query) {
  return axioss({
    url: "/userAuthorize/getReplenishInfo",
    method: "get",
    params: query
  });
}

/**
 * 获取联系人
 *
 * @param {*} query 
 * @returns {*}
 */
export function getContactPerson(query) {
  return axioss({
    url: "/userAuthorize/getContactPerson",
    method: "get",
    params: query
  });
}

/**
 * 获取上传凭证
 *
 * @param {*} query 
 * @returns {*}
 */
export function getUploadProof(query) {
  return axioss({
    url: "/oss/getUploadProof",
    method: "get",
    params: query
  });
}

////////////////////////////////////////////////////xs////////////////////////////////////////////////////

/**
 * 获取服务器时间戳
 *
 * @param {*} query 
 * @returns {*}
 */
export function getNewTimeTimeMillis(query) {
  return axioss({
    url: "/xs/call/getNewTimeTimeMillis",
    method: "get",
    params: query
  });
}

/**
 * 修改手机确认协议支付
 *
 * @param data 
 * @returns {*}
 */
export function applyStage(data = {}) {
  return axioss({
    url: "/xs/call/applyStage",
    method: "post",
    data
  });
}

/**
 * 获取首页消息(专属)
 * @param {*} query  leagueId
 */

 export function queryH5IndexMessages(query) {
  return axioss({
    url: "/league/getH5IndexMessages",
    method: "get",
    params: query,
  });
}

/**
 * 获取下级门店列表(专属)
 * @param {*} query  leagueId
 */

 export function queryNextLeagueList(query) {
  return axioss({
    url: "/league/findNextLeagueList",
    method: "get",
    params: query,
  });
}

/**
 * 根据token获取登录状态(专属)
 * @param {*} query  token
 */

  export function confirmLoginStatus(query) {
    return axioss({
      url: "/confirmLoginStatus",
      method: "get",
      params: query,
    });
  }

  /**
 * 根据token获取登录状态(专属)
 * @param {*} query  token
 */

  export function queryApplyResultInfo(query) {
    return axioss({
      url: "/preloanApply/queryApplyResultInfo",
      method: "get",
      params: query,
      headers: {
        "NeedAuthorization":true
      }
    });
  }
  
/**
 * 查询附产品
 * @param {*} query  token
 */

  export function findAttachProducts(query) {
  return axioss({
    url: "/appLoanApply/findAttachProducts",
    method: "get",
    params: query,
  });
}
  
/**
 * 判断用户是否能编辑资料
 * @param {*} query  token
 */

 export function checkEditInfo(query) {
  return axioss({
    url: "/userInfo/checkEditInfo",
    method: "get",
    params: query,
  });
}

/**
 * 获取补充信息配置
 * @param {*} query  token
 */

 export function getReplenishInfoRule(query) {
  return axioss({
    url: "/appLoanStep/getReplenishInfoRule",
    method: "get",
    params: query,
  });
}

/**
 * 获获取用户现场资料
 * @param {*} query  token
 */

export function getUserSceneInfo(query) {
  return axioss({
    url: "/userAuthorize/getUserSceneInfo",
    method: "get",
    params: query,
  });
}

/**
 * 获获取资金路由状态
 * @param {*} query  token
 */

export function getRouteStatus(query) {
  return axioss({
    url: "/appLoanStep/getRouteStatus",
    method: "get",
    params: query,
  });
}

/**
 * 旷视意愿同步返回
 * @param {*} query  token
 */

export function faceIdReturnUrl(data = {}) {
  return axioss({
    url: "/external/call/faceidReturnUrl",
    method: "post",
    data
  });
}

/**
 * 获取视频认证地址
 * @param {*} query  token
 */

export function getVideoAuthenticationAddress(query) {
  return axioss({
    url: "/preloanApply/getVideoAuthenticationAddress",
    method: "get",
    params: query,
  });
}


/**
 * 跳过绑卡流程
 * @param {*} query  token
 */

export function jumpBankCard(query) {
  return axioss({
    url: "/appLoanStep/jumpBankCard",
    method: "get",
    params: query,
  });
}

/**
 * app移动端进行企业实名认证
 * @param {*} query  token
 */

export function doOrganAuth(query) {
  return axioss({
    url: "/app/assetLeagueStep/doOrganAuth",
    method: "get",
    params: query,
  });
}


/**
 * APP首页信息
 * @param {*} query  token
 */

export function getIndexInfo(query) {
  return axioss({
    url: "/app/assetLeagueStep/getIndexInfo",
    method: "get",
    params: query,
  });
}


/**
 * 身份证识别
 * @param {*} query  token
 */

export function uploadIdCard(data) {
  return axioss({
    url: "/app/admitOrder/uploadIdCard",
    method: "post",
    data,
  });
}



/**
 * 保存企业补充信息
 * @param {*} query  token
 */

export function saveIdCardInfo(data) {
  return axioss({
    url: "/app/admitOrder/saveIdCardInfo",
    method: "post",
    data,
  });
}


/**
 * 保存店铺信息
 * @param {*} query  token
 */

export function saveShopInfo(data) {
  return axioss({
    url: "/app/admitOrder/saveShopInfo",
    method: "post",
    data,
  });
}


/**
 * 获取枚举
 * @param {*} query  token
 */

export function getSelectOption1(params) {
  return axioss({
    url: "/common/getSelectOption",
    method: "get",
    params,
  });
}



/**
 * 保存补充资料
 * @param {*} query  token
 */

export function saveSupplyInfo(data) {
  return axioss({
    url: "/app/admitOrder/saveSupplyInfo",
    method: "post",
    data,
  });
}


/**
 * 提交请款申请
 * @param {*} query  token
 */

export function apply(data) {
  return axioss({
    url: "/app/preloan/apply",
    method: "post",
    data,
  });
}




/**
 * 绑定店铺账号
 * @param {*} query  token
 */

export function bindShopAccount(data) {
  return axioss({
    url: "/app/preloan/bindShopAccount",
    method: "post",
    data,
  });
}


/**
 * 查询商户授权自动签状态结果
 * @param {*} query  token
 */

export function getAuthMerchantStatus(data) {
  return axioss({
    url: "/app/preloan/getAuthMerchantStatus",
    method: "post",
    data,
  });
}



/**
 * 机构新增收款账户
 * @param {*} query  token
 */

export function addIncomeAccount(data) {
  return axioss({
    url: "/app/preloan/addIncomeAccount",
    method: "post",
    data,
  });
}



/**
 * 查询机构收款账户列表
 * @param {*} query  token
 */

export function getLeagueIncomeAccountList(data) {
  return axioss({
    url: "/app/preloan/getLeagueIncomeAccountList",
    method: "post",
    data,
  });
}



/**
 * 查询是否有订单未完成
 * @param {*} query  token
 */

export function getOperatorNotOverApply(params) {
  return axioss({
    url: "/app/preloan/getOperatorNotOverApply",
    method: "get",
    params,
  });
}



/**
 * 查询个人授权自动签状态结果
 * @param {*} query  token
 */

export function getPersonAutoSignAuthResult(data) {
  return axioss({
    url: "/app/preloan/getPersonAutoSignAuthResult",
    method: "post",
    data,
  });
}



/**
 * 获取个人实名地址
 * @param {*} query  token
 */

export function getPersonVerifyUrl(data) {
  return axioss({
    url: "/app/preloan/getPersonVerifyUrl",
    method: "post",
    data,
  });
}



/**
 * 更新店铺数据
 * @param {*} query  token
 */

export function updateShopInfo(data) {
  return axioss({
    url: "/app/preloan/updateShopInfo",
    method: "post",
    data,
  });
}


/**
 * 机构新增开户行下拉列表
 * @param {*} query  token
 */

export function getBankList(params) {
  return axioss({
    url: "/app/preloan/getBankList",
    method: "get",
    params,
  });
}


/**
 * 查询法大大企业实名认证信息
 * @param {*} query  token
 */

export function getEnterpriseAuthInfo(data) {
  return axioss({
    url: "/app/admitOrder/getEnterpriseAuthInfo",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}


/**
 * 查询所属支行列表
 * @param {*} query  token
 */

export function getOpenBranchList(params) {
  return axioss({
    url: "/app/preloan/getOpenBranchList",
    method: "get",
    params,
  });
}


/**
 * 新增收款账户城市下拉列表
 * @param {*} query  token
 */

export function getCityList(params) {
  return axioss({
    url: "/app/preloan/getCityList",
    method: "get",
    params,
  });
}


/**
 * 查询请款利率信息
 * @param {*} query  token
 */

export function getLoanFeeRuleTo(data) {
  return axioss({
    url: "/app/preloan/getLoanFeeRuleTo",
    method: "post",
    data,
  });
}


/**
 *  查询最新可用手机号
 * @param {*} query  token
 */

export function getLatestAvailablePhone(data) {
  return axioss({
    url: "/app/preloan/getLatestAvailablePhone",
    method: "post",
    data,
  });
}


/**
 *  商户准入审核风控执行
 * @param {*} query  token
 */

export function doMerchantAdmissionRiskExecute(data) {
  return axioss({
    url: "/app/assetLeagueStep/doMerchantAdmissionRiskExecute",
    method: "post",
    data,
  });
}


/**
 *  请款准入审核风控执行
 * @param {*} query  token
 */

export function doTransactionApprovalRiskExecute(data) {
  return axioss({
    url: "/app/preloan/doTransactionApprovalRiskExecute",
    method: "post",
    data,
  });
}


/**
 *  查询监管账户信息
 * @param {*} query  token
 */

export function getSettlementAccount(data) {
  return axioss({
    url: "/app/preloan/getSettlementAccount",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}


/**
 * 更改结算账户
 * @param {*} query  token
 */

export function doUpdateSettlementAccount(data) {
  return axioss({
    url: "/app/preloan/doUpdateSettlementAccount",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}


/**
 * 查询请款记录列表
 * @param {*} query  token
 */

export function getLoanApplyList(data) {
  return axioss({
    url: "/app/home/getLoanApplyList",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}


/**
 * 查询请款记录详情
 * @param {*} query  token
 */

export function getLoanApplyDetail(data) {
  return axioss({
    url: "/app/home/getLoanApplyDetail",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}

/**
 * 取消申请
 * @param {*} query  token
 */

export function cancelLoanApply(data) {
  return axioss({
    url: "/app/home/cancelLoanApply",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}

/**
 * 查询收款账户列表
 * @param {*} query  token
 */

export function getMyReceivingAccount(data) {
  return axioss({
    url: "/app/home/getMyReceivingAccount",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}

/**
 * 查询收款账户收款信息
 * @param {*} query  token
 */

export function getReceivingAccountDetail(data) {
  return axioss({
    url: "/app/home/getReceivingAccountDetail",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}


/**
 * 查询收款账户收款记录交易信息
 * @param {*} query  token
 */

export function getReceivingTransactionDetail(data) {
  return axioss({
    url: "/app/home/getReceivingTransactionDetail",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}

/**
 * 查询我的账单信息
 * @param {*} query  token
 */

export function getMyBill(data) {
  return axioss({
    url: "/app/home/getMyBill",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}

/**
 * 查询账单详情
 * @param {*} query  token
 */

export function getBillDetail(data) {
  return axioss({
    url: "/app/home/getBillDetail",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}

/**
 * 查询还款详情
 * @param {*} query  token
 */

export function getRepaymentDetail(data) {
  return axioss({
    url: "/app/home/getRepaymentDetail",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}


/**
 * 保存关联信息
 * @param {*} query  token
 */

export function saveRelationInfo(data) {
  return axioss({
    url: "/app/admitOrder/saveRelationInfo",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}


/**
 * 保存企业补充信息
 * @param {*} query  token
 */

export function saveEnterpriseSupplementInfo(data) {
  return axioss({
    url: "/app/preloan/saveEnterpriseSupplementInfo",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}


/**
 * 企业资质文字识别
 * @param {*} query  token
 */

export function recognizeBusinessLicense(data) {
  return axioss({
    url: "/app/admitOrder/recognizeBusinessLicense",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}


/**
 * 保存企业信息
 * @param {*} query  token
 */

export function saveEnterpriseInfo(data) {
  return axioss({
    url: "/app/admitOrder/saveEnterpriseInfo",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}


/**
 * 查询企业人员信息
 * @param {*} query  token
 */

export function getEnterprisePersonInfo(data) {
  return axioss({
    url: "/app/preloan/getEnterprisePersonInfo",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}

/**
 * 修改手机号码
 * @param {*} query  token
 */

export function updateUserMobile(data) {
  return axioss({
    url: "/app/home/updateUserMobile",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}

/**
 * 查询企业开户收款账户信息
 * @param {*} query  token
 */

export function getCompanyIncomingIncomeAccount(data) {
  return axioss({
    url: "/app/preloan/getCompanyIncomingIncomeAccount",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}


/**
 * 保存企业开户对公银行信息
 * @param {*} query  token
 */

export function saveCorporateBankInfo(data) {
  return axioss({
    url: "/app/preloan/saveCorporateBankInfo",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}

/**
 * 企业开户打款金额确认
 * @param {*} query  token
 */

export function verifyConfirmationOfRemittanceAmount(data) {
  return axioss({
    url: "/app/preloan/verifyConfirmationOfRemittanceAmount",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}

/**
 * 企业开户法人确认
 * @param {*} query  token
 */

export function verifyLegalConfirmation(data) {
  return axioss({
    url: "/app/preloan/verifyLegalConfirmation",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}


/**
 * 企业开户经办人确认
 * @param {*} query  token
 */

export function verifyOperatorConfirmation(data) {
  return axioss({
    url: "/app/preloan/verifyOperatorConfirmation",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}


/**
 * 企业开户校验发送验证码
 * @param {*} query  token
 */

export function sendVerifySMS(data) {
  return axioss({
    url: "/app/preloan/sendVerifySMS",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}


/**
 * 查询企业开户企业人员信息
 * @param {*} query  token
 */

export function getEBankEnterprisePersonInfo(data) {
  return axioss({
    url: "/app/preloan/getEBankEnterprisePersonInfo",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}



/**
 * 确认请款
 * @param {*} query  token
 */

export function confirmApply(data) {
  return axioss({
    url: "/app/preloan/confirmApply",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}


/**
 * 查询店铺补充资料
 * @param {*} query  token
 */

export function getSupplyInfo(data) {
  return axioss({
    url: "/app/admitOrder/getSupplyInfo",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}



/**
 * 完成补充资料
 * @param {*} query  token
 */

export function completeSupplyInfo(data) {
  return axioss({
    url: "/app/admitOrder/completeSupplyInfo",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}

/**
 * 完成店铺数据
 * @param {*} query  token
 */

export function completeShopInfo(data) {
  return axioss({
    url: "/app/preloan/completeShopInfo",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}


/**
 * 企业开户结算账户授权
 * @param {*} query  token
 */

export function openAcctAuth(data) {
  return axioss({
    url: "/app/preloan/openAcctAuth",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}



/**
 * 企业开户结算账户授权申请
 * @param {*} query  token
 */

export function applyAcctAuth(data) {
  return axioss({
    url: "/app/preloan/applyAcctAuth",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}


/**
 * 查询电子银行账户信息
 * @param {*} query  token
 */

export function getEbankAccount(data) {
  return axioss({
    url: "/app/home/getEbankAccount",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}


/**
 * 查询电子银行账户交易明细列表
 * @param {*} query  token
 */

export function queryEbankAccountTran(data) {
  return axioss({
    url: "/app/home/queryEbankAccountTran",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}


/**
 * 查询提现基本账户信息
 * @param {*} query  token
 */

export function getWithdrawBasicAccountInfo(data) {
  return axioss({
    url: "/app/home/getWithdrawBasicAccountInfo",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}


/**
 * 确认提现
 * @param {*} query  token
 */

export function confirmWithDraw(data) {
  return axioss({
    url: "/app/home/confirmWithDraw",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}

/**
 * 账户资料信息
 * @param {*} query  token
 */

export function getManagementDataInfoTo(data) {
  return axioss({
    url: "/app/home/getManagementDataInfoTo",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}

/**
 * 账户管理信息
 * @param {*} query  token
 */

export function getManagementAccountInfo(data) {
  return axioss({
    url: "/app/home/getManagementAccountInfo",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}

/**
 * 查询企业开户申请新网是否已处理结束
 * @param {*} query  token
 */

export function openCompanyIncomingDone(data) {
  return axioss({
    url: "/app/preloan/openCompanyIncomingDone",
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    data,
  });
}



/**
 * 查询请款确认协议模板
 * @param {*} query  token
 */

export function getContractList(params) {
  return axioss({
    url: "/app/preloan/getContractList",
    method: "get",
    params
  });
}



