<template>
  <div class="container">
    <div class="row r" @click="toMenu('editPhone')">
      <label class="t">修改手机号</label>
      <span class="v">{{mobile}}</span>
    </div>
    <div class="row r"  @click="toAgreementView('《隐私政策》', `https://ech5.test.jdz360.cn/agreement/豆易链隐私政策.html?t=${Math.random()}`)" >
      <label class="t">隐私政策</label>
    </div>
    <button class="btn1 btn-primary" @click="isConfromVisibleRpMask=true">安全退出</button>




     <!-- 二次确认弹窗 -->
     <transition name="cube-action-sheet-fade">
        <cube-popup
          type="repayment-confrim"
          position="center"
          :visible="isConfromVisibleRpMask"
          @mask-click="isConfromVisibleRpMask=false"
        >
          <transition name="cube-action-sheet-move">
            <div class="confrim-content" v-show="isConfromVisibleRpMask" @click.stop>
              <p class="title">提示</p>
              <p class="desc">您确定要退出登录吗？</p>
              <div class="footer-box">
                <span class="btn two" @click="isConfromVisibleRpMask=false">取消</span>
                <span class="btn main" @click="confirmLogout">确定</span>
              </div>
            </div>
          </transition>
        </cube-popup>
      </transition>
  </div>
</template>
<script>
  export default {
    data(){
      return{
        mobile:'',
        isConfromVisibleRpMask:false
      }
    },
    methods: {
      toMenu(name){
        this.$router.push({name})
      },
      confirmLogout(){
        window.localStorage.clear();
        this.$router.replace({name:'login'})
      },

      toAgreementView(protocolName, url) {
        this.$router.push({
          name: "AgreementView",
          query: {
            protocolName,
            url
          }
        });
      },
    },
    created() {
      const mobile = window.localStorage.getItem("account")||this.$ls.get("loginMobile");
      if (mobile) {
        this.mobile = mobile.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
      }
    }
  }
</script>
<style lang="less" scoped>
.container{
  background: #F5F7FB;
  .row{
    width: 343px;
    height: 46px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    margin:10px auto 0;
    display:flex;
    align-items:center;
    justify-content: space-between;
    padding: 0 37px 0 17px;
    .t{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 18px;
    }
    .v{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 18px;
    }
    &.r{
      position: relative;
      &::after{
        content:'';
        position: absolute;
        right: 10px;
        width: 24px;
        height:24px;
        background: url(~@/assets/images/img/right_icon.png) no-repeat;
        background-size:contain;
      }
    }
  }
  .btn1{
    all:unset;
    width: 321px;
    height: 42px;
    background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
    border-radius: 30px 30px 30px 30px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: calc(20px + env(safe-area-inset-bottom));
    left: 50%;
    transform: translateX(-50%);
  }


  .confrim-content{
    width: 316px;
    height: 158px;
    background: #FFFFFF;
    display:flex;
    flex-direction: column;
    border-radius: 8px 8px 8px 8px;
    .title{
      text-align:center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #3D3D3D;
      margin-top:22px;
    }
    .desc{
      text-align:center;
      font-family: PingFangSC-Medium, PingFangSC-Medium;
      font-weight: 400;
      font-size: 16px;
      color: #353535;
      margin-top:14px;
    }
    .footer-box{
      margin-top:25px;
      display:flex;
      justify-content: space-around;
      .btn{
        width: 108px;
        height: 36px;
        text-align:center;
        line-height:36px;
        font-family: PingFangSC-Semibold, PingFangSC-Semibold;
        font-weight: 400;
        font-size: 16px;
        border-radius: 30px 30px 30px 30px;
        &.main{
          background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
          color: #FFFFFF;
        }
        &.two{
          border: 1px solid #009DFF;
          color:#009DFF;
        }
      }
    }
  }
}
</style>