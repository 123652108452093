var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      !_vm.loading
        ? _c("div", { staticClass: "row-content" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "row-item border-bottom-1px",
                  on: {
                    click: function($event) {
                      return _vm.toAccount(
                        _vm.info.settleAcctStatus,
                        "settlementAccount"
                      )
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row-item-title" }, [
                    _c("span", { staticClass: "money" }, [_vm._v("结算账户")]),
                    _c("span", { staticClass: "status status02" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            ["未开通", "去开通", "审核中", "已开通"][
                              _vm.info.settleAcctStatus
                            ]
                          ) +
                          "\n            "
                      ),
                      _c("i")
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "row-item border-bottom-1px",
                  on: {
                    click: function($event) {
                      return _vm.toAccount(
                        _vm.info.balanceAcctStatus,
                        "balanceAccounts"
                      )
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row-item-title" }, [
                    _c("span", { staticClass: "money" }, [_vm._v("余额账户")]),
                    _c("span", { staticClass: "status status02" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            ["未开通", "去开通", "审核中", "已开通"][
                              _vm.info.balanceAcctStatus
                            ]
                          ) +
                          "\n            "
                      ),
                      _c("i")
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "row-item border-bottom-1px",
                  on: { click: _vm.toIncome }
                },
                [
                  _c("div", { staticClass: "row-item-title" }, [
                    _c("span", { staticClass: "money" }, [_vm._v("收款账户")]),
                    _c("span", { staticClass: "status status02" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            ["未添加", "去添加", _vm.info.incomeAcctNum][
                              _vm.info.incomeStatus
                            ]
                          ) +
                          "\n            "
                      ),
                      _c("i")
                    ])
                  ])
                ]
              )
            ])
          ])
        : _c("c-spin", {
            staticStyle: { "margin-top": "40vh", "text-align": "center" },
            attrs: { tip: "Loading...", size: "large" }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }