<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-12-24 16:15:11
 * @LastEditors: CZK
 * @LastEditTime: 2025-01-21 16:45:55
-->
<template>
  <div class="container" v-if='info'>
    <div class="list-container">
      <div class="item">
        <div class="up-box border-bottom-1px">
          <div class="title"> 
            <img :src="bankImg[info.enterpriseName]?bankImg[info.enterpriseName]:bankImg['默认']"/>{{info.enterpriseName	}}</div>
          <p class="num">{{formatNumber(info.accountNo)}}</p>
        </div>
        <div class="down-box">
          <div class="money-line">
            <span>可用余额</span> <span>￥{{info.balance}}</span>
            <button @click="toMenu('payouts')" class="on">提现</button>
          </div>
          <div class="desc-line">
            <span>开户日期 {{info.openDate}} </span>
          </div>
        </div>
        <!-- <p class="desc">{{info.receivingAccount.openBranchName}}</p> -->
      </div>

      <div class="card" v-for="(item,key,index) in list" :key="key">
        <div class="title border-bottom-1px" v-if="index===0">
          交易明细
        </div>
        <p class="date">{{key}}</p>
        <!-- @click="toMenu('transactionDetails',jtem.spbId)" -->
        <div class="card-item border-bottom-1px" v-for="(jtem,jndex) in item" :key="jndex" >
          <div class="line">
            <span class="title1">{{jtem.summary}}</span><span class="val">{{jtem.crDrMaintInd=='C'?'+':"-"}}{{jtem.tranAmt||0}}元<img src="../../assets/images/img/right_icon.png" alt=""></span>
          </div>

          <div class="line">
            <span class="title2">{{jtem.tranDate}}</span><span class="val2">余额：{{jtem.actualBalAmt}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {bankImg} from "@/utils/bankImg"
import{getEbankAccount,queryEbankAccountTran} from '@/api/user'
import moment from 'moment'
  export default {
    name: "balanceAccounts",
    data() {
      return {
        bankImg,
        info:null,
        list:[]
      }
    },
    methods:{
      formatNumber(numStr) {
          return numStr.replace(/^(\d{4})(\d+)(\d{4})$/,"$1" + "*".repeat(numStr.length - 8) + "$3");
      },
      returnList(rows){
        if(rows.length==0) return {}
        let obj = {}
        rows.forEach(item=>{
          let ym = moment(item.tranDate).format('YYYY年MM月')
          if(obj[ym]){
            item.tranDate = moment(item.tranDate).format('MM.DD hh:mm')
            obj[ym].push(item)
          }else{
            item.tranDate = moment(item.tranDate).format('MM.DD hh:mm')
            obj[ym] = [item]
          }
        })
        return obj
      },
      toMenu(name,spbId){
        this.$router.push({name,query:{spbId}})
      },
      async getInfo(){
        let res = await getEbankAccount({token:'',type:1})
        if(res.success) this.info = res.data

        let res1 = await queryEbankAccountTran({token:'',type:1})
        console.log(res1.data.tranRecordList,'----------')
        if(res1.success) this.list = this.returnList(res1.data.tranRecordList||[])
        console.log(this.list,'---------------------')
      }
    },
    mounted(){
      this.getInfo()
    }
  }
</script>
<style lang="less" scoped>
  .container {
    width: 100%;
    background: #F5F7FB url(../../assets/images/img/apply_bg.png) no-repeat top center;
    .list-container{
      margin:22px auto 0;
      width: 343px;
      .item{
        width: 343px;
        // height: 168px;
        background: #FFFFFF;
        box-shadow: 0px 3 8px 0px rgba(51,51,51,0.1);
        border-radius: 8px 8px 8px 8px;
        padding: 14px 24px;
        margin-bottom: 16px;
        .up-box{
          padding-bottom:15px;
        }
        .down-box{
          display: flex;
          flex-direction:column;
          margin-top: 19px;
          .desc-line{
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            line-height: 22px;
            margin-top:8px;
          }
          .money-line{
            display: flex;
            width: 100%;
            align-items:center;
            button{
              all:unset;
              width: 70px;
              height: 30px;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 14px;
              color: #FFFFFF;
              margin-left: auto;
              float: right;
              text-align: center;
              background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
              border-radius: 30px 30px 30px 30px;
              opacity: 0.5;
              &.on{
                background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
                opacity: 1;
              }
            }
            span{
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 16px;
              color: #333333;
              &:last-of-type{
                font-weight: 500;
                margin-left:15px;
              }
            }
          }
        }
        .title{
          display: flex;
          align-items:center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #333333;
          line-height: 20px;

          img{
            width:30px;
            height:30px;
            object-fit: contain;
            margin-right:8px;
          }
        }
        .num{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 18px;
          color: #333333;
          line-height: 23px;
          margin-top:7px;
          letter-spacing: 3px;
        }
        .desc{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          margin-top: 11px;
          line-height: 18px;
        }
      }

      .card{
        width: 343px;
        // margin-top: 16px;
        padding: 0 11px;
        background: #FFFFFF;
        box-shadow: 0px 3 8px 0px rgba(35,96,170,0.1);
        border-radius: 8px 8px 8px 8px;
        .title{
          padding:0 6px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #333333;
          height:46px;
          line-height: 46px;
        }
        .date{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #1C2023;
          line-height: 18px;
          padding-top: 15px;
          margin-left: 6px;
        }
        .card-item{
          width: 100%;
          height: 70px;
          display: flex;
          padding:  0 6px;
          flex-direction:column;
          justify-content: center;
          .line-date{
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            line-height: 18px;
            margin-top:8px;
          }
          .line{
            display:flex;
            justify-content: space-between;
            align-items: center;
            margin-top:6px;
            .title1{
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 16px;
              color: #333333;
            }
            .title2{
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #666666;
            }
            .val2{
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #666666;
            }
            .val{
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #333333;
              display:flex;
              align-items:center;
              img{
                width:24px;
                height:24px;
                object-fit:contain;
              }
            }
          }
        }
      }
    }
  }
</style>