var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { position: "relative" },
      on: {
        mouseover: function($event) {
          _vm.showImage = true
        },
        mouseout: function($event) {
          _vm.showImage = true
        }
      }
    },
    [
      _vm.type === "2"
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showImage,
                  expression: "showImage"
                }
              ],
              staticClass: "verify-img-out",
              style: {
                display: _vm.mode === "pop" ? "none" : undefined,
                position: _vm.mode === "pop" ? "absolute" : "relative",
                height: parseInt(_vm.setSize.imgHeight) + _vm.vSpace + "px",
                bottom: _vm.mode === "pop" ? "42px" : undefined
              },
              on: {
                mouseover: function($event) {
                  _vm.showImage = true
                },
                mouseout: function($event) {
                  _vm.showImage = true
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "verify-img-panel",
                  style: {
                    width: _vm.setSize.imgWidth,
                    height: _vm.setSize.imgHeight,
                    background:
                      "url(" + _vm.imgUrl + _vm.imgName[_vm.imgRand] + ")",
                    "background-size":
                      _vm.setSize.imgWidth + " " + _vm.setSize.imgHeight
                  }
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showRefresh,
                          expression: "showRefresh"
                        }
                      ],
                      staticClass: "verify-refresh",
                      on: { click: _vm.refresh }
                    },
                    [_c("i", { staticClass: "iconfont icon-refresh" })]
                  ),
                  _c("div", {
                    staticClass: "verify-gap",
                    style: {
                      width: _vm.blockSize.width,
                      height: _vm.blockSize.height,
                      top: _vm.top + "px",
                      left: _vm.left + "px"
                    }
                  })
                ]
              )
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "verify-bar-area",
          style: {
            width: _vm.setSize.barWidth,
            height: _vm.barSize.height,
            "line-height": _vm.barSize.height
          }
        },
        [
          _c("span", {
            staticClass: "verify-msg",
            domProps: { textContent: _vm._s(_vm.text) }
          }),
          _c(
            "div",
            {
              staticClass: "verify-left-bar",
              style: {
                width:
                  _vm.leftBarWidth !== undefined
                    ? _vm.leftBarWidth
                    : _vm.barSize.height,
                height: _vm.barSize.height,
                "border-color": _vm.leftBarBorderColor,
                transaction: _vm.transitionWidth
              }
            },
            [
              _c("span", {
                staticClass: "verify-msg",
                domProps: { textContent: _vm._s(_vm.finishText) }
              }),
              _c(
                "div",
                {
                  staticClass: "verify-move-block",
                  style: {
                    width: _vm.barSize.height,
                    height: _vm.barSize.height,
                    "background-color": _vm.moveBlockBackgroundColor,
                    left: _vm.moveBlockLeft,
                    transition: _vm.transitionLeft
                  },
                  on: { touchstart: _vm.start, mousedown: _vm.start }
                },
                [
                  _c("i", {
                    class: ["verify-icon iconfont", _vm.iconClass],
                    style: { color: _vm.iconColor }
                  }),
                  _vm.type === "2"
                    ? _c("div", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.mode !== "pop" && _vm.showImage,
                            expression: "mode !== 'pop' && showImage"
                          }
                        ],
                        staticClass: "verify-sub-block",
                        style: {
                          width: _vm.blockSize.width,
                          height: _vm.blockSize.height,
                          top:
                            "-" +
                            (parseInt(_vm.setSize.imgHeight) -
                              _vm.top +
                              _vm.vSpace) +
                            "px",
                          "background-image":
                            "url(" +
                            _vm.imgUrl +
                            _vm.imgName[this.imgRand] +
                            ")",
                          "background-size":
                            _vm.setSize.imgWidth + " " + _vm.setSize.imgHeight,
                          "background-position-y": "-" + _vm.top + "px",
                          "background-position-x": "-" + _vm.left + "px"
                        }
                      })
                    : _vm._e()
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }