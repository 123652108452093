var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        {
          staticClass: "row r",
          on: {
            click: function($event) {
              return _vm.toMenu("editPhone")
            }
          }
        },
        [
          _c("label", { staticClass: "t" }, [_vm._v("修改手机号")]),
          _c("span", { staticClass: "v" }, [_vm._v(_vm._s(_vm.mobile))])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "row r",
          on: {
            click: function($event) {
              _vm.toAgreementView(
                "《隐私政策》",
                "https://ech5.test.jdz360.cn/agreement/豆易链隐私政策.html?t=" +
                  Math.random()
              )
            }
          }
        },
        [_c("label", { staticClass: "t" }, [_vm._v("隐私政策")])]
      ),
      _c(
        "button",
        {
          staticClass: "btn1 btn-primary",
          on: {
            click: function($event) {
              _vm.isConfromVisibleRpMask = true
            }
          }
        },
        [_vm._v("安全退出")]
      ),
      _c(
        "transition",
        { attrs: { name: "cube-action-sheet-fade" } },
        [
          _c(
            "cube-popup",
            {
              attrs: {
                type: "repayment-confrim",
                position: "center",
                visible: _vm.isConfromVisibleRpMask
              },
              on: {
                "mask-click": function($event) {
                  _vm.isConfromVisibleRpMask = false
                }
              }
            },
            [
              _c("transition", { attrs: { name: "cube-action-sheet-move" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isConfromVisibleRpMask,
                        expression: "isConfromVisibleRpMask"
                      }
                    ],
                    staticClass: "confrim-content",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [
                    _c("p", { staticClass: "title" }, [_vm._v("提示")]),
                    _c("p", { staticClass: "desc" }, [
                      _vm._v("您确定要退出登录吗？")
                    ]),
                    _c("div", { staticClass: "footer-box" }, [
                      _c(
                        "span",
                        {
                          staticClass: "btn two",
                          on: {
                            click: function($event) {
                              _vm.isConfromVisibleRpMask = false
                            }
                          }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "btn main",
                          on: { click: _vm.confirmLogout }
                        },
                        [_vm._v("确定")]
                      )
                    ])
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }