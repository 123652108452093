<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2021-12-06 09:20:52
 * @LastEditors: CZK
 * @LastEditTime: 2025-01-08 12:00:59
-->
<template>
  <div class="container">
    <iframe
      id="iframe"
      class="agreement-iframe"
      frameborder="0"
      name="showHere"
      allowtransparency
      scrolling="auto"
      :src="url + '#toolbar=0&view=FitV'+'&navpanes=0'"
    ></iframe>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: "agreementView",
  data() {
    return {
      url: ""
    };
  },
  components: {},
  mounted() {
    const { url } = this.$route.query;
    console.log("url", url);
    this.url = url;

  },
  activated() {
    const { protocolName, url } = this.$route.query;
    // console.log("url", url);
    this.url = url;
    //更新标题

    if (protocolName) {
      document.title = protocolName;
    }

    this.lodingToast = this.$createToast({
      time: 0,
      txt: "加载中"
    }).show();
    
    this.$nextTick(() => {
      document.getElementById("iframe").onload = () => {
        this.lodingToast.hide();
      };
    });
  },
  deactivated () {
    this.lodingToast.hide();
  }
};
</script>

<style scoped lang="less">
.agreement-iframe {
  height: 100vh;
  width: 100%;
  // background: #ddd;
}
</style>
