<template>
  <div class="container">
    <div class="row-content" v-if="!loading">
      <div class="row" v-for="(item,index) in list" :key="index">
        <div class="row-time border-bottom-1px">{{index}}</div>
        <div class="row-item border-bottom-1px" v-for="(jtem,jndex) in item" :key="jndex" @click="toPage('applyDetail',jtem.applyId)">
          <div class="row-item-title">
            <span class="money">¥{{jtem.loanAmount||0}}</span><span class="status" :class="returnClass(jtem.orderStatus)">{{jtem.orderStatus}}<i></i></span>
          </div>
          <p class="row-item-date">{{jtem.applyTime}}</p>
        </div>
      </div>
      <empty v-if="!hasList"/>
    </div>
    <c-spin v-else style="margin-top:40vh;text-align: center;" tip="Loading..." size="large"></c-spin>
    
  </div>
</template>
<script>
  import { getLoanApplyList } from '@/api/user'
  import moment from "moment";
  import empty from './empty.vue'
  export default {
    name: "",
    components:{empty},
    data() {
      return {
        status:['申请中','待复审','还款中','已结清','已拒绝','逾期中','审批中','放款中'],
        list:null,
        loading:true
      }
    },
    computed: {
      hasList(){
        return this.list&& Object.keys(this.list).length>0
      }
    },
    methods:{
      returnClass(name){
       let i = this.status.findIndex(item=>item==name)
       return  i>-1?`status0${i+1}`:''
      },
      toPage(name,applyId){
        this.$router.push({name,query:{applyId}})
      },
      async getList(){
       let returnList = {}
       let res = await getLoanApplyList({token:''})
       if(res.success){
        (res.data||[]).forEach(item=>{
          item.applyMonth = moment(item.applyTime).format('YYYY年MM月')
          if(returnList[item.applyMonth]){
            returnList[item.applyMonth].push(item)
          }else{
            returnList[item.applyMonth] = [item]
          }
        })

        this.list = returnList
        setTimeout(()=>{
          this.loading = false
        },1000)
        
       }
      }
    },
    mounted(){
      this.getList()
    }
  }
</script>
<style lang="less" scoped>
  .container {
    width:100%;
    background:#F5F7FB url("../../assets/images/img/apply_bg.png") no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    .row-content{
      width:343px;
      margin:22px auto 0;
      .row{
        width: 343px;
        background: #FFFFFF;
        border-radius: 8px 8px 8px 8px;
        padding: 0 11px;
        margin-bottom:16px;
        .row-time{
          width:100%;
          height: 40px;
          line-height: 40px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #1C2023;
        }
        .row-item{
          padding:12px 6px;
          display:flex;
          flex-direction: column;
          justify-content: space-between;
          .row-item-title{
            display:flex;
            align-items:center;
            justify-content: space-between;
            .money{
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #333333;
            }
            .status{
              width: 74px;
              height: 26px;
              border-radius: 5px 5px 5px 5px;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              i{
                display:block;
                width:24px;
                height:24px;
              }
              &.status01{
                background: #FFEDE1;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #FF6A00;
                i{
                  background: url("../../assets/images/img/right01.png") no-repeat;
                  background-size: contain;
                }
              }

              &.status02{
                background: #EAEDFE;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #304FFE;
                i{
                  background: url("../../assets/images/img/right02.png") no-repeat;
                  background-size: contain;
                }
              }

              &.status03{
                background: #E8F6FC;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #007BFF;
                i{
                  background: url("../../assets/images/img/right03.png") no-repeat;
                  background-size: contain;
                }
              }

              &.status04{
                background: #E5F9ED;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #1BA555;
                i{
                  background: url("../../assets/images/img/right04.png") no-repeat;
                  background-size: contain;
                }
              }

              &.status05{
                background: #FFEFEF;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #FF0000;
                i{
                  background: url("../../assets/images/img/right05.png") no-repeat;
                  background-size: contain;
                }
              }

              &.status06,&.status07,&.status08{
                background: #F6EFFF;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #8A38F5;
                i{
                  background: url("../../assets/images/img/right06.png") no-repeat;
                  background-size: contain;
                }
              }
              
            }
          }
          .row-item-date{
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            margin-top: 7px;
            line-height: 18px;
          }
        }
      }
    }
  }
</style>