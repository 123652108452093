<template>
  <div class="container" v-show="!loading">
    <div class="login-one" v-if="!loginNum">
      <img src="../../assets/images/img/title-bg.png" class="title-bg"/>
      <div class="an-box">
        <img src="../../assets/images/img/an-icon.png" alt="" />
        由国家合规平台出资
      </div>
      <div class="login-one-bg">
        <img src="../../assets/images/img/login_title.png" class="main-bg" alt="" />
      </div>
      
      <div class="login-main" >

        <!-- <p class="login-title">账号登录</p> -->
        <div class="login-mobile-input">
          <img src="../../assets/images/img/mobile.png" alt="">
          <input
            v-fixedInput
            v-model="mobile"
            class="fill-input"
            maxlength="11"
            :placeholder="`请输入手机号（已加密）${invitationCode}`"
            type="tel"
            onkeyup="this.value=this.value.replace(/\D/g,'')"
          />
        </div>
        <div class="login-code-input" v-if="!wechatLogin">
          <img src="../../assets/images/img/message.png" alt="">
          <input
            v-fixedInput
            v-model="tattedCode"
            class="fill-input"
            maxlength="6"
            placeholder="短信验证码"
            type="number"
            onkeyup="this.value=this.value.replace(/\D/g,'')"
          />
          <!-- showPuzzle -->
          <div
            class="get-code"
            @click.stop.prevent="showPuzzle"
          >{{ state.smsSendBtn ? state.time + 's后重发' : '获取验证码'}}</div>
        </div>

        <div class="login-mobile-input top14" :class="{noedit:!isEdit}">
          <img src="../../assets/images/img/email.png" alt="">
          <input
            v-fixedInput
            v-model="invitationCode"
            class="fill-input"
            maxlength="6"
            :placeholder="`'请填写邀请码(选填)'`"
            type="number"
            :disabled="!isEdit"
            onkeyup="this.value=this.value.replace(/\D/g,'')"
          />
        </div>


        <div class="login-sumbit" @click="handleSubmit" :disabled="submitting">
          专属额度，立即领取
        </div>
        <div class="login-agreen">
          <i></i>
          <p> 
            我已阅读并同意
            <a @click="toAgreementView('《用户服务协议》', `https://ech5.test.jdz360.cn/agreement/豆易链服务协议.html?t=${Math.random()}`)" class="agreement-link">《用户服务协议》</a>
            <a @click="toAgreementView('《隐私政策》', `https://ech5.test.jdz360.cn/agreement/豆易链隐私政策.html?t=${Math.random()}`)" class="agreement-link">《隐私政策》</a>
            ，未注册时将自动注册账号
          </p>
        </div>
      </div>
    </div>
    
    <div class="login-two" v-else>
      <img src="../../assets/images/login_two.png" class="main-bg" alt="" />
      <span class="login-title">HELLO，欢迎回来</span>
      <p class="login-mobile">{{maskPhoneNumber(mobile)}}</p>

      <div class="login-sumbit" @click="handleSubmit" :disabled="submitting">一键登录</div>
      <p class="login-change" @click="loginNum=false">切换账号</p>
      <div class="login-agreen">
        <i></i>
        <p> 
          我已阅读并同意
        <a @click="toAgreementView('《用户服务协议》', `https://ech5.test.jdz360.cn/agreement/豆易链服务协议.html?t=${Math.random()}`)" class="agreement-link">《用户服务协议》</a>
        <a @click="toAgreementView('《隐私政策》', `https://ech5.test.jdz360.cn/agreement/豆易链隐私政策.html?t=${Math.random()}`)" class="agreement-link">《隐私政策》</a>
         ，未注册时将自动注册账号
        </p>
      </div>
    </div>

    <!-- <div class="puzzle-box" v-if="verificationShow">
      <PuzzleVerification
        :verificationShow="verificationShow"
        deviation="20"
        :puzzleImgList="puzzleImgList"
        blockType="puzzle"
        @setVisible="setVisibleChange"
        :onSuccess="handleSuccess"
      />
    </div> -->
    <div class="puzzle-box" v-if="verificationShow">
      <verify :type="5" @success="handleSuccess" width="60%" @error="handleError()"/>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { msgLogin, verificationCode, sendSms, regAndLogin, getH5OpenId ,getVerificationCode, getCaptcha} from "@/api/user";
import { Validator } from "@/utils/validate.js";
import { setRepaymentToken, wxlogin } from "@/utils/auth";
import { ButtonMixin } from "@comp/Button";
import store from "@/store";
import { JSEncrypt } from "jsencrypt";
import fixedInput from "@/directive/fixedInput";
import { APP_VERSION, RSA_PUBLIC_KEY } from "@/config/config";
import PuzzleVerification from '@comp/vue-puzzle-verification/src/puzzleVerification.vue'
import verify from "@comp/verify/components/Verify.vue"
const LIMITTIME = 60;
const banner = require("@/assets/images/img/block01.png");
const banner_1 = require("@/assets/images/img/block02.png");
const banner_2 = require("@/assets/images/img/block03.png");
const banner_3 = require("@/assets/images/img/block04.png");
const banner_4 = require("@/assets/images/img/block05.png");
const banner_5 = require("@/assets/images/img/block06.png");

// const banner_2 = require("@/assets/images/banner_2.png");
export default {
  name: "",
  mixins: [ButtonMixin],
  components: {PuzzleVerification,verify},
  directives: {
    fixedInput
  },
  data() {
    return {
      puzzleImgList:[banner,banner_1,banner_2,banner_3,banner_4,banner_5],
      state: {
        time: LIMITTIME,
        smsSendBtn: false
      },
      loading: true,
      loginNum: false,
      mobile: "",
      // 调用短信验证码返回
      tattedId: "",
      tattedCode: "",
      invitationCode:'',
      captchaValue:'',
      checked: true,
      imgCode:'',
      openId: '',
      captchaKey:'',
      isEdit:true,
      checkedAccount:false,
      wechatLogin:false,
      showCode:false,
      verificationShow:false,
      authWechatLogin:true,
      isWechat: getIsWxClient()
    };
  },
  computed: {},
  watch: {
    mobile(val){
        console.log(val,'----')
        if(val.length==11) this.getBindStatus(val)
    },
    'captchaValue':{
      handler(val){
        console.log(val,"-----")
        // 展示验证码框
        if(val.length==4) this.showCode = true
        else this.showCode = false
      },
      deep:true
    },
  },
  methods: {
    handleError(){
      this.$createToast({
          type: "error",
          txt: "验证失败"
        }).show();
    },
    handleSuccess(e){
      this.verificationShow = false
      this.getCaptcha()
    },
    setVisibleChange(e){
      this.verificationShow = e
    },
    maskPhoneNumber(phoneNumber) {  
      // 检查手机号长度是否为11位  
      if (phoneNumber.length !== 11) {  
          return '无效的手机号';  
      }  
    
      // 提取前三位和后四位  
      const prefix = phoneNumber.slice(0, 3);  
      const suffix = phoneNumber.slice(-4);  
    
      // 生成中间部分的星号  
      const stars = '*'.repeat(phoneNumber.length - prefix.length - suffix.length);  
    
      // 拼接结果  
      return prefix + stars + suffix;  
    },
    getImgCaptcha(){
      getCaptcha({token:''}).then(res=>{
          this.imgCode = res.data.base64
          this.captchaKey = res.data.validateKey
        })
    },
    changeTap(e){
      console.log(e,"===")
        this.$createDialog({
        type: "confirm",
        title: '请确认当前微信是本人常用微信？',
        // icon: "cubeic-alert",
        content: `开通微信授权登录后，下次不需短信验证，直接登录。`,
        confirmBtn: {
          text: "确定",
          active: true,
          disabled: false,
          href: "javascript:;"
        },
        cancelBtn: {
          text: "取消",
          active: false,
          disabled: false,
          href: "javascript:;"
        },
        onConfirm: () => {
          this.authWechatLogin=e
        },
        onCancel: () => {
          this.authWechatLogin=false
        }
      }).show();
    },
    getBindStatus(val){
      let openid=this.openId||window.localStorage.getItem('openId')
      if(!openid||!val){
        this.loading = false
        return
      } 
      console.log(openid,"---",val)
      getVerificationCode({mobile:val,openid:openid}).then(res=>{
       this.wechatLogin = res.data.isBind=='1'
       this.loginNum =  res.data.isBind=='1'
       this.mobile =  res.data.mobile
       this.loading = false
      })
    },
    //恢复按钮
    regainCheckBtn(state) {
      state.time = LIMITTIME;
      state.smsSendBtn = false;
    },
    showPuzzle(){
      if(this.state.smsSendBtn) return
      const { checked, state, mobile, captchaKey, captchaValue } = this;
      if(!mobile){
        this.$createToast({
          type: "warn",
          txt: "请输入手机号"
        }).show();
        return;
      }
      this.verificationShow = true
    },
    //获取验证码
    getCaptcha() {
      const { checked, state, mobile, captchaKey, captchaValue } = this;
      // if(!this.showCode) return
      if (!checked) {
        this.$createToast({
          type: "warn",
          txt: "请阅读并同意服务协议"
        }).show();
        return;
      }
      setTimeout(() => {
        if (mobile.length > 0) {
          state.smsSendBtn = true;
          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              this.regainCheckBtn(state);
              window.clearInterval(interval);
            }
          }, 1000);
          const toastSuccess = this.$createToast({
            txt: "验证码发送中",
            type: "correct",
            time: 0
          });
          toastSuccess.show();
          sendSms({mobile, authCodeType:'UserLogin',captchaValue, captchaKey})
            .then(res => {
              toastSuccess.hide();
              if (res && res.success && res.data && res.data.authId) {
                this.$createToast({
                  txt: "验证码已发送，请留意",
                  type: "correct"
                }).show();
                // 储存tattedId
                const tattedId = res.data.authId;
                this.tattedId = tattedId;
                // 记录信息
                this.$ls.set("loginTattedId", tattedId);
                this.$ls.set("loginMobile", mobile);
                this.$ls.set("startLoginTimeCode", +new Date());
              } else {
                clearInterval(interval);
                this.regainCheckBtn(state);
              }
            })
            .catch(err => {
              console.log('err', err)
              toastSuccess.hide();
              clearInterval(interval);
              this.regainCheckBtn(state);
            });
        } else {
          this.$createToast({
            txt: "请确认手机号",
            type: "error"
          }).show();
        }
      }, 0);
    },
    //提交登录
    handleSubmit() {

      let { checked, mobile, tattedId, tattedCode,invitationCode } = this;
      if (!checked) {
        this.$createToast({
          type: "warn",
          txt: "请阅读并同意服务协议"
        }).show();
        return;
      }
      let validator = new Validator();
      validator.add(mobile, "mobileFormat", "请确认手机号码");
     if(!this.wechatLogin) validator.add(tattedCode, "isEmpty", "请确认短信验证码");
      const errMsg = validator.start();
      if (errMsg) {
        this.$createToast({
          txt: errMsg,
          type: "error"
        }).show();
        return;
      }
      this.submitting = true;
      if(this.wechatLogin) tattedCode='111'
      console.log(tattedId,"0000000000")
      regAndLogin({appVersion:APP_VERSION, authCode:"" == tattedId ? getRsaStr(tattedCode, RSA_PUBLIC_KEY) : tattedCode, authId:tattedId,
        equipment: this.appSource(), mobile, mobileModel:'', mobileVersion:'', source:'jdz03', type:'UserLogin',invitationCode,
        wxOpenid:this.openId||window.localStorage.getItem('openId'),
        wechatLogin:this.wechatLogin,authWechatLogin:this.authWechatLogin})
        .then(res => {
          if (res.success) {
            console.log("success登录了----------------------- ", res);
            saveLoginInfo(res.data);
            this.$ls.set("loginMobile", mobile);
            //保存
            if(this.checkedAccount){
              window.localStorage.setItem('mobile',mobile);
              
              // window.localStorage.setItem('tattedCode',tattedCode);
            }else{
              window.localStorage.setItem('mobile','');
              window.localStorage.setItem('tattedCode','');
            }

            store.commit('SET_ISLOGIN', true);

            this.$router.replace({
              name: "xaIndex"
            });
          }
          this.regainStatus();
        })
        .catch(err => {
          console.log("err------------------报错了 ", err);
          this.regainStatus();
        });
    },
    toAgreementView(protocolName, url) {
      this.$router.push({
        name: "AgreementView",
        query: {
          protocolName,
          url
        }
      });
    },









///////////////////////////////////////////////////////////////////
    //方便登录调试
    getCaptcha2() {
      const { state, mobile } = this;

      setTimeout(() => {
        if (mobile.length > 0) {
          state.smsSendBtn = true;

          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              this.regainCheckBtn(state);
              window.clearInterval(interval);
            }
          }, 1000);

          const toastSuccess = this.$createToast({
            txt: "验证码发送中",
            type: "correct",
            time: 0
          });
          toastSuccess.show();

          verificationCode({ mobile })
            .then(res => {
              toastSuccess.hide();
              if (
                res &&
                res.success &&
                res.tattedId
              ) {
                this.$createToast({
                  txt: "验证码已发送，请留意",
                  type: "correct"
                }).show();
                // 储存tattedId
                const tattedId = res.tattedId;
                this.tattedId = tattedId;
                // 记录信息
                // this.$ls.set("loginTattedId", tattedId);
                this.$ls.set("loginMobile", mobile);
                this.$ls.set("startLoginTimeCode", +new Date());
              } else {
                clearInterval(interval);
                this.regainCheckBtn(state);
                
              }
            })
            .catch(err => {
              console.log('err', err)
              toastSuccess.hide();
              clearInterval(interval);
              this.regainCheckBtn(state);
            });
        } else {
          this.$createToast({
            txt: "请确认手机号",
            type: "error"
          }).show();
        }
      }, 0);
    },

    handleSubmit2() {
      const { mobile, tattedId, tattedCode } = this;

      let validator = new Validator();

      validator.add(mobile, "mobileFormat", "请确认手机号码");
      validator.add(tattedCode, "isEmpty", "请确认短信验证码");
      const errMsg = validator.start();
      if (errMsg) {
        this.$createToast({
          txt: errMsg,
          type: "error"
        }).show();
        return;
      }
      this.submitting = true;
      msgLogin({ mobile, tattedId, tattedCode })
        .then(res => {
          if (res.success) {
            const { token } = res.data;
            setRepaymentToken(token);

            this.$router.replace({
              name: "mine"
            });
          }
          this.regainStatus();
        })
        .catch(err => {
          console.log("err ", err);
          this.regainStatus();
        });
    },
    appSource() {
			const u = navigator.userAgent;
			const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
			if (isiOS) {
				return "iOS";
			} else {
				return "Android";
			}
		}
  },
  mounted() {
    // this.$ls.get("loginTattedId") &&
    //   (this.tattedId = this.$ls.get("loginTattedId")); 取消获取

    // this.$ls.get("loginMobile") && (this.mobile = this.$ls.get("loginMobile"));

    const nowTime = +new Date();
    const prevTime = this.$ls.get("startLoginTimeCode") || 0;

    const timeDifference = ((nowTime - prevTime) / 1000) | 0;

    if (timeDifference < LIMITTIME) {
      const { state } = this;
      state.time = LIMITTIME - timeDifference;
      state.smsSendBtn = true;
      const interval = window.setInterval(() => {
        if (state.time-- <= 0) {
          this.regainCheckBtn(state);
          window.clearInterval(interval);
        }
      }, 1000);
    }


    const {state,invitationCode} = this.$route.query
    let isVal = state==undefined||state==''||state==null||state=='null'||state=='undefined'
    let isinvitationCode= invitationCode==undefined||invitationCode==''||invitationCode==null||invitationCode=='null'||invitationCode=='undefined'

    this.invitationCode = (isinvitationCode?'':invitationCode) || (isVal?'':state)
    
    this.isEdit = !((isinvitationCode?'':invitationCode) || (isVal?'':state))

    // 清空页面缓存
    store.commit("CLEAR_CACHE_VIEW");
    const mobile = window.localStorage.getItem('mobile');
    // const tattedCode = window.localStorage.getItem('tattedCode');
    
    console.log(mobile,"0000000")
    //保存
    // if(mobile){
    //   this.mobile = mobile
    //   // this.tattedCode = tattedCode
    //   this.checkedAccount = true
    //   this.getBindStatus(mobile)
    // }

    // this.getImgCaptcha()

  },
  created(){
    //
    if(getIsWxClient()){
      // 判断是否微信登录了
      // if(!window.localStorage.getItem('openId')){
        console.log(window.localStorage.getItem('openId'),"缓存openId")
        //判断是否回来回调
        if (this.$route.query.code) {
          
          console.log(this.$route.query.code,"回调code",this.$route.query.invitationCode)

            //执行微信登录跟后端换取openId 
            getH5OpenId({code:this.$route.query.code}).then(res=>{
              console.log(res.data.wxOpenid,"返回openId")
              if(res.data.wxOpenid){
                this.openId=res.data.wxOpenid
                this.mobile=res.data.mobile
                window.localStorage.setItem('mobile',this.mobile);
                window.localStorage.setItem('openId',this.openId)
                this.getBindStatus(this.mobile)
              }else{
                saveLoginInfo(res.data);
                store.commit('SET_ISLOGIN', true);
                //跳转我的页面
                this.$router.replace({
                  name: "main"
                });
              }
            })        
        }else{
          //前往微信授权
          const {invitationCode} = this.$route.query

          wxlogin(window.location.href,invitationCode)
        }
      // }
    }else{
      this.loading = false
    }
   
  }
};
 /**
   * 判断是否是微信环境
   */
  function getIsWxClient () {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
          return true;
      }
      return false;
  };

/**
 * RSA
*/
function getRsaStr(obj, publicKey) {
  let encrypt = new JSEncrypt();
  if (null == publicKey) {
    console.log("Rsa publicKey is null");
  } else {
    //console.log("Rsa publicKey is " + publicKey)
  }
  encrypt.setPublicKey(publicKey);
  return encrypt.encrypt(obj);
}

/**
 * 保存登录信息
*/
function saveLoginInfo(obj) {
  const { mobile, currentTime, freshToken, token, userId, openId } = obj;
  
  //保存用户账号
  window.localStorage.setItem("account", mobile);
  //保存时间差，本地与服务器的时间差
  window.localStorage.setItem("currentTimeDiff", Date.now() - currentTime);
  //保存freshToken
  window.localStorage.setItem("freshToken", freshToken);
  //保存token
  window.localStorage.setItem("token", token);
  //保存userId
  window.localStorage.setItem("userId", userId);
  //保存openId 用于公众号推送
  window.localStorage.setItem("openId", openId||'');
   //手机号
  window.localStorage.setItem("mobile", mobile);
}
</script>

<style scoped lang="less">
// .container {
//   padding: 38px 16px 0;
//   font-size: 16px;
//   .logo {
//     display: block;
//     margin: 0 auto 54px;
//     width: 100px;
//     object-fit: contain;
//     height: 100px;
//   }

//   .fill-item {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     height: 67px;
//     img{
//       width: 15px;
//       object-fit: contain;
//       margin-right: 10px;
//       &.img_code{
//         width: 100px;
//         height: 50px;
//         object-fit: contain;
//         margin-right: 0;
//       }
//     }
//     .fill-name {
//       width: 70px !important;
//       //padding-left: 16px;
//       text-align: left;
//       color: rgba(51, 51, 51, 1);
//       line-height: 40px;
//     }
//     .fill-input {
//       height: 40px;
//       width: 150px;
//       flex: 1;
//       background: none;
//       outline: none;
//       border: 0px;
//       font-size: 14px;
//       color: #666;
//       &::-webkit-input-placeholder {
//         color: #ccc;
//         // line-height: 1.2em;
//         line-height: 19px;
//       }
//     }

//     .get-code {
//       width: 90px;
//       text-align: right;
//       font-size: 14px;
//       font-family: PingFang SC;
//       font-weight: 500;
//       color: #4E75FA;
//     }
//   }
//   .login-tip{
//     width: 100%;
//     display: flex;
//     align-items:center;
//     margin-top: 21px;
//     justify-content: center;
//     p{
//       font-size: 12px;
//       font-family: PingFang SC;
//       font-weight: 400;
//       color: #999999;
//       &::before{
//         content:'*';
//         color:#FA324E;
//         font-size: 12px;
//       }
//     }
//   }
//   .login-bg{
//     background-color: #fff;
//     z-index:10;
//     position: absolute;
//     width: 100%;
//   }
//   .with-click1{
//     padding: 0;
//     .cube-checkbox-wrap{
//       padding:0;
//     }
//     /deep/.cube-checkbox-label{
//       font-size: 14px !important;
//     }
//   }
//   .agreement-read-section {
//     padding: 10px 0 16px;
//     font-size: 12px;
//     font-family: SourceHanSansCN-Regular;
//     font-weight: 400;
//     color: #333;
//     line-height: 1.2;
//     // position:fixed;
//     // bottom: 50px;
//     .with-click {
//       font-size: 16px;
//       &.cube-checkbox {
//         padding: 0 2px 0 2px;
        
//       }
//     }
//     .agreement-name-section {
//       text-align: left;
//       line-height: 1.5;
//       font-size: 14px;
//       .agreement-link {
//         color: #4E75FA;
//         &:active {
//           opacity: 0.6;
//         }
//       }
//     }
//   }

//   .submit-section {
//     margin-top: 33px;
//     .cube-btn{
//       height: 40px;
//       padding: 0;
//       width: 343px;
//       background: #D2D2D2;
//       border-radius: 20px;
//       &.on{
//         background:#4E75FA;
//       }
//     }
//   }
// }
input {  
    all: initial; /* 或 all: unset; */  
}
.container {
  width: 100%;
  .login-one{
    width: calc(100%);
    background:#F5F7FB url("../../assets/images/img/login-one-bg.png") no-repeat;
    background-size: 100% auto;
    // padding-top: 57px;
    padding: 0 16px;
    .main-bg{
      width: 142px;
      height: 70px;
      object-fit: contain;
      margin: 28px 0 36px 18px;
    }
    .an-box{
      display:flex;
      align-items:center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 12px;
      color: rgba(0,0,0,0.5);
      line-height: 15px;
      margin-left: 12px;
      img{
        width:12px;
        height:12px;
        object-fit:contain;
        margin-right:2px;
        margin-top:6px;
      }
    }
    .title-bg{
      width:251px;
      object-fit:contain;
      margin-top:18px;
      margin-left: 12px;
    }
    .login-one-bg{
      margin-top:16px;
      width:100%;
      background: url("../../assets/images/img/base.png") no-repeat;
      background-size: 100% auto;
    }
    .login-main{
    width: 100%;
    margin: 0 auto;
    // border-radius: 16px 16px 0px 0px;
    min-height: calc(100vh - 142px);
    background: #fff;
    // border-radius: 10px 10px 0 0;
    position: relative;
    display: flex;
    flex-direction: column;
    // box-shadow: 0 4px 4px #e4f0ff;
    align-items: center;
    .login-title{
      font-family: PingFang SC;
      font-weight: 600;
      font-size: 15px;
      color: #1578FE;
      margin-top: 15px;
      position: relative;
      &::before{
        content: '';
        display: block;
        width: 9px;
        height: 1px;
        border-radius: 0.5px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -6px;
        margin: 0 auto;
        background: #1578FE;
      }
    }
    .login-mobile-input{
      width: 310px;
      height: 42px;
      display: flex;
      margin-top: 13px;
      align-items: center;
      border-radius: 10px 10px 10px 10px;
      border: 1px solid #CCCCCC;
      
      &.top14{
        margin-top: 14px;
      }
      img{
        width:24px;
        height:24px;
        object-fit: contain;
        margin: 0 10px 0 16px;
      }
      input{
        flex: 1;
        padding:0 10px;
        color:#333;
        &::placeholder{
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #999999;
        }
        &::-webkit-input-placeholder {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #999999;
        }
      }

      &.noedit{
        background:#eee;
        input{
          color:#5c5c5c;
        }
      }
    }
    .login-code-input{
      width: 310px;
      height: 42px;
      margin-top: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px 10px 10px 10px;
      border: 1px solid #CCCCCC;
      img{
        width:24px;
        height:24px;
        object-fit: contain;
        margin: 0 10px 0 16px;
      }
      input{
        padding-left:10px;
        display: block;
        color:#333;
        width: calc(100% - 100px - 50px);
        &::placeholder{
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #999999;
        }
        &::-webkit-input-placeholder {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #999999;
        }
      }
      .get-code{
        width: 100px;
        height: 24px;
        white-space: nowrap;
        font-family: PingFang SC;
        line-height: 24px;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        background: #FFFFFF;
        border-left: 1px solid #E5E5E5;
      }
    }
    

    .login-sumbit{
      width: 310px;
      height: 42px;
      background: linear-gradient( 90deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
      border-radius: 30px 30px 30px 30px;
      text-align: center;
      line-height: 42px;
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF;
      margin-top: 41px;
      // letter-spacing: 4px;
    }
    .login-agreen{
      width: 247px;
      display: flex;
      margin-top: 33px;
      align-items: center;
      // position: fixed;
      // bottom: 48px;
      // left: 0;
      // right: 0;
      margin: 33px auto;
      i{
        display: block;
        width: 13px;
        height: 13px;
        background: url("../../assets/images/img/check_on.png") no-repeat;
        background-size: 100% 100%;
      }
      p{
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 10px;
        margin-left: 10px;
        max-width: 90%;
        text-align: left;
        color: #666666;
        line-height: 16px;
        a{
          color: rgba(38, 129, 251, 1);
        }
      }
    }
    }
  }
  
  .login-two{
    width: 100%;
    min-height: 100vh;
    background: url("../../assets/images/two_bg.png") no-repeat;
    background-size: 100% auto;
    display: flex;
    flex-direction: column;
    .main-bg{
      width: 100%;
      height: 275px;
      object-fit: contain;
      margin: 0 auto 55px;
    }
    .login-title{
      font-family: PingFang SC;
      font-weight: 600;
      font-size: 23px;
      color: #333333;
      margin-left: 42px;
      text-align: left;
    }
    .login-mobile{
      font-family: PingFang SC;
      font-weight: 600;
      font-size: 18px;
      color: #333333;
      text-align: center;
      margin-top: 34px;
    }
    .login-sumbit{
      width: 339px;
      height: 45px;
      border-radius: 22px;
      background: linear-gradient(0deg, #0B73FF 0%, #2982FA 100%);
      text-align: center;
      line-height: 45px;
      margin: 0 auto;
      font-family: PingFang SC;
      font-weight: 600;
      font-size: 18px;
      margin-top: 62px;
      box-shadow: 0 4px 4px #9bc6fe;
      letter-spacing: 4px;
      color: #FFFFFF;
    }
    .login-change{
      font-family: PingFang SC;
      font-weight: 600;
      font-size: 12px;
      color: #333333;
      line-height: 19px;
      margin:18px auto 0;
      text-decoration-line: underline;
    }
    .login-agreen{
      width: 247px;
      display: flex;
      margin: 0 auto;
      margin-top: 33px;
      align-items: center;
      i{
        display: block;
        width: 13px;
        height: 13px;
        background: url("../../assets/images/img/check_on.png") no-repeat;
        background-size: 100% 100%;
      }
      p{
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-size: 10px;
        margin-left: 10px;
        max-width: 90%;
        text-align: left;
        color: #666666;
        line-height: 16px;
        a{
          color: rgba(38, 129, 251, 1);
        }
      }
    }
  }
}
.puzzle-box{
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 9999;
  top: 0;
  // /deep/ .puzzle-container{
  //   display: inline-block !important;
  // }
}
</style>
