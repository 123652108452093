var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("iframe", {
      staticClass: "agreement-iframe",
      attrs: {
        id: "iframe",
        frameborder: "0",
        name: "showHere",
        allowtransparency: "",
        scrolling: "auto",
        src: _vm.url + "#toolbar=0&view=FitV" + "&navpanes=0"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }