<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-10-29 13:58:38
 * @LastEditors: CZK
 * @LastEditTime: 2025-01-08 19:00:34
-->
<template>
  <div class="container" v-if="info">
    <div class="card">
      <p class="danwei">结算金额(元)</p>
      <p class="money">{{info.repaymentDiff||0}}</p>

      <div class="date-line border-bottom-1px">
        <span class="title">{{info.signDate}}</span><span class="val">请款{{info.loanAmount||0}}元
          <!-- <img src="../../assets/images/img/right_icon.png" alt=""> -->
        </span>
      </div>

      <div class="line">
        <span class="title">结算金额</span><span class="val">￥{{info.repaymentDiff||0}}</span>
      </div>

      <div class="small-line">
        <span class="title">本金</span><span class="val">￥{{info.principalDiff||0}}</span>
      </div>
      <div class="small-line">
        <span class="title">融资费</span><span class="val">￥{{info.basicInterestDiff||0}}</span>
      </div>
      <div class="small-line">
        <span class="title">管理费</span><span class="val">￥{{info.platformServiceDiff||0}}</span>
      </div>
      <div class="small-line">
        <span class="title">服务费</span><span class="val">￥{{info.channelServiceDiff||0}}</span>
      </div>
      <div class="border-bottom-1px" style="margin-top:9px;"></div>
      <div class="line border-bottom-1px">
        <span class="title">结算账户</span><span class="val">{{info.bankName}}(尾号{{info.bankAccount}})</span>
      </div>
      <div class="line border-bottom-1px">
        <span class="title">结算方式</span><span class="val">{{info.repayType}}</span>
      </div>
      <div class="line border-bottom-1px">
        <span class="title">结算时间</span><span class="val">{{info.repayTime}}</span>
      </div>
      <div class="line border-bottom-1px">
        <span class="title">结算状态</span><span class="val">{{info.status}}</span>
      </div>
    </div>
  </div>
</template>
<script>
  import {getRepaymentDetail} from "@/api/user"

  export default {
    name: "repaymentDetails",
    data() {
      return {
       info:null 
      }
    },
    methods:{
      toMenu(name){
        this.$router.push({name})
      },
      async getInfo(){
        const billId = this.$route.query.billId
        const res = await getRepaymentDetail({token:'',billId})
        if(res.success) this.info = res.data
      }
    },
    mounted(){
      this.getInfo()
    }
  }
</script>
<style lang="less" scoped>
  .container {
    width: 100%;
    background: #F5F7FB url(../../assets/images/img/mine_bg.png) no-repeat top center;
    .card{
      width: 343px;
      background: #FFFFFF;
      margin:22px auto 0;
      padding:0 11px 0;
      box-shadow: 0px 3 8px 0px rgba(35,96,170,0.1);
      border-radius: 8px 8px 8px 8px;
      display:flex;
      flex-direction: column;
      .small-line{
        padding:0 6px;
        display:flex;
        justify-content: space-between;
        align-items: center;
        height: 30px;
        .title{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
        }
        .val{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 18px;
        }
      }
      .line{
        padding:0 6px;
        // margin-top: 14px;
        display:flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        .title{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
          line-height: 20px;
        }
        .val{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
          line-height: 18px;
        }

      }
      .date-line{
        padding:0 6px;
        margin-top: 14px;
        display:flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        .title{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 18px;
        }
        .val{
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 18px;
          display:flex;
          align-items:center;
          img{
            width:24px;
            height:24px;
            object-fit:contain;
          }
        }
      }
      .money{
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 36px;
        color: #333333;
        text-align: center;
        margin-top: 5px;
        line-height: 45px;
      }
      .danwei{
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        text-align:center;
        color: #333333;
        margin-top: 21px;
        line-height: 18px;
      }
      
    }
    
  }
</style>