var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _c("div", { staticClass: "confrim-content" }, [
      _c("p", { staticClass: "title" }, [_vm._v("提示")]),
      _c("p", { staticClass: "desc" }, [
        _vm._v(
          "当前账簿未完成结算授权，无法实现账户结算 请点击下方获取短信验证码，完成授权确认"
        )
      ]),
      _c("div", { staticClass: "line h50 border-bottom-1px" }, [
        _c("span", { staticClass: "t" }, [_vm._v("验 证 码")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.authCode,
              expression: "authCode"
            }
          ],
          attrs: { type: "text", placeholder: "短信验证码" },
          domProps: { value: _vm.authCode },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.authCode = $event.target.value
            }
          }
        }),
        _c(
          "div",
          {
            staticClass: "get-code",
            class: { on: !_vm.state.smsSendBtn },
            on: {
              click: function($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.getCaptcha($event)
              }
            }
          },
          [
            _vm._v(
              _vm._s(
                _vm.state.smsSendBtn ? _vm.state.time + "s后重发" : "获取验证码"
              )
            )
          ]
        )
      ]),
      _c("div", { staticClass: "line" }, [
        _c("span", { staticClass: "tip" }, [
          _vm._v(
            "短信验证码发送到" +
              _vm._s(_vm.info.contactPhone) +
              "（" +
              _vm._s(_vm.info.contactName) +
              "）"
          )
        ])
      ]),
      _c("div", { staticClass: "footer-box" }, [
        _c("span", { staticClass: "btn two", on: { click: _vm.toIndex } }, [
          _vm._v("取消")
        ]),
        _c("span", { staticClass: "btn main", on: { click: _vm.commit } }, [
          _vm._v("确定")
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-box" }, [
      _c("p", { staticClass: "title" }, [_vm._v("结算授权")]),
      _c("p", { staticClass: "desc" }, [_vm._v("请填写以下资料吧~")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }