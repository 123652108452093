var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cerify-code-panel" }, [
    _c(
      "div",
      {
        staticClass: "verify-code",
        style: {
          width: _vm.width,
          height: _vm.height,
          "line-height": _vm.height,
          "font-size": _vm.fontSize,
          "background-color": _vm.containerBackgroundColor,
          color: _vm.containerColor
        },
        on: { click: _vm.setCode }
      },
      _vm._l(_vm.codeShow, function(code) {
        return _c("span", { style: code.style }, [
          _vm._v("\n            " + _vm._s(code.char || code) + "\n        ")
        ])
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "verify-code-area", style: { width: _vm.width } },
      [
        _c("div", { staticClass: "verify-input-area" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputValue,
                expression: "inputValue"
              }
            ],
            staticClass: "varify-input-code",
            attrs: { type: "text" },
            domProps: { value: _vm.inputValue },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.inputValue = $event.target.value
              }
            }
          })
        ]),
        _c(
          "div",
          { staticClass: "verify-change-area", on: { click: _vm.setCode } },
          [_c("a", { staticClass: "verify-change-code" }, [_vm._v("换一张")])]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }