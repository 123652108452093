var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.loading,
          expression: "!loading"
        }
      ],
      staticClass: "container"
    },
    [
      !_vm.loginNum
        ? _c("div", { staticClass: "login-one" }, [
            _c("img", {
              staticClass: "title-bg",
              attrs: { src: require("../../assets/images/img/title-bg.png") }
            }),
            _vm._m(0),
            _vm._m(1),
            _c("div", { staticClass: "login-main" }, [
              _c("div", { staticClass: "login-mobile-input" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/img/mobile.png"),
                    alt: ""
                  }
                }),
                _c("input", {
                  directives: [
                    { name: "fixedInput", rawName: "v-fixedInput" },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.mobile,
                      expression: "mobile"
                    }
                  ],
                  staticClass: "fill-input",
                  attrs: {
                    maxlength: "11",
                    placeholder: "请输入手机号（已加密）" + _vm.invitationCode,
                    type: "tel",
                    onkeyup: "this.value=this.value.replace(/\\D/g,'')"
                  },
                  domProps: { value: _vm.mobile },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.mobile = $event.target.value
                    }
                  }
                })
              ]),
              !_vm.wechatLogin
                ? _c("div", { staticClass: "login-code-input" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/images/img/message.png"),
                        alt: ""
                      }
                    }),
                    _c("input", {
                      directives: [
                        { name: "fixedInput", rawName: "v-fixedInput" },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.tattedCode,
                          expression: "tattedCode"
                        }
                      ],
                      staticClass: "fill-input",
                      attrs: {
                        maxlength: "6",
                        placeholder: "短信验证码",
                        type: "number",
                        onkeyup: "this.value=this.value.replace(/\\D/g,'')"
                      },
                      domProps: { value: _vm.tattedCode },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.tattedCode = $event.target.value
                        }
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "get-code",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.showPuzzle($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.state.smsSendBtn
                              ? _vm.state.time + "s后重发"
                              : "获取验证码"
                          )
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "login-mobile-input top14",
                  class: { noedit: !_vm.isEdit }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/img/email.png"),
                      alt: ""
                    }
                  }),
                  _c("input", {
                    directives: [
                      { name: "fixedInput", rawName: "v-fixedInput" },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.invitationCode,
                        expression: "invitationCode"
                      }
                    ],
                    staticClass: "fill-input",
                    attrs: {
                      maxlength: "6",
                      placeholder: "'请填写邀请码(选填)'",
                      type: "number",
                      disabled: !_vm.isEdit,
                      onkeyup: "this.value=this.value.replace(/\\D/g,'')"
                    },
                    domProps: { value: _vm.invitationCode },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.invitationCode = $event.target.value
                      }
                    }
                  })
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "login-sumbit",
                  attrs: { disabled: _vm.submitting },
                  on: { click: _vm.handleSubmit }
                },
                [_vm._v("\n        专属额度，立即领取\n      ")]
              ),
              _c("div", { staticClass: "login-agreen" }, [
                _c("i"),
                _c("p", [
                  _vm._v(" \n          我已阅读并同意\n          "),
                  _c(
                    "a",
                    {
                      staticClass: "agreement-link",
                      on: {
                        click: function($event) {
                          _vm.toAgreementView(
                            "《用户服务协议》",
                            "https://ech5.test.jdz360.cn/agreement/豆易链服务协议.html?t=" +
                              Math.random()
                          )
                        }
                      }
                    },
                    [_vm._v("《用户服务协议》")]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "agreement-link",
                      on: {
                        click: function($event) {
                          _vm.toAgreementView(
                            "《隐私政策》",
                            "https://ech5.test.jdz360.cn/agreement/豆易链隐私政策.html?t=" +
                              Math.random()
                          )
                        }
                      }
                    },
                    [_vm._v("《隐私政策》")]
                  ),
                  _vm._v("\n          ，未注册时将自动注册账号\n        ")
                ])
              ])
            ])
          ])
        : _c("div", { staticClass: "login-two" }, [
            _c("img", {
              staticClass: "main-bg",
              attrs: {
                src: require("../../assets/images/login_two.png"),
                alt: ""
              }
            }),
            _c("span", { staticClass: "login-title" }, [
              _vm._v("HELLO，欢迎回来")
            ]),
            _c("p", { staticClass: "login-mobile" }, [
              _vm._v(_vm._s(_vm.maskPhoneNumber(_vm.mobile)))
            ]),
            _c(
              "div",
              {
                staticClass: "login-sumbit",
                attrs: { disabled: _vm.submitting },
                on: { click: _vm.handleSubmit }
              },
              [_vm._v("一键登录")]
            ),
            _c(
              "p",
              {
                staticClass: "login-change",
                on: {
                  click: function($event) {
                    _vm.loginNum = false
                  }
                }
              },
              [_vm._v("切换账号")]
            ),
            _c("div", { staticClass: "login-agreen" }, [
              _c("i"),
              _c("p", [
                _vm._v(" \n        我已阅读并同意\n      "),
                _c(
                  "a",
                  {
                    staticClass: "agreement-link",
                    on: {
                      click: function($event) {
                        _vm.toAgreementView(
                          "《用户服务协议》",
                          "https://ech5.test.jdz360.cn/agreement/豆易链服务协议.html?t=" +
                            Math.random()
                        )
                      }
                    }
                  },
                  [_vm._v("《用户服务协议》")]
                ),
                _c(
                  "a",
                  {
                    staticClass: "agreement-link",
                    on: {
                      click: function($event) {
                        _vm.toAgreementView(
                          "《隐私政策》",
                          "https://ech5.test.jdz360.cn/agreement/豆易链隐私政策.html?t=" +
                            Math.random()
                        )
                      }
                    }
                  },
                  [_vm._v("《隐私政策》")]
                ),
                _vm._v("\n       ，未注册时将自动注册账号\n      ")
              ])
            ])
          ]),
      _vm.verificationShow
        ? _c(
            "div",
            { staticClass: "puzzle-box" },
            [
              _c("verify", {
                attrs: { type: 5, width: "60%" },
                on: {
                  success: _vm.handleSuccess,
                  error: function($event) {
                    return _vm.handleError()
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "an-box" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/img/an-icon.png"), alt: "" }
      }),
      _vm._v("\n      由国家合规平台出资\n    ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-one-bg" }, [
      _c("img", {
        staticClass: "main-bg",
        attrs: {
          src: require("../../assets/images/img/login_title.png"),
          alt: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }