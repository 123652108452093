var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c("div", { staticClass: "container" }, [
        _vm._m(0),
        _c("div", { staticClass: "info-faren-main" }, [
          _c("div", { staticClass: "tips" }, [
            _vm._v("请上传以下照片或文件，照片要求清晰无反光，文件小于10M。")
          ]),
          _c(
            "div",
            { staticClass: "info-faren-content" },
            _vm._l(_vm.filesArray, function(item, index) {
              return _c("div", { key: index }, [
                item.show == 1
                  ? _c(
                      "div",
                      {
                        staticClass: "info-faren-item border-bottom-1px",
                        on: {
                          click: function($event) {
                            return _vm.toItem(item.code)
                          }
                        }
                      },
                      [
                        _c("label", { class: { on: item.need == 1 } }, [
                          _vm._v(
                            _vm._s(
                              item.name +
                                "（" +
                                (item.need == 1 ? "必传" : "可传") +
                                "）"
                            )
                          )
                        ]),
                        _c("span", [_vm._v(_vm._s(item.urlList.length))]),
                        _c("i")
                      ]
                    )
                  : _vm._e()
              ])
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "add-btn on", on: { click: _vm.commit } },
            [
              _vm.commiting ? _c("cube-loading") : _vm._e(),
              _c("span", [_vm._v("下一步")])
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-box" }, [
      _c("p", { staticClass: "title" }, [_vm._v("补充资料")]),
      _c("p", { staticClass: "desc" }, [_vm._v("Hi，请填写以下资料吧~")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }