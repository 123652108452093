<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-12-18 18:57:23
 * @LastEditors: CZK
 * @LastEditTime: 2025-01-14 15:25:55
-->
<template>
  <div class="container" v-if="!loading">
    <div class="title-box">
      <p class="title">补充资料</p>
      <p class="desc">Hi，请填写以下资料吧~</p>
    </div>

    <div class="info-faren-main">
      <div class="tips">请上传以下照片或文件，照片要求清晰无反光，文件小于{{size}}M。</div>
      <div class="info-faren-content">
        <div class="has-value" v-for="(item,index) in imgUrls" :key="index">
          <div class="upload-box-btn-main" >
            <img :src="item" v-if="returnItem.uploadType==1"/>
            <img src="@/assets/images/img/successBg.png" v-else/>
            <i @click="removeImg(index)"></i>
          </div>
          <span class="uploading-tips" :class="{on:(index===0&&imgUrls.length>0)?(returnItem.need==1):false}">{{returnItem.title}}</span>
        </div>

        <div class="no-value" v-if="imgUrls.length<8">
          <div class="upload-box-btn-main" >
            <img src="@/assets/images/img/carme.png"/>
          </div>
          <span class="uploading-tips" :class="{on:imgUrls.length==0?(returnItem.need==1):false}">{{returnItem.title}}</span>
          <OSSUpload 
            :type="returnItem.uploadType"   
            :backgroundImgName="returnItem.bg" 
            :accept="returnItem.accept"
            :need="returnItem.need"
            :maxSize="returnItem.size"
            :successBg="returnItem.successBg"
            ref="upload1"
            @success="(e)=>{return handleSuccess(e)}"
            :title="returnItem.title" 
            :files.sync="returnItem.files"
            class="upload-item"
            style="margin-bottom: 14px;"
            :multiple="returnItem.multiple"/>
          </div>
        </div>
        

      <div class="add-btn on" @click="commit"><cube-loading v-if="commiting"></cube-loading>
        <span>保存</span></div>

    </div>

  </div>
</template>
<script type="text/ecmascript-6">
import OSSUpload from "@comp/Upload/OSSUpload";
import {saveSupplyInfo,updateShopInfo,getSupplyInfo} from "@api/user";
import {findNextFlowThenJump,findNextFlowThenJump1} from "../main/findNextFlow"
export default {
  name: "additionalInformation",
  components: {
    OSSUpload
  },
  data(){
    return {
      commiting:false,
      code:'',
      loading:true,
      type:1,
      imgUrls:[],
      returnItem:null,
      filesArray:[]
    }
  },
  computed: {
    size(){
      return this.returnItem?this.returnItem.size/1024/1024:0
    }
  },
  methods: {
    removeImg(index){
      this.imgUrls.splice(index,1)
    },
    async commit(){
      if(this.commiting) return
      const {returnItem,imgUrls} = this
      let flag = false
      let tipsName = ''

      if((returnItem.need==1)&&imgUrls.length==0){
        flag = true;
        tipsName = returnItem.title
      }


     const supplementInfoList = {code:returnItem.code,urlList:this.imgUrls}

      if(flag){
        return this.$createToast({txt:`请上传${tipsName}`,type: "error"}).show();
      }

      this.commiting = true
      let res = null
      if(this.type==1) {
        res =  await saveSupplyInfo({
            ...supplementInfoList,
            token:''
          })
      }else{
        res =  await updateShopInfo({
            ...supplementInfoList,
            token:''
          })
      }

      

      if(res.success){
        this.type==1?findNextFlowThenJump():findNextFlowThenJump1()
      }

      this.commiting = false
    },
    handleSuccess(e){
      this.imgUrls.push(e.filenameUrl)
    },
    async createDom(){
      
      let res =  await getSupplyInfo({token:''})
      if(res.success){
        this.loading = false
        let findItem = res.data.filter(item=>item.code ===this.code)
        if(findItem.length>0){
            let item = findItem[0]
            item.need = parseInt(item.need)
            let imgItem =  {
              uploadType: 1,
              bg: "carme.png",
              accept: "image/png,image/jpeg,image/gif",
              title: "店铺资质(必传)",
              need:0,
              url:'',
              size: 10*1024*1024,
              multiple: false,
              files: []
            }
            let pdfItem =  {
              uploadType: 2,
              bg: "carme.png",
              accept: "application/pdf",
              title: "店铺资质(必传)",
              need:0,
              size: 100*1024*1024,
              successBg:'successBg.png',
              url:'',
              multiple: false,
              files: []
            }

            let excelItem =  {
              uploadType: 3,
              bg: "carme.png",
              accept: "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xls,.xlsx,text/csv,.csv",
              title: "店铺资质(必传)",
              need:0,
              successBg:'successBg.png',
              url:'',
              size: 100*1024*1024,
              multiple: false,
              files: []
            }
            let returnItem = null
            if(item.code=="ShopCertQualification"||item.code=="ShopScreenshot"){
              imgItem.need = item.need
              imgItem.code = item.code
              imgItem.title = `${item.name}${item.need=='1'?'(必传)' :'(选传)'}`
              returnItem = imgItem
            }else if(item.code=='CreditReport'){
              pdfItem.need = item.need
              pdfItem.code = item.code
              pdfItem.title = `${item.name}${item.need=='1'?'(必传)' :'(选传)'}`
              returnItem = pdfItem
            }else{
              excelItem.need = item.need
              excelItem.code = item.code
              excelItem.title = `${item.name}${item.need=='1'?'(必传)' :'(选传)'}`
              returnItem = excelItem
            }
            this.imgUrls = item.urlList
            this.returnItem = returnItem
        }
      }
      // const childJson = JSON.parse(json)
      // this.filesArray =  childJson.map(item=>{
      //     item.need = parseInt(item.need)
      //     let imgItem =  {
      //       uploadType: 1,
      //       bg: "carme.png",
      //       accept: "image/png,image/jpeg,image/gif",
      //       title: "店铺资质(必传)",
      //       need:0,
      //       url:'',
      //       multiple: false,
      //       files: []
      //     }
      //     let pdfItem =  {
      //       uploadType: 2,
      //       bg: "carme.png",
      //       accept: "application/pdf",
      //       title: "店铺资质(必传)",
      //       need:0,
      //       successBg:'successBg.png',
      //       url:'',
      //       multiple: false,
      //       files: []
      //     }

      //     let excelItem =  {
      //       uploadType: 3,
      //       bg: "carme.png",
      //       accept: ".xls,.xlsx,.csv",
      //       title: "店铺资质(必传)",
      //       need:0,
      //       successBg:'successBg.png',
      //       url:'',
      //       multiple: false,
      //       files: []
      //     }
      //     let returnItem = null
      //   if(item.code=="ShopCertQualification"||item.code=="ShopScreenshot"){
      //     imgItem.need = item.need
      //     imgItem.code = item.code
      //     imgItem.title = `${item.name}${item.need=='1'?'(必传)' :'(选传)'}`
      //     returnItem = imgItem
      //   }else if(item.code=='CreditReport'){
      //     pdfItem.need = item.need
      //     pdfItem.code = item.code
      //     pdfItem.title = `${item.name}${item.need=='1'?'(必传)' :'(选传)'}`
      //     returnItem = pdfItem
      //   }else{
      //     excelItem.need = item.need
      //     excelItem.code = item.code
      //     excelItem.title = `${item.name}${item.need=='1'?'(必传)' :'(选传)'}`
      //     returnItem = excelItem
      //   }
      //   return returnItem
      // })
    }
  },
  created(){
    const {code,type} = this.$route.query;
    this.code = code
    this.type = type
    this.createDom()
  }
}
</script>
<style lang="less" scoped>
.container{
width: 100%;
background:#F5F7FB url("../../assets//images/img/bg1.png") no-repeat;
background-size: contain;
.title-box{
  margin-top: 27px;
  margin-left: 32px;
  .title{
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 18px;
  }
  .desc{
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 22px;
    margin-top: 12px;
  }
}
.info-faren-main{
  width: 343px;
  display: flex;
  flex-direction: column;
  // height: 500px;
  background: #FFFFFF;
  margin: 17px auto 0;
  border-radius: 8px 8px 8px 8px;
  .info-faren-title-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;
    .select-box{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      display: flex;
      line-height: 18px;
      span{display: flex;align-items: center;}
      i{
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url("../../assets/images/img/no.png") no-repeat;
        background-size: contain;
        margin-left: 10px;
        margin-right: 10px;
        &.yes{
          background: url("../../assets/images/img/yes.png") no-repeat;
        }
      }
    }
  }
  .info-faren-title{
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    margin-left: 5px;
    color: #1C2023;
    line-height: 20px;
    &::after{
      content: '*';
      color:#FA5151;
    }
  }
  .info-faren-content{
    display: flex;
    margin-top: 14px;
    padding: 0 12px;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    .no-value{
      width: 154px;
      height: 134px;
      border-radius: 8px 8px 8px 8px;
      border: 2px dashed #C0DBFF;
      overflow:hidden;
      position: relative;
      .upload-item{
        width:100%;
        height:100%;
        
        position: absolute;
        left:0;
        top:0;
        opacity:0;
        z-index:1;
      }
      .upload-box-btn-main{
        width: 100%;
        height: 104px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img{
          width:100%;
          height:100%;
          object-fit:contain;
        }
      }
      .uploading-tips{
        width: 100%;
        text-align: center;
        height: calc(134px - 104px);
        display: block;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #3399FF;
        line-height: calc(134px - 104px);
        background: #DEEFFF;
        border-radius: 0px 0px 8px 8px;
        &.on{
          &::before{
            content: "*";
            color: #FA5151 ;
          }
        }
      }
    }
    .has-value{
      width: 154px;
      height: 134px;
      border-radius: 8px 8px 8px 8px;
      border: 2px dashed #C0DBFF;
      margin-bottom:14px;
      .upload-box-btn-main{
        width: 100%;
        height: 104px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img{
          width:100%;
          height:100%;
          object-fit:contain;
        }
        i{
          width:30px;
          height:20px;
          display:block;
          position: absolute;
          right:0;
          top:0;
          background:url('../../assets/images/img/del.png') no-repeat;
          background-size:contain;
        }
      }
      .uploading-tips{
        width: 100%;
        text-align: center;
        height: calc(134px - 104px);
        display: block;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #3399FF;
        line-height: calc(134px - 104px);
        background: #DEEFFF;
        border-radius: 0px 0px 8px 8px;
        &.on{
          &::before{
            content: "*";
            color: #FA5151 ;
          }
        }
      }
    }
  }
  .tips{
    width: 100%;
    height: 60px;
    background: #FFFBE1;
    border-radius: 8px 8px 0px 0px;
    padding: 17px 10px;
    text-align: left;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #FFA54C;
  }
  .add-btn{
    width: 321px;
    height: 42px;
    text-align: center;
    background: #CCCCCC;
    border-radius: 30px 30px 30px 30px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 42px;
    margin: 12px auto 12px;
    color: #FFFFFF;
    &.on{
      background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
      margin: 10px auto 16px;
    }
  }
}
}
</style>