var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "t" }, [_vm._v("手 机 号")]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.mobile,
            expression: "form.mobile"
          }
        ],
        attrs: { type: "text", maxlength: "11", placeholder: "请输入手机号" },
        domProps: { value: _vm.form.mobile },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.form, "mobile", $event.target.value)
          }
        }
      })
    ]),
    _c("div", { staticClass: "row" }, [
      _c("label", { staticClass: "t" }, [_vm._v("验 证 码")]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.authCode,
            expression: "form.authCode"
          }
        ],
        attrs: { type: "text", maxlength: "11", placeholder: "请输入验证码" },
        domProps: { value: _vm.form.authCode },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.form, "authCode", $event.target.value)
          }
        }
      }),
      _c(
        "div",
        {
          staticClass: "get-code",
          on: {
            click: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.getCaptcha($event)
            }
          }
        },
        [
          _vm._v(
            _vm._s(
              _vm.state.smsSendBtn ? _vm.state.time + "s后重发" : "获取验证码"
            )
          )
        ]
      )
    ]),
    _c(
      "button",
      { staticClass: "btn1 btn-primary", on: { click: _vm.commit } },
      [_vm._v("确定")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }