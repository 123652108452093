var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _c("div", { staticClass: "info-faren-main" }, [
      _vm._m(1),
      _c(
        "div",
        { staticClass: "info-faren-content" },
        _vm._l(_vm.faren.idCard, function(item, index) {
          return _c("OSSUpload", {
            key: index,
            ref: "upload1",
            refInFor: true,
            attrs: {
              type: item.uploadType,
              backgroundImgName: item.bg,
              accept: item.accept,
              maxSize: 2 * 1024 * 1024,
              title: item.title,
              files: item.files,
              multiple: item.multiple
            },
            on: {
              success: function(e) {
                return _vm.handleSuccess(e, "faren", index)
              },
              "update:files": function($event) {
                return _vm.$set(item, "files", $event)
              }
            }
          })
        }),
        1
      ),
      _vm.faren.identityFrontUrl || _vm.faren.identityReverseUrl
        ? _c("div", { staticClass: "tips" }, [
            _vm._v("请确认以下信息是否正确，如有误请重新识别")
          ])
        : _vm._e(),
      _vm.faren.identityFrontUrl || _vm.faren.identityReverseUrl
        ? _c("div", { staticClass: "faren-form" }, [
            _vm.faren.name
              ? _c("div", { staticClass: "form-item border-bottom-1px" }, [
                  _c("label", [_vm._v("真实姓名")]),
                  _c("span", [_vm._v(_vm._s(_vm.faren.name))])
                ])
              : _vm._e(),
            _vm.faren.idCardNo
              ? _c("div", { staticClass: "form-item border-bottom-1px" }, [
                  _c("label", [_vm._v("身份证号")]),
                  _c("span", [_vm._v(_vm._s(_vm.faren.idCardNo))])
                ])
              : _vm._e(),
            _vm.faren.signIssued
              ? _c("div", { staticClass: "form-item border-bottom-1px" }, [
                  _c("label", [_vm._v("签发机关")]),
                  _c("span", [_vm._v(_vm._s(_vm.faren.signIssued))])
                ])
              : _vm._e(),
            _vm.faren.effectiveStartDate
              ? _c("div", { staticClass: "form-item border-bottom-1px" }, [
                  _c("label", [_vm._v("有效期限")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.faren.effectiveStartDate) +
                        "-" +
                        _vm._s(_vm.faren.effectiveEndDate)
                    )
                  ])
                ])
              : _vm._e(),
            _vm.faren.identityFrontUrl && _vm.faren.identityReverseUrl
              ? _c("div", { staticClass: "form-item border-bottom-1px" }, [
                  _c("label", { staticClass: "on" }, [_vm._v("联系电话")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.faren.phone,
                        expression: "faren.phone"
                      }
                    ],
                    attrs: {
                      type: "text",
                      maxlength: 11,
                      disabled: _vm.faren.contactStatus == 1,
                      placeholder: "请填写法人实名手机号"
                    },
                    domProps: { value: _vm.faren.phone },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.faren, "phone", $event.target.value)
                      }
                    }
                  }),
                  _c("div", { staticClass: "select-box" }, [
                    _c("span", { on: { click: _vm.changeFaren } }, [
                      _c("i", {
                        class: {
                          yes: _vm.faren.contactStatus == 1 || _vm.isFaSame
                        }
                      }),
                      _vm._v("同本人")
                    ])
                  ])
                ])
              : _vm._e(),
            !_vm.isFaSame &&
            _vm.faren.identityFrontUrl &&
            _vm.faren.identityReverseUrl
              ? _c("div", { staticClass: "form-item border-bottom-1px" }, [
                  _c("label", { staticClass: "on" }, [_vm._v("验证码")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.legalAuthCode,
                        expression: "legalAuthCode"
                      }
                    ],
                    attrs: {
                      type: "text",
                      maxlength: 6,
                      placeholder: "验证码"
                    },
                    domProps: { value: _vm.legalAuthCode },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.legalAuthCode = $event.target.value
                      }
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "get-code",
                      class: { on: !_vm.state1.smsSendBtn },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.getCaptcha1($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.state1.smsSendBtn
                            ? _vm.state1.time + "s后重发"
                            : "获取验证码"
                        )
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm.faren.identityFrontUrl && _vm.faren.identityReverseUrl
              ? _c("div", { staticClass: "form-item border-bottom-1px" }, [
                  _c("label", { staticClass: "on" }, [_vm._v("电子邮箱")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.faren.email,
                        expression: "faren.email"
                      }
                    ],
                    attrs: { type: "text", placeholder: "请填写电子邮箱" },
                    domProps: { value: _vm.faren.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.faren, "email", $event.target.value)
                      }
                    }
                  })
                ])
              : _vm._e()
          ])
        : _vm._e()
    ]),
    _c("div", { staticClass: "info-faren-main" }, [
      _c("div", { staticClass: "info-faren-title-box" }, [
        _c("p", { staticClass: "info-faren-title on" }, [
          _vm._v("企业联系人信息")
        ]),
        _c("div", { staticClass: "select-box" }, [
          _c("span", { on: { click: _vm.changeLianxiren } }, [
            _c("i", { class: { yes: _vm.lianxiren.contactStatus == 1 } }),
            _vm._v("同法人")
          ])
        ])
      ]),
      _vm.lianxiren.contactStatus != 1
        ? _c("div", [
            _c(
              "div",
              { staticClass: "info-faren-content" },
              _vm._l(_vm.lianxiren.idCard, function(item, index) {
                return _c("OSSUpload", {
                  key: index,
                  ref: "upload1",
                  refInFor: true,
                  attrs: {
                    type: item.uploadType,
                    backgroundImgName: item.bg,
                    accept: item.accept,
                    maxSize: 2 * 1024 * 1024,
                    title: item.title,
                    files: item.files,
                    multiple: item.multiple
                  },
                  on: {
                    success: function(e) {
                      return _vm.handleSuccess(e, "lianxiren", index)
                    },
                    "update:files": function($event) {
                      return _vm.$set(item, "files", $event)
                    }
                  }
                })
              }),
              1
            ),
            _vm.lianxiren.identityFrontUrl || _vm.lianxiren.identityReverseUrl
              ? _c("div", { staticClass: "tips" }, [
                  _vm._v("请确认以下信息是否正确，如有误请重新识别")
                ])
              : _vm._e(),
            _vm.lianxiren.identityFrontUrl || _vm.lianxiren.identityReverseUrl
              ? _c("div", { staticClass: "faren-form" }, [
                  _vm.lianxiren.name
                    ? _c(
                        "div",
                        { staticClass: "form-item border-bottom-1px" },
                        [
                          _c("label", [_vm._v("真实姓名")]),
                          _c("span", [_vm._v(_vm._s(_vm.lianxiren.name))])
                        ]
                      )
                    : _vm._e(),
                  _vm.lianxiren.idCardNo
                    ? _c(
                        "div",
                        { staticClass: "form-item border-bottom-1px" },
                        [
                          _c("label", [_vm._v("身份证号")]),
                          _c("span", [_vm._v(_vm._s(_vm.lianxiren.idCardNo))])
                        ]
                      )
                    : _vm._e(),
                  _vm.lianxiren.signIssued
                    ? _c(
                        "div",
                        { staticClass: "form-item border-bottom-1px" },
                        [
                          _c("label", [_vm._v("签发机关")]),
                          _c("span", [_vm._v(_vm._s(_vm.lianxiren.signIssued))])
                        ]
                      )
                    : _vm._e(),
                  _vm.lianxiren.effectiveStartDate
                    ? _c(
                        "div",
                        { staticClass: "form-item border-bottom-1px" },
                        [
                          _c("label", [_vm._v("有效期限")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.lianxiren.effectiveStartDate) +
                                "-" +
                                _vm._s(_vm.lianxiren.effectiveEndDate)
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.lianxiren.identityFrontUrl &&
                  _vm.lianxiren.identityReverseUrl
                    ? _c(
                        "div",
                        { staticClass: "form-item border-bottom-1px" },
                        [
                          _c("label", { staticClass: "on" }, [
                            _vm._v("联系电话")
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.lianxiren.phone,
                                expression: "lianxiren.phone"
                              }
                            ],
                            attrs: {
                              type: "text",
                              maxlength: 11,
                              placeholder: "请填写联系人实名手机号"
                            },
                            domProps: { value: _vm.lianxiren.phone },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.lianxiren,
                                  "phone",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _c("div", { staticClass: "select-box" }, [
                            _c("span", { on: { click: _vm.changeFaren1 } }, [
                              _c("i", {
                                class: {
                                  yes:
                                    _vm.lianxiren.personStatus == 1 ||
                                    _vm.isSame
                                }
                              }),
                              _vm._v("同本人")
                            ])
                          ])
                        ]
                      )
                    : _vm._e(),
                  !_vm.isSame &&
                  _vm.lianxiren.identityFrontUrl &&
                  _vm.lianxiren.identityReverseUrl
                    ? _c(
                        "div",
                        { staticClass: "form-item border-bottom-1px" },
                        [
                          _c("label", { staticClass: "on" }, [
                            _vm._v("验证码")
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.authCode,
                                expression: "authCode"
                              }
                            ],
                            attrs: {
                              type: "text",
                              maxlength: 6,
                              placeholder: "验证码"
                            },
                            domProps: { value: _vm.authCode },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.authCode = $event.target.value
                              }
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "get-code",
                              class: { on: !_vm.state.smsSendBtn },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.getCaptcha($event)
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.state.smsSendBtn
                                    ? _vm.state.time + "s后重发"
                                    : "获取验证码"
                                )
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.lianxiren.identityFrontUrl &&
                  _vm.lianxiren.identityReverseUrl
                    ? _c(
                        "div",
                        { staticClass: "form-item border-bottom-1px" },
                        [
                          _c("label", { staticClass: "on" }, [
                            _vm._v("电子邮箱")
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.lianxiren.email,
                                expression: "lianxiren.email"
                              }
                            ],
                            attrs: {
                              type: "text",
                              placeholder: "请填写电子邮箱"
                            },
                            domProps: { value: _vm.lianxiren.email },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.lianxiren,
                                  "email",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ])
        : _vm._e()
    ]),
    _c(
      "div",
      { staticClass: "add-btn on", on: { click: _vm.commit } },
      [
        _vm.commiting ? _c("cube-loading") : _vm._e(),
        _c("span", [_vm._v("下一步")])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-box" }, [
      _c("p", { staticClass: "title" }, [_vm._v("企业补充信息")]),
      _c("p", { staticClass: "desc" }, [_vm._v("Hi，请填写以下资料吧~")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-faren-title-box" }, [
      _c("p", { staticClass: "info-faren-title on" }, [_vm._v("企业法人信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }