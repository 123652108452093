var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "header-row" }, [
          _c("div", { staticClass: "header-left" }, [
            _c("img", {
              attrs: { src: require("../../assets/images/avatar-default.png") }
            }),
            _c("span", [_vm._v(_vm._s(_vm.name))])
          ]),
          _c("div", { staticClass: "header-right" }, [
            _vm.token
              ? _c("img", {
                  staticClass: "r",
                  attrs: {
                    src: require("../../assets/images/img/setting_icon.png")
                  },
                  on: {
                    click: function($event) {
                      return _vm.toMenu("setting")
                    }
                  }
                })
              : _vm._e(),
            _c("img", {
              attrs: { src: require("../../assets/images/img/cutomer.png") }
            })
          ])
        ]),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "main-box" }, [
            _vm.leagueInfo.leagueId
              ? _c("p", { staticClass: "main-title" }, [
                  _vm._v("可用额度（元）")
                ])
              : _c("p", { staticClass: "main-title" }, [
                  _vm._v("最高可申请金额（元）")
                ]),
            _c("div", { staticClass: "main-box-content" }, [
              _vm.leagueInfo.leagueId
                ? _c("span", { staticClass: "main-money" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatCurrency")(
                          _vm.leagueInfo.useLoanAmount || 0
                        )
                      )
                    )
                  ])
                : _c("span", { staticClass: "main-money" }, [
                    _vm._v(_vm._s(_vm._f("formatCurrency")(10000000)))
                  ]),
              _c(
                "span",
                {
                  staticClass: "main-btn",
                  class: { btn5: !_vm.isUsebtn },
                  on: {
                    click: function($event) {
                      return _vm.apply()
                    }
                  }
                },
                [
                  _vm._v("\n          " + _vm._s(_vm.btnName) + "\n          "),
                  _vm.isTip ? _c("i", [_vm._v(_vm._s(_vm.btnDesc))]) : _vm._e()
                ]
              )
            ]),
            _vm.leagueInfo.leagueId
              ? _c("p", { staticClass: "main-desc-2" }, [
                  _vm._v(
                    "总额度" +
                      _vm._s(
                        _vm._f("formatCurrency")(_vm.totalLoanAmount || 0)
                      ) +
                      "元\n        "
                  )
                ])
              : _c("p", { staticClass: "main-desc" }, [
                  _vm._v("透明｜合规｜安全｜高效")
                ])
          ]),
          _vm._m(0),
          _c("div", { staticClass: "common-service" }, [
            _c("p", { staticClass: "common-service-title" }, [
              _vm._v("常用服务")
            ]),
            _c("div", { staticClass: "common-service-content" }, [
              _c(
                "div",
                {
                  staticClass: "common-service-item",
                  on: {
                    click: function($event) {
                      return _vm.toMenu("applyRecode")
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/img/menu01.png")
                    }
                  }),
                  _c("p", [_vm._v("申请记录")])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "common-service-item",
                  on: {
                    click: function($event) {
                      return _vm.toMenu("mine")
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/img/menu02.png")
                    }
                  }),
                  _c("p", [_vm._v("还款记录")])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "common-service-item",
                  on: {
                    click: function($event) {
                      return _vm.toMenu("accountMangement")
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/img/menu03.png")
                    }
                  }),
                  _c("p", [_vm._v("账户管理")])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "common-service-item",
                  on: {
                    click: function($event) {
                      return _vm.toMenu("dataMangement")
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/img/menu04.png")
                    }
                  }),
                  _c("p", [_vm._v("资料管理")])
                ]
              )
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "line-items mgt16",
              on: {
                click: function($event) {
                  return _vm.toIntro("使用须知", "useKnow")
                }
              }
            },
            [_vm._m(1), _c("i")]
          ),
          _c(
            "div",
            {
              staticClass: "line-items mgt10",
              on: {
                click: function($event) {
                  return _vm.toIntro("帮助中心", "problem")
                }
              }
            },
            [_vm._m(2), _c("i")]
          ),
          _c(
            "div",
            {
              staticClass: "line-items mgt10",
              on: {
                click: function($event) {
                  return _vm.toIntro("产品介绍", "intro")
                }
              }
            },
            [_vm._m(3), _c("i")]
          ),
          _c(
            "div",
            {
              staticClass: "line-items mgt10",
              on: {
                click: function($event) {
                  return _vm.toIntro("关于我们", "aboutUs")
                }
              }
            },
            [_vm._m(4), _c("i")]
          )
        ]),
        _c("img", {
          staticClass: "beian",
          attrs: { src: require("../../assets/images/img/beian.png") }
        })
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "broadcast" }, [
      _c("i"),
      _c("div", { staticClass: "broadcast-content" }, [
        _c("div", { staticClass: "broadcast-title" }, [
          _c("span", [
            _vm._v(
              "感谢您的支持与关注！请留下您的【联系方式+企业名称+称呼】发送至【豆易链】微信公众号，我们会第一时间安排业务经理与您对接！"
            )
          ]),
          _c("span", [
            _vm._v(
              "感谢您的支持与关注！请留下您的【联系方式+企业名称+称呼】发送至【豆易链】微信公众号，我们会第一时间安排业务经理与您对接！"
            )
          ]),
          _c("span", [
            _vm._v(
              "感谢您的支持与关注！请留下您的【联系方式+企业名称+称呼】发送至【豆易链】微信公众号，我们会第一时间安排业务经理与您对接！"
            )
          ]),
          _c("span", [
            _vm._v(
              "感谢您的支持与关注！请留下您的【联系方式+企业名称+称呼】发送至【豆易链】微信公众号，我们会第一时间安排业务经理与您对接！"
            )
          ]),
          _c("span", [
            _vm._v(
              "感谢您的支持与关注！请留下您的【联系方式+企业名称+称呼】发送至【豆易链】微信公众号，我们会第一时间安排业务经理与您对接！"
            )
          ]),
          _c("span", [
            _vm._v(
              "感谢您的支持与关注！请留下您的【联系方式+企业名称+称呼】发送至【豆易链】微信公众号，我们会第一时间安排业务经理与您对接！"
            )
          ]),
          _c("span", [
            _vm._v(
              "感谢您的支持与关注！请留下您的【联系方式+企业名称+称呼】发送至【豆易链】微信公众号，我们会第一时间安排业务经理与您对接！"
            )
          ]),
          _c("span", [
            _vm._v(
              "感谢您的支持与关注！请留下您的【联系方式+企业名称+称呼】发送至【豆易链】微信公众号，我们会第一时间安排业务经理与您对接！"
            )
          ]),
          _c("span", [
            _vm._v(
              "感谢您的支持与关注！请留下您的【联系方式+企业名称+称呼】发送至【豆易链】微信公众号，我们会第一时间安排业务经理与您对接！"
            )
          ]),
          _c("span", [
            _vm._v(
              "感谢您的支持与关注！请留下您的【联系方式+企业名称+称呼】发送至【豆易链】微信公众号，我们会第一时间安排业务经理与您对接！"
            )
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line-item" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/img/menu05.png") }
      }),
      _c("span", [_vm._v("使用须知")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line-item" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/img/menu06.png") }
      }),
      _c("span", [_vm._v("帮助中心")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line-item" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/img/menu07.png") }
      }),
      _c("span", [_vm._v("产品介绍")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line-item" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/img/menu08.png") }
      }),
      _c("span", [_vm._v("关于我们")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }