<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-12-13 10:15:47
 * @LastEditors: CZK
 * @LastEditTime: 2024-12-25 10:09:18
-->
<template>
  <div class="container" v-if="!loading">
    <div class="title-box">
      <p class="title">企业开户</p>
      <p class="desc">请填写以下资料吧~</p>
    </div>

    <div class="info-faren-main">
      <div class="body-box">
        <div class="info-faren-title-box border-bottom-1px">
          <p class="info-faren-title">企业对公账户</p>
        </div>

        <!-- <div class='upload-company-box border-bottom-1px'>
          <div class="upload-company-show-image">
            <div class="default-image" @click="see(form.businessLicenseUrl)" v-if="form.businessLicenseUrl">
              <img :src="form.businessLicenseUrl" class="d"/>
            </div>

            <div class="default-image" v-else>
              <img src="../../assets/images/img/d-img.jpg" class="d"/>
              <div class="see-btn" @click="see()">
                <img src="../../assets/images/img/see.png"/>
                <span>查看样本</span>
              </div>
            </div>

            
          </div>

          <div class="upload-company-btn ">
            <OSSUpload 
            :type="form.idCard.uploadType"
            :backgroundImgName="form.idCard.bg" 
            :accept="form.idCard.accept"
            ref="upload1" 
            class="ossupload"
            :classType="true"
            :title="form.idCard.title"
            @success="(e)=>{return getCompanyUrl(e,'form')}"
            :files.sync="form.idCard.files" 
            :multiple="form.idCard.multiple"/>
            <button @click="upload" class="on">上传</button>
          </div>
        </div> -->
        <!-- <div class="tips">请确认以下信息是否正确，如有误请重新识别</div> -->

        <div class="faren-form" v-if="incomeAccount">
          <div class="form-item border-bottom-1px">
            <label>账&nbsp;户&nbsp;名</label>
            <span>{{incomeAccount.accountName}}</span>
          </div>
          <div class="form-item border-bottom-1px">
            <label>账&nbsp;户&nbsp;号</label>
            <span>{{incomeAccount.bankNo}}</span>
          </div>
          <div class="form-item border-bottom-1px">
            <label>开&nbsp;户&nbsp;行</label>
            <span>{{incomeAccount.bankName}}</span>
          </div>
          <div class="form-item border-bottom-1px">
            <label>所属支行</label>
            <span>{{incomeAccount.openBranchName}}</span>
          </div>
        </div>
      </div>
    </div>


    <div class="info-faren-main">
      <div class="body-box">
        <div v-if="!legalPersonStatus">
          <div class="info-faren-title-box border-bottom-1px">
            <p class="info-faren-title">授权信息</p>
          </div>
          <div class="faren-form">
            <div class="form-item1">
              <label>经办授权书</label>
              <span class="down" @click='loadDown'>下载模版 <i></i></span>
            </div>
              <div class="form-item1">
                <div class="empty-img" v-if="form.grantUrl" @click="see(form.grantUrl)">
                  <img :src="form.grantUrl"/>
                </div>
                <div class="empty-img" v-else>
                  <img src="../../assets/images/img/add_d.png" alt="">
                  <OSSUpload 
                    :type="file.uploadType"   
                    :backgroundImgName="file.bg" 
                    :accept="file.accept"
                    ref="upload1" 
                    class="ossupload"
                    :title="file.title"
                    :calssType="true"
                    @success="(e)=>{return handleSuccess(e)}"
                    :files.sync="file.files" 
                    :multiple="file.multiple"/>
                </div>
                
              <div>
                
              </div>
            </div>
          </div>
        </div>
        <div class="agreement-box">
              <i></i>
              <p>本人已阅读并同意
                <a @click="toAgreementView('新网银行好惠通业务服务协议','docx')"> 《新网银行好惠通业务服务协议》</a>、
                <a @click="toAgreementView('个人信息查询及使用授权书','docx')">《个人信息查询及使用授权书》</a>、
                <a @click="toAgreementView('企业信息查询及使用授权书','docx')">《企业信息查询及使用授权书》</a>、
                <a @click="toAgreementView('数字证书及电子签名授权委托书','docx')">《数字证书及电子签名授权委托书》</a>、
                <a @click="toAgreementView('新网银行好惠通业务免校验交易服务协议','pdf')">《新网银行好惠通业务免校验交易服务协议》</a>
              </p>
            </div>

            
        <div class="add-btn on"  @click="commit">
          <cube-loading v-if="commiting"></cube-loading>
          <span>确认开立监管户</span>
        </div>

      </div>
    </div>

    <!-- 二次确认弹窗 -->
    <transition name="cube-action-sheet-fade">
        <cube-popup
          type="repayment-confrim"
          position="center"
          :visible="isConfromVisibleRpMask"
          @mask-click="isConfromVisibleRpMask=false"
        >
          <transition name="cube-action-sheet-move">
            <div class="confrim-content" v-show="isConfromVisibleRpMask" @click.stop>
              <p class="title">提示</p>
              <p class="desc">你尚未添加企业对公账户（收款账户），请先添加</p>
              <div class="footer-box">
                <span class="btn two" @click="cancelRpMask">暂不添加</span>
                <span class="btn main" @click="add">去添加</span>
              </div>
            </div>
          </transition>
        </cube-popup>
      </transition>
  </div>
</template>
<script type="text/ecmascript-6">
import OSSUpload from "@comp/Upload/OSSUpload";
import { resetImageBlob } from "@/utils/getOssImageBlobUrl";
// import fontCard from '@comp/fontCard/index'
import {findNextFlowThenJump1} from "./findNextFlow"
import moment from "moment";
import {getCompanyIncomingIncomeAccount,saveCorporateBankInfo} from "@api/user";
export default {
  name: "SupplementaryInformation",
  components: {
    // fontCard,
    OSSUpload
  },
  // 受益方式 1-直接或间接25%(含)以上 2-通过人事、财务等其他方对公司进行控制 3-高级管理员 4-法人或公司负责人 5-其他
  data(){
    return {
      isConfromVisibleRpMask:true,
      form:{
        "grantUrl": "",
        "token": "",
      },
      loading:true,
      file:{
        uploadType: 1,
        bg: "font_bg.png",
        accept: "image/png,image/jpeg,image/gif",
        // accept: ".xls,.xlsx,.csv",
        title: "上传授权书",
        multiple: false,
        files: []
      },
      incomeAccount:null,
      legalPersonStatus:true,
      identify:false,
      commiting:false
    }
  },
  methods: {
    toAgreementView(name, unit) {

     const urlPath = `https://ech5.test.jdz360.cn/agreement/${name}.${unit}?t=${Math.random()}`
      this.$router.push({
        name: "AgreementView",
        query: {
          protocolName:name,
          url:urlPath
        }
      });
    },
    loadDown(){
      window.open(`https://ech5.test.jdz360.cn/agreement/经办人授权委托书.doc?t=${Math.random()}`)
    },
    cancelRpMask(){
      this.$router.back()
    },
    add(){
      this.$router.push('receivingAccount')
    },
    async getInfo(){
     let res =  await getCompanyIncomingIncomeAccount({token:""})
     if(res.success){
        this.incomeAccount= res.data.incomeAccount
        this.isConfromVisibleRpMask = !this.incomeAccount
        this.legalPersonStatus = res.data.legalPersonStatus == '1'?true:false
        this.loading = false
     }
    },
    see(url){
      const d_img = require('../../assets/images/img/d-img.jpg')
      url=url||d_img
      this.$createImagePreview({
        imgs: [url]
      }).show()
    },
    getCompanyUrl(e,KeyName,index){
      const {filenameUrl}=e
      this[KeyName].businessLicenseUrl=filenameUrl
      this.identify= false
      this.upload()
    },
    async upload(){

      // console.log(new Date('2017-12-12'),'时间')
      // return
      if(!this.form.businessLicenseUrl) return
      const toast = this.$createToast({txt: '识别中...',type: "loading",mask:true,time:999});
      toast.show()
      let res =  await recognizeBusinessLicense({businessLicenseUrl:this.form.businessLicenseUrl,token:''})
      if(res.success){
        const{data} = res
        const {businessAddress,businessScope,companyForm,companyName,companyType,creditCode,issueDate,
          legalPerson,registeredCapital,registrationDate,title,validFromDate,validPeriod,validToDate} = data

          /**
           * address": "",
        "businessLicenseUrl": "",
        "creditCode": "",
        "enterpriseType": "",
        "foundTime": "",
        "name": "",
        "sendTime": "",
        "shortName": "",
           */

           this.form.address = businessAddress
           this.form.creditCode = creditCode
           this.form.enterpriseType = companyType
           this.form.foundTime = registrationDate
          //  this.form.foundTime = moment().format('YYYY-MM-DD')
           this.form.name = companyName
           this.identify= true
           setTimeout(() => {
            toast.hide()
           },1000)
        }
    },
    //法人 联系人
    async handleSuccess(e){
      if(e.status == 'success'){
        const {filenameUrl}=e
        this.form.grantUrl = filenameUrl
      }else{
        this.$createToast({txt: '上传失败，请重试~',type: "error"}).show();
      }
    },
    async commit(){
      if(this.commiting) return;
      
      if(!this.incomeAccount){
        if(!this.form.grantUrl) return this.$createToast({txt: '请上传授权书',type: "error"}).show();
      }

      this.commiting = true
     let res =  await saveCorporateBankInfo({
        ...this.form,token:''
      })

      if(res.success){
        findNextFlowThenJump1()
      }
      this.commiting = false
    }
  },
  created(){
    this.getInfo()
  }
}
</script>
<style lang="less" scoped>
input{
all: unset;
}
.container{
width: 100%;
background:#F5F7FB url("../../assets//images/img/bg1.png") no-repeat;
background-size: contain;
.agreement-box{
    display: flex;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    line-height: 18px;
    margin: 16px 0;
    text-align: justify;
    a{
      color:#3399FF;
    }
    i{
      display: block;
      width: 18px;
      height: 18px;
      margin-right: 8px;
      flex-shrink: 0;
      background: url("../../assets/images/img/check_on.png") no-repeat;
      background-size: contain;
    }
  }
.title-box{
  margin-top: 27px;
  margin-left: 32px;
  .title{
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 18px;
  }
  .desc{
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
   
    line-height: 22px;
    margin-top: 12px;
  }
}
.info-faren-main{
  width: 343px;
  // height: 500px;
  background: #FFFFFF;
  margin: 17px auto 0;
  border-radius: 8px 8px 8px 8px;
  // padding: 14px 12px 12px;
  .upload-company-box{
    display:flex;
    flex-direction: column;
    align-items: center;
    // padding-bottom: 14px;
    .upload-company-btn{
      position: relative;
      width: 321px;
      height: 42px;
      display:flex;
      flex-direction: column;
      align-items: center;
      margin: 14px auto 14px;
      .ossupload{
        width:100%;
        height:100%;
        position:absolute;
        left:0;
        opacity: 0;
        top:0;
      }
      button{
        all:unset;
        width: 321px;
        height: 42px;
        text-align: center;
        background: #CCCCCC;
        border-radius: 30px 30px 30px 30px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 42px;
        color: #FFFFFF;
        &.on{
          background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
          margin: 0 auto 0;
        }
      }
    }
    .upload-company-show-image{
      width: 321px;
      height: 124px;
      border-radius: 8px 8px 8px 8px;
      border: 2px dashed #C0DBFF;
      display:flex;
      justify-content:center;
      align-items: center;
      .default-image{
        width:159px;
        height:107px;
        overflow:hidden;
        display:flex;
        justify-content:center;
        align-items: center;
        position:relative;
        .d{
          width:100%;
          height:100%;
          object-fit:cover;
        }
        .see-btn{
          display:flex;
          flex-direction: column;
          justify-content:center;
          align-items: center;
          position:absolute;
          img{
            width:40px;
            height:40px;
            object-fit:contain;
          }
          span{
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #3399FF;
            line-height: 18px;
            margin-top:6px;
          }
        }
      }
    }
  }
  
  .tips{
    width: 100%;
    height: 60px;
    background: #FFFBE1;
    border-radius: 8px 8px 0px 0px;
    padding: 17px 12px;
    text-align: left;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #FFA54C;
    text-align: left;
    line-height:1.2;
  }
  .body-box{
    padding: 14px 12px 12px;
  }
  .info-faren-title-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 13px;
    .select-box{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      display: flex;
      line-height: 18px;
      span{display: flex;align-items: center;}
      i{
        display: inline-block;
        width: 16px;
        height: 16px;
        background: url("../../assets/images/img/no.png") no-repeat;
        background-size: contain;
        margin-left: 10px;
        margin-right: 10px;
        &.yes{
          background: url("../../assets/images/img/yes.png") no-repeat;
          background-size: contain;
        }
      }
    }
  }
  .info-faren-title{
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    margin-left: 5px;
    color: #1C2023;
    line-height: 20px;
    &.on{
      &::after{
        content: '*';
        color:#FA5151;
      }
    }
    
  }
  .info-faren-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    button{
      all:unset;
      width: 321px;
      height: 42px;
      text-align: center;
      background: #CCCCCC;
      border-radius: 30px 30px 30px 30px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 42px;
      margin: 12px auto 0;
      color: #FFFFFF;
      &.on{
        background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
        margin: 10px auto 16px;
      }
    }
    
  }
  // .tips{
  //   width: 321px;
  //   height: 40px;
  //   background: #FFFBE1;
  //   border-radius: 8px 8px 8px 8px;
  //   padding: 10px 0 10px 6px;
  //   text-align: left;
  //   font-family: PingFang SC, PingFang SC;
  //   font-weight: 500;
  //   font-size: 14px;
  //   margin: 14px 0;
  //   color: #FFA54C;
  // }
  .faren-form{
    .form-item1{
      width: 100%;
      display: flex;
      justify-content: space-between;
      min-height: 50px;
      label{
        width: 70px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        flex-shrink: 0;
        color: #666666;
        margin-left: 6px;
        line-height: 50px;
        margin-right: 32px;
      }
      .empty-img{
        width: 50px;
        margin-left: 6px;
        height: 50px;
        position:relative;
        overflow: hidden;
        .ossupload{
          width:100%;
          height:100%;
          position:absolute;
          left: 0;
          top: 0;
          opacity: 0;
        }
        img{
          width:100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .down{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #3399FF;
        line-height: 18px;
        display:flex;
        align-items: center;
        i{
          display: inline-block;
          width: 24px;
          height: 24px;
          background: url("../../assets/images/img/blue_right.png") no-repeat;
          background-size: contain;
        }
      }
      .types-box{
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        line-height: 18px;
        margin-top: 16px;
        p{
          display: flex;
          margin-bottom: 11px;
        }
        i{
          display: inline-block;
          width: 16px;
          height: 16px;
          flex-shrink: 0;
          background: url("../../assets/images/img/no.png") no-repeat;
          background-size: contain;
          margin-right: 10px;
          &.yes{
            background: url("../../assets/images/img/yes.png") no-repeat;
            background-size: contain;
          }
        }
      }
    }
    .form-item{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      label{
        width: 76px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        flex-shrink: 0;
        color: #666666;
        margin-left: 6px;
        line-height: 20px;
        margin-right: 32px;
        
        &.on{
          position:relative;
          &::after{
            content: '*';
            position:absolute;
            right:-6px;
            top:0;
            color:#FA5151;
          }
        }
      }
      input{
        flex: 1;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        &::placeholder{
          color: #999999;
        }
      }
      span{
        flex: 1;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
      }
    }
  }
  
}

.add-btn{
    width: 321px;
    height: 42px;
    text-align: center;
    background: #CCCCCC;
    border-radius: 30px 30px 30px 30px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 42px;
    margin: 12px auto 0;
    color: #FFFFFF;
    &.on{
      background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
      margin: 10px auto 16px;
    }
  }


  .confrim-content{
    width: 316px;
    height: 178px;
    background: #FFFFFF;
    display:flex;
    flex-direction: column;
    border-radius: 8px 8px 8px 8px;
    .title{
      text-align:center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #3D3D3D;
      margin-top:22px;
    }
    .desc{
      text-align:center;
      font-family: PingFangSC-Medium, PingFangSC-Medium;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      padding: 0 45px;
      color: #353535;
      margin-top:14px;
    }
    .footer-box{
      margin-top:25px;
      display:flex;
      justify-content: space-around;
      .btn{
        width: 108px;
        height: 36px;
        text-align:center;
        line-height:36px;
        font-family: PingFangSC-Semibold, PingFangSC-Semibold;
        font-weight: 400;
        font-size: 16px;
        border-radius: 30px 30px 30px 30px;
        &.main{
          background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
          color: #FFFFFF;
        }
        &.two{
          border: 1px solid #009DFF;
          color:#009DFF;
        }
      }
    }
  }
}
</style>