var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "div",
        { staticClass: "container" },
        [
          _vm._m(0),
          _c("div", { staticClass: "info-faren-main" }, [
            _c("div", { staticClass: "body-box" }, [
              _vm._m(1),
              _vm.incomeAccount
                ? _c("div", { staticClass: "faren-form" }, [
                    _c("div", { staticClass: "form-item border-bottom-1px" }, [
                      _c("label", [_vm._v("账 户 名")]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.incomeAccount.accountName))
                      ])
                    ]),
                    _c("div", { staticClass: "form-item border-bottom-1px" }, [
                      _c("label", [_vm._v("账 户 号")]),
                      _c("span", [_vm._v(_vm._s(_vm.incomeAccount.bankNo))])
                    ]),
                    _c("div", { staticClass: "form-item border-bottom-1px" }, [
                      _c("label", [_vm._v("开 户 行")]),
                      _c("span", [_vm._v(_vm._s(_vm.incomeAccount.bankName))])
                    ]),
                    _c("div", { staticClass: "form-item border-bottom-1px" }, [
                      _c("label", [_vm._v("所属支行")]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.incomeAccount.openBranchName))
                      ])
                    ])
                  ])
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "info-faren-main" }, [
            _c("div", { staticClass: "body-box" }, [
              !_vm.legalPersonStatus
                ? _c("div", [
                    _vm._m(2),
                    _c("div", { staticClass: "faren-form" }, [
                      _c("div", { staticClass: "form-item1" }, [
                        _c("label", [_vm._v("经办授权书")]),
                        _c(
                          "span",
                          { staticClass: "down", on: { click: _vm.loadDown } },
                          [_vm._v("下载模版 "), _c("i")]
                        )
                      ]),
                      _c("div", { staticClass: "form-item1" }, [
                        _vm.form.grantUrl
                          ? _c(
                              "div",
                              {
                                staticClass: "empty-img",
                                on: {
                                  click: function($event) {
                                    return _vm.see(_vm.form.grantUrl)
                                  }
                                }
                              },
                              [_c("img", { attrs: { src: _vm.form.grantUrl } })]
                            )
                          : _c(
                              "div",
                              { staticClass: "empty-img" },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/images/img/add_d.png"),
                                    alt: ""
                                  }
                                }),
                                _c("OSSUpload", {
                                  ref: "upload1",
                                  staticClass: "ossupload",
                                  attrs: {
                                    type: _vm.file.uploadType,
                                    backgroundImgName: _vm.file.bg,
                                    accept: _vm.file.accept,
                                    title: _vm.file.title,
                                    calssType: true,
                                    files: _vm.file.files,
                                    multiple: _vm.file.multiple
                                  },
                                  on: {
                                    success: function(e) {
                                      return _vm.handleSuccess(e)
                                    },
                                    "update:files": function($event) {
                                      return _vm.$set(_vm.file, "files", $event)
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                        _c("div")
                      ])
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "agreement-box" }, [
                _c("i"),
                _c("p", [
                  _vm._v("本人已阅读并同意\n              "),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.toAgreementView(
                            "新网银行好惠通业务服务协议",
                            "docx"
                          )
                        }
                      }
                    },
                    [_vm._v(" 《新网银行好惠通业务服务协议》")]
                  ),
                  _vm._v("、\n              "),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.toAgreementView(
                            "个人信息查询及使用授权书",
                            "docx"
                          )
                        }
                      }
                    },
                    [_vm._v("《个人信息查询及使用授权书》")]
                  ),
                  _vm._v("、\n              "),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.toAgreementView(
                            "企业信息查询及使用授权书",
                            "docx"
                          )
                        }
                      }
                    },
                    [_vm._v("《企业信息查询及使用授权书》")]
                  ),
                  _vm._v("、\n              "),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.toAgreementView(
                            "数字证书及电子签名授权委托书",
                            "docx"
                          )
                        }
                      }
                    },
                    [_vm._v("《数字证书及电子签名授权委托书》")]
                  ),
                  _vm._v("、\n              "),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.toAgreementView(
                            "新网银行好惠通业务免校验交易服务协议",
                            "pdf"
                          )
                        }
                      }
                    },
                    [_vm._v("《新网银行好惠通业务免校验交易服务协议》")]
                  )
                ])
              ]),
              _c(
                "div",
                { staticClass: "add-btn on", on: { click: _vm.commit } },
                [
                  _vm.commiting ? _c("cube-loading") : _vm._e(),
                  _c("span", [_vm._v("确认开立监管户")])
                ],
                1
              )
            ])
          ]),
          _c(
            "transition",
            { attrs: { name: "cube-action-sheet-fade" } },
            [
              _c(
                "cube-popup",
                {
                  attrs: {
                    type: "repayment-confrim",
                    position: "center",
                    visible: _vm.isConfromVisibleRpMask
                  },
                  on: {
                    "mask-click": function($event) {
                      _vm.isConfromVisibleRpMask = false
                    }
                  }
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: "cube-action-sheet-move" } },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isConfromVisibleRpMask,
                              expression: "isConfromVisibleRpMask"
                            }
                          ],
                          staticClass: "confrim-content",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                            }
                          }
                        },
                        [
                          _c("p", { staticClass: "title" }, [_vm._v("提示")]),
                          _c("p", { staticClass: "desc" }, [
                            _vm._v(
                              "你尚未添加企业对公账户（收款账户），请先添加"
                            )
                          ]),
                          _c("div", { staticClass: "footer-box" }, [
                            _c(
                              "span",
                              {
                                staticClass: "btn two",
                                on: { click: _vm.cancelRpMask }
                              },
                              [_vm._v("暂不添加")]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "btn main",
                                on: { click: _vm.add }
                              },
                              [_vm._v("去添加")]
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-box" }, [
      _c("p", { staticClass: "title" }, [_vm._v("企业开户")]),
      _c("p", { staticClass: "desc" }, [_vm._v("请填写以下资料吧~")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "info-faren-title-box border-bottom-1px" },
      [_c("p", { staticClass: "info-faren-title" }, [_vm._v("企业对公账户")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "info-faren-title-box border-bottom-1px" },
      [_c("p", { staticClass: "info-faren-title" }, [_vm._v("授权信息")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }