<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2024-12-24 16:15:11
 * @LastEditors: CZK
 * @LastEditTime: 2025-01-02 09:58:25
-->
<template>
  <div class="container" v-if="!loading">
    <div class="box">
      <div class='item'>
        <div class="line border-bottom-1px">
          <span class="t">收款账户</span>
          <span class="val">{{info.receiveAccountName}}</span>
        </div>

        <div class="line border-bottom-1px">
          <span class="t">收款账号</span>
          <span class="val">{{info.receiveAccountNo}}</span>
        </div>

        <div class="line border-bottom-1px">
          <span class="t">收款银行</span>
          <span class="val">{{info.receiveBankName}}</span>
        </div>
      </div>


      <div class='item'>
        <div class="line border-bottom-1px">
          <span class="t">转出账号</span>
          <span class="val">{{info.balanceAccountNo}}0</span>
        </div>

        <div class="line border-bottom-1px">
          <span class="t">可用余额</span>
          <span class="val1">￥{{info.availableBalance}}</span>
        </div>
      </div>


      <div class='item'>
        <div class="line border-bottom-1px">
          <span class="t">提现金额</span>
          <input type='number' placeholder="请输入提现金额" v-model="money"/>
          <button @click="allMoney">全部提现</button>
        </div>

        <div class="line border-bottom-1px">
          <span class="t">验&nbsp;证&nbsp;码</span>
          <input type='text' v-model="authCode" placeholder="短信验证码"/>
          <span class="val2" @click="getCaptcha">{{ state.smsSendBtn ? state.time + 's后重发' : '获取验证码'}}</span>
        </div>

        <div class="line">
          <span class="desc">验证码发送至：{{contactInfo.contactPhone}}（{{contactInfo.contactName}}）</span>
        </div>
      </div>
    </div>

    <div class="add-btn on" @click="commit">
          <cube-loading v-if="commiting"></cube-loading>
          <span>确认提现</span>
        </div>
    
  </div>
</template>
<script>
import{getWithdrawBasicAccountInfo,confirmWithDraw,sendSms,getEBankEnterprisePersonInfo} from '@/api/user'
let LIMITTIME = 60
import moment from 'moment'
  export default {
    name: "payouts",
    data() {
      return {
        state: {
          time: LIMITTIME,
          smsSendBtn: false
        },
        authCode:'',
        authId:'',
        commiting:false,
        loading:true,
        info:null,
        contactInfo:null,
        money:""
      }
    },
    methods:{
    async commit(){
      if(this.commiting) return
      if(!this.money) return this.$createToast({type: "warn",txt: "请检查提现金额"}).show();
      if(!this.authCode) return this.$createToast({type: "warn",txt: "请先获取验证码"}).show();
      this.commiting = true
      let res = await confirmWithDraw({
        amt:this.money,
        authCode:this.authCode,
        authId:this.authId,
        bindBankNo:this.info.receiveAccountNo,
        phone:this.contactInfo.contactPhone,
        token:''
      })
      if(res.success){
        this.$createToast({type: "success",txt: "成功提现"}).show();
        setTimeout(()=>{
          this.$router.back()
        },1000)
      }

      this.commiting = false
    },
      //恢复按钮
    regainCheckBtn(state) {
      state.time = LIMITTIME;
      state.smsSendBtn = false;
    },

    //获取验证码
    getCaptcha() {
      const {state } = this;
      // if(!this.showCode) return
      if (!this.contactInfo.contactPhone) {
        this.$createToast({
          type: "warn",
          txt: "请确认手机号"
        }).show();
        return;
      }
      setTimeout(() => {
        if (this.contactInfo.contactPhone.length > 0) {
          state.smsSendBtn = true;
          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              this.regainCheckBtn(state);
              window.clearInterval(interval);
            }
          }, 1000);
          const toastSuccess = this.$createToast({
            txt: "验证码发送中",
            type: "correct",
            time: 0
          });
          toastSuccess.show();
          sendSms({mobile:this.contactInfo.contactPhone, authCodeType:'WithdrawCheck'})
            .then(res => {
              toastSuccess.hide();
              if (res && res.success && res.data && res.data.authId) {
                
                // 储存tattedId
                const authId = res.data.authId;
                if(authId){
                  this.authId = authId;
                  this.$createToast({
                    txt: "验证码已发送，请留意",
                    type: "correct"
                  }).show();
                }
                
              } else {
                clearInterval(interval);
                this.regainCheckBtn(state);
              }
            })
            .catch(err => {
              console.log('err', err)
              toastSuccess.hide();
              clearInterval(interval);
              this.regainCheckBtn(state);
            });
        } else {
          this.$createToast({
            txt: "请确认手机号",
            type: "error"
          }).show();
        }
      }, 0);
    },
      allMoney(){
        this.money = this.info.availableBalance
      },
      async getInfo(){
       let res = await getWithdrawBasicAccountInfo({token:''})
       let res1 =  await getEBankEnterprisePersonInfo({token:""})
       if(res.success) this.info = res.data
       if(res1.success) this.contactInfo = res1.data
       this.loading = false
     }
    },
    mounted(){
      this.getInfo()
    }
  }
</script>
<style lang="less" scoped>
  .container {
    width: 100%;
    background: #F5F7FB url(../../assets/images/img/apply_bg.png) no-repeat top center;
    .box{
      margin-top:22px;
      .item{
        width: 343px;
        background: #FFFFFF;
        border-radius: 8px 8px 8px 8px;
        padding: 0 11px;
        margin:0 auto 16px;
        .line{
          width:100%;
          height: 50px;
          display:flex;
          align-items:center;
          .desc{
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            line-height: 20px;
          }
          .val2{
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            line-height: 18px;
          }
          button{
            all:unset;
            width: 60px;
            text-align:center;
            font-family: PingFangSC-Regular, PingFangSC-Regular;
            font-weight: 400;
            font-size: 12px;
            color: #333333;
            height: 20px;
            border-radius: 8px 8px 8px 8px;
            border: 1px solid #333333;
          }
          .t{
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            display:flex;
            justify-content: space-between;
            width:56px;
            color: #666666;
          }
          input{
            all:unset;
            flex:1;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            margin-left: 32px;
            &::placeholder{
            color: #999999;
          }
          }
          .val{
            flex:1;
            margin-left: 32px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            line-height: 18px;
          }
          .val1{
            margin-left:auto;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #333333;
          }
        }
      }
    }
    .add-btn{
      width: 321px;
      height: 42px;
      text-align: center;
      background: #CCCCCC;
      border-radius: 30px 30px 30px 30px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 42px;
      margin: 12px auto 18px;
      color: #FFFFFF;
      &.on{
        background: linear-gradient( 270deg, #009DFF 2%, #0073FF 100%, #007AFF 100%);
        margin: 10px auto 16px;
      }
    }

  }
</style>